import './uniqueSellingPoints.scss';

import { useLocalize } from 'localize-react';
import React from 'react';

import { ReactComponent as CheckCircleGreen } from '../../assets/svgs/checkCircleGreen.svg';

export const UniqueSellingPoints: React.FC<any> = () => {
  const { translate: t } = useLocalize();
  const {
    backgroundContrastColor,
    secondaryColor,
  } = window.tz_globalConfigs.styling;
  const { partnerStylingOverrides } = window.tz_globalConfigs;

  return <div className="vehicle-usps" style={{ color: backgroundContrastColor }}>
    <div className="vehicle-usp"><CheckCircleGreen style={{ fill: partnerStylingOverrides?.['whitelabel.usp.color'] || secondaryColor || '#6c6' }} /><span>{t('savePercent')}</span></div>
    <div className="vehicle-usp"><CheckCircleGreen style={{ fill: partnerStylingOverrides?.['whitelabel.usp.color'] || secondaryColor || '#6c6' }} /><span>{t('transferCount')}</span></div>
    <div className="vehicle-usp"><CheckCircleGreen style={{ fill: partnerStylingOverrides?.['whitelabel.usp.color'] || secondaryColor || '#6c6' }} /><span>{t('customerSupport')}</span></div>
  </div>;
};
