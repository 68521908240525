import {
  cilAirplaneMode,
  cilBoatAlt,
  cilBriefcase,
  cilBuilding,
  cilCalendar,
  cilCheckCircle,
  cilClock,
  cilFork,
  cilLocationPin,
  cilLocomotive,
  cilPeople,
  cilSpeedometer,
  cilTransfer,
  cilWarning,
  cilXCircle,
} from '@coreui/icons';

export const icons = Object.assign({}, {
  cilSpeedometer,
  cilLocationPin,
  cilTransfer,
  cilAirplaneMode,
  cilCalendar,
  cilClock,
  cilBriefcase,
  cilPeople,
  cilCheckCircle,
  cilXCircle,
  cilWarning,
  cilFork,
  cilBoatAlt,
  cilLocomotive,
  cilBuilding,
});
