import React, { useEffect } from 'react';

import { useLocalize } from '../../hooks/useLocalize';

export const DocumentTitle = () => {
  const { translate: t, locale } = useLocalize();

  useEffect(() => {
    document.title = t('documentTitle');
    // eslint-disable-next-line
  }, [t('documentTitle')]);

  return (<></>);
};
