import { CRow } from '@coreui/react';
import { Button } from '@transferz/fe-component-library/src/components/button/Button';
import { useLocalize } from 'localize-react';
import React from 'react';

import { ReactComponent as AdultPassengers } from '../../assets/svgs/adultPassengers.svg';
import { ReactComponent as ChildPassengers } from '../../assets/svgs/childPassengers.svg';
import { ReactComponent as InfantPassengers } from '../../assets/svgs/infantPassengers.svg';
import { InputSpinner } from '../inputSpinner/InputSpinner';

interface IPassengersOptionsProps {
  isMobile?: boolean;
  setPassengersPopupOpen: (arg: boolean) => void,
  passengers: { adultPassengers: number, childPassengers: number, infantPassengers: number },
  setPassengers: (arg: {
    adultPassengers: number;
    childPassengers: number;
    infantPassengers: number;
  }) => void
  popupPassengers: { adultPassengers: number, childPassengers: number, infantPassengers: number },
  setPopupPassengers: (arg: {
    adultPassengers: number;
    childPassengers: number;
    infantPassengers: number;
  }) => void
}

export function PassengersOptions({ isMobile = false, setPassengersPopupOpen, passengers, setPassengers, popupPassengers, setPopupPassengers }: IPassengersOptionsProps) {
  const { translate: t } = useLocalize();
  return (
    <>
      <CRow className="mr-0 ml-0 ">
        <div className="booking-options w-100 show-options" >
          <ul className="pl-0 pr-0">
            <li className="option" >
              <div className="travel-addon-container">
                <div className="d-flex">
                  <AdultPassengers className="passenger-icon" />
                  <div>
                    <div data-testid="select_travel-addon" className="travel-addon">
                      <div className="travel-addon-title">{t('adultPassengers')}</div>
                    </div>
                    <div className="travel-addon-price">
                      <span>{t('adultPassengersAge')}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <InputSpinner
                    maxValue={99}
                    name="adults"
                    initialValue={isMobile ? passengers.adultPassengers : popupPassengers.adultPassengers}
                    onChange={isMobile
                      ? (value: number) => setPopupPassengers({ ...popupPassengers, adultPassengers: value })
                      : (value: number) => setPassengers({ ...passengers, adultPassengers: value })}
                  />
                </div>
              </div>
            </li>
            <li className="option" >
              <div className="travel-addon-container">
                <div className="d-flex">
                  <ChildPassengers className="passenger-icon" />
                  <div>
                    <div data-testid="select_travel-addon" className="travel-addon">
                      <div className="travel-addon-title">{t('childPassengers')}</div>
                    </div>
                    <div className="travel-addon-price">
                      <span>{t('childPassengersAge')}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <InputSpinner
                    maxValue={99}
                    name="children"
                    initialValue={isMobile ? passengers.childPassengers : popupPassengers.childPassengers}
                    onChange={isMobile
                      ? (value: number) => setPopupPassengers({ ...popupPassengers, childPassengers: value })
                      : (value: number) => setPassengers({ ...passengers, childPassengers: value })}
                  />
                </div>
              </div>
            </li>
            <li className="option" >
              <div className="travel-addon-container">
                <div className="d-flex">
                  <InfantPassengers className="passenger-icon" />
                  <div>
                    <div data-testid="select_travel-addon" className="travel-addon">
                      <div className="travel-addon-title">{t('infantPassengers')}</div>
                    </div>
                    <div className="travel-addon-price">
                      <span>{t('infantPassengersAge')}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <InputSpinner
                    maxValue={99}
                    name="infants"
                    initialValue={isMobile ? passengers.infantPassengers : popupPassengers.infantPassengers}
                    onChange={isMobile
                      ? (value: number) => setPopupPassengers({ ...popupPassengers, infantPassengers: value })
                      : (value: number) => setPassengers({ ...passengers, infantPassengers: value })}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </CRow>
      {isMobile && <div className="passengersFooter d-flex justify-content-flex-end">
        <Button
          className="submitBtn"
          label={popupPassengers.adultPassengers + popupPassengers.childPassengers + popupPassengers.infantPassengers ? t('Done') : t('AddPassenger')}
          priority="primary"
          onClick={() => {
            setPassengers(popupPassengers);
            setPassengersPopupOpen(false);
          }}
          variant="info"
          disabled={!(popupPassengers.adultPassengers + popupPassengers.childPassengers + popupPassengers.infantPassengers)}
          data-button-id="confirm-cancel-journey"
          fullWidth
        />
      </div>}
    </>
  );
}
