import { useLocalize as _useLocalize } from 'localize-react';
import { useContext } from 'react';

import { LanguageContext } from '../components/localization/LanguageContext';

export const useLocalize = (): any => {
  const { currentLocale } = useContext(LanguageContext);
  const { translate: _translate } = _useLocalize();

  return {
    translate(key: string, values: { [key: string]: string }) {
      return _translate(key.replaceAll('.', '_'), values);
    },
    locale: currentLocale,
  };
};
