import './vehicleCategoryInformationPopup.scss';

import { Popup } from '@transferz/components/popup/Popup';
import React, { ReactElement } from 'react';

import { ReactComponent as ArrowRight } from '../../assets/svgs/arrowRight.svg';
import { ReactComponent as InfoIcon } from '../../assets/svgs/info.svg';
import { ReactComponent as Mail } from '../../assets/svgs/mail.svg';
import { ReactComponent as MeetAndGreet } from '../../assets/svgs/meetAndGreet.svg';
import { ReactComponent as Smartphone } from '../../assets/svgs/smartphone.svg';
import { ReactComponent as UsersGroup } from '../../assets/svgs/usersGroup.svg';
import { useLocalize } from '../../hooks/useLocalize';

interface ISharedVehicleInformationPopupProps {
  image?: ReactElement | ReactElement[] | null;
  button?: ReactElement | ReactElement[] | null;
}

export const SharedVehicleInformationPopup: React.FC<ISharedVehicleInformationPopupProps> = ({ image, button }) => {
  const { translate: t } = useLocalize();

  let DefaultButton = (
    <div>
      {image}
      <div className="vehicle-info vehicle-shared">
        <UsersGroup />
        <span>{t('shared')}</span>
        <InfoIcon />
      </div>
    </div>
  );

  return (
    <Popup
      title={t('sharedShuttleTitle')}
      button={button || DefaultButton}
      footerButton={
        <div className="button-confirm">
          <span>{t('okGotIt')}</span>
          <ArrowRight/>
        </div>
      }
    >
      <div className="vehicle-info-content">
        <p className="content-title">{t('howDoesItWork')}</p>
        <ul className="list-icons">
          <li>
            <Smartphone/>
            <span>{t('youllReceive')} <b>{t('youllReceive_2')}</b> {t('youllReceive_3')} <b>{t('youllReceive_4')}</b> {t('youllReceive_5')}</span>
          </li>
          <li>
            <MeetAndGreet/>
            <span>{t('atTheAirport')} <b>{t('atTheAirport_2')}</b>. {t('atTheAirport_3')}</span>
          </li>
          <li>
            <Mail/>
            <span>{t('forTheReturnTrip')} <b>{t('forTheReturnTrip_2')}</b> {t('forTheReturnTrip_3')}</span>
          </li>
        </ul>
        <p className="content-title">{t('goodToKnow')}</p>
        <ul className="list">
          <li>{t('youCanBeDropped')}</li>
          <li>{t('youCanChangeCancel')}</li>
          <li>{t('youMayBring')}</li>
        </ul>
      </div>
    </Popup>
  );
};

export default SharedVehicleInformationPopup;
