import './currency.scss';

import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import { getCurrencyInfo } from '@transferz/utils';
import React, { useEffect } from 'react';

import { useLocalize } from '../../hooks/useLocalize';
import { useLocalPreferredCurrencyCode } from '../../hooks/useLocalPreferredCurrencyCode';
import { useQueryCurrencies } from '../../queries/currency/hooks/useQueryCurrencies';

export const CurrencyStandalone: React.FC = () => {
  const { translate: t } = useLocalize();
  const [preferredCurrencyCode, setPreferredCurrencyCode] = useLocalPreferredCurrencyCode();

  const { partnerStylingOverrides } = window.tz_globalConfigs;
  const arrowStyles: any = {
    '--content-text-color': partnerStylingOverrides?.['whitelabel.page.textColor'] || window.tz_globalConfigs?.styling?.backgroundContrastColor || '#000000',
  } as React.CSSProperties;

  let searchKeys = '';
  let inputKeyTimeout: any = 0;
  const currencyDropDown = document.querySelector('.currency-container .dropdown-menu');
  const observerCurrencyDropDown = new MutationObserver(() => {
    searchKeys = '';
    unselectElements();
    const currencyOpenedDropDown = document.querySelector('.currency-container .dropdown-menu.show');
    if (currencyOpenedDropDown) {
      currencyOpenedDropDown.scrollTop = 0;
    }
  });
  const { data: currencyData, loading } = useQueryCurrencies();

  const handleClick = async (preferredCurrencyCode: string) => {
    setPreferredCurrencyCode(preferredCurrencyCode);
  };

  const unselectElements = () => {
    const selectedElements: Array<Element> = Array.from(document.getElementsByClassName('dropdown-item_select'));
    selectedElements.forEach((element: Element) => element.classList.remove('dropdown-item_select'));
  };

  const handleKeyDown = (event: any) => {
    const currencyDropDown = document.querySelector('.currency-container .dropdown-menu.show');

    if (!currencyDropDown) {
      return;
    }
    unselectElements();
    if (event.which === 8 || event.which === 46) {
      searchKeys = searchKeys.slice(0, -1);
    } else if ((event.which >= 65 && event.which <= 90) || event.which === 32) {
      searchKeys += event.key;
    }

    const currencyElements: Array<HTMLElement> = Array.from(document.querySelectorAll(`[data-currency*="${searchKeys}" i]`));
    const currencyElemTopPosition = currencyElements[0]?.offsetTop || 0;

    if (!searchKeys.length) {
      currencyDropDown.scrollTop = 0;
    }

    if (currencyElements[0]) {
      currencyDropDown.scrollTop = currencyElemTopPosition - 50;
      currencyElements.forEach((elem: HTMLElement) => elem.parentElement?.classList.add('dropdown-item_select'));
    }

    clearTimeout(inputKeyTimeout);
    inputKeyTimeout = setTimeout(() => {
      searchKeys = '';
    }, 1500);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    if (currencyDropDown) {
      observerCurrencyDropDown.observe(currencyDropDown, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      observerCurrencyDropDown.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  if (!currencyData) {
    return null;
  }

  return (
    <CDropdown inNav className="currency-container c-header-nav-items mx-2">
      <CDropdownToggle data-testid="preferred-currency-code" className="c-header-nav-link" color="secondary" caret={true} style={arrowStyles} disabled={loading}>
        <span>{preferredCurrencyCode}</span>
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="m-0">
        {
          currencyData.currencies.map((currencyItem: string) => <CDropdownItem key={currencyItem} onClick={() => handleClick(currencyItem)}>
            <span data-currency={getCurrencyInfo(currencyItem).currency}>{getCurrencyInfo(currencyItem).symbol}</span>
            {t(`currencies.${currencyItem}`)}
          </CDropdownItem>,
          )
        }
      </CDropdownMenu>
    </CDropdown>
  );
};
