import React from 'react';

interface IErrorBoundaryProps {
  children: any;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.PureComponent<IErrorBoundaryProps, IErrorBoundaryState> {
  state: IErrorBoundaryState = {
    hasError: false,
  };

  componentDidCatch(error: any): void {
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <div>Error</div>;
    }
    return children;
  }
}
