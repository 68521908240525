import CIcon from '@coreui/icons-react';
import ar from 'date-fns/locale/ar-SA';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import pt from 'date-fns/locale/pt';
import sv from 'date-fns/locale/sv';
import React, { useEffect, useMemo, useRef } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

registerLocale('en-US', en);
registerLocale('de-DE', de);
registerLocale('es-ES', es);
registerLocale('fr-FR', fr);
registerLocale('it-IT', it);
registerLocale('nl-NL', nl);
registerLocale('pt-BR', pt);
registerLocale('da-DK', da);
registerLocale('sv-SE', sv);
registerLocale('ar-SA', ar);

const getCustomStyles = () => {
  const {
    backgroundColor,
    backgroundContrastColor,
    primaryColor,
  } = window.tz_globalConfigs?.styling;

  return {
    '--text-color': backgroundContrastColor || '#000000',
    '--selected-text-color': backgroundColor || '#ffffff',
    '--selected-bg-color': primaryColor || '#3c4b64',
  } as React.CSSProperties;
};

export const DayPicker = ({ id, value, onChange, round, locale, minDate }: any) => {
  useMemo(() => import('./styles'), []);
  const dayRef = useRef(null) as any;
  const customStyles = getCustomStyles();
  setDefaultLocale(locale);

  useEffect(() => {
    const input = dayRef.current.querySelector('input');
    input.setAttribute('readonly', true);
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={dayRef} className={`picker-wrapper daypicker-wrapper ${round && 'daypicker-wrapper-large'}`}  style={customStyles}>
      <label htmlFor={id}>
        <CIcon size="lg" name="cil-calendar" className="mfe-2" />
      </label>
      <DatePicker
          id={id}
          dateFormat="dd-MM-yyyy"
          selected={value}
          onChange={(date: any) => onChange(date)}
          minDate={minDate || new Date()}
          value={value}
          popperPlacement="bottom-end"
      />
    </div>
  );
};

export const TimePicker = ({ id, value, onChange, round, minTime }: any) => {
  const timeRef = useRef(null) as any;
  const customStyles = getCustomStyles();

  useEffect(() => {
    const input = timeRef.current.querySelector('input');
    input.setAttribute('readonly', true);
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={timeRef} className={`picker-wrapper timepicker-wrapper ${round && 'timepicker-wrapper-large'}`} style={customStyles}>
      <label htmlFor={id}>
        <CIcon size="lg" name="cil-clock" className="mfe-2" />
      </label>
      <DatePicker
        id={id}
        locale="en-US"
        selected={value}
        onChange={(date: any) => onChange(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
        timeCaption="Time"
        dateFormat="h:mm aa"
        minTime={minTime || new Date(0, 0, 0, 0, 0)}
        maxTime={new Date(0, 0, 0, 23, 59)}
      />
    </div>
  );
};
