import { PortalType } from '@transferz/fe-models';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';

import { useMutationCreateBooking } from '../queries/booking/hooks/useMutationCreateBooking';
import { useLocalize } from './useLocalize';

export const useCreateBooking = () => {
  const { locale } = useLocalize();
  const [booking, setBooking] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [createBookingMutation] = useMutationCreateBooking();
  const flags = useFlags();
  const queryData: any = {};

  const getQuotesFromQuery = () => {
    const urlParams: any = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
      if (key === 'quotes') {
        queryData[key] = JSON.parse(value);
      }
      if (key === 'isRoundTrip') {
        queryData[key] = value;
      }
    }
    return queryData;
  };

  const createBooking = (formData: any) => {
    const { quotes } = getQuotesFromQuery();
    const {
      other,
      bookerDetails,
      travellerDetails,
      inboundFlightNumber = '',
      outboundFlightNumber = '',
      outboundShipName = '',
      inboundShipName = '',
      code,
      country,
      partnerReference = '',
    } = formData;

    const _travellerDetails = {
      firstName: other ? travellerDetails.firstName : bookerDetails.firstName,
      lastName: other ? travellerDetails.lastName : bookerDetails.lastName,
      email: other ? travellerDetails.email : bookerDetails.email,
      phone: flags?.dataPreselected ? bookerDetails.phone.replace(/\s+/g, '') : `${code.value}${bookerDetails.phone.charAt(0) === '0' ? bookerDetails.phone.substring(1) : bookerDetails.phone}`,
      meetAndGreetSignText: '',
      driverComments: travellerDetails.driverComments,
      flightNumber: outboundFlightNumber,
      shipName: outboundShipName,
      trainNumber: '',
      languageIsoCode: locale,
      residenceCountryCode: country?.value,
    };

    quotes.forEach((quote: any, index: number) => {
      quote.quoteTravelAddons.forEach((addon: any) => addon.quoteTravelAddonId = Number(addon.quoteTravelAddonId));
      quote.travellerInfo = { ..._travellerDetails };
      if (index) {
        quote.travellerInfo.flightNumber = inboundFlightNumber;
        quote.travellerInfo.shipName = inboundShipName;
      }
    });

    const body = {
      quotes,
      bookerInfo: {
        lastName: bookerDetails.lastName,
        firstName: bookerDetails.firstName,
        email: bookerDetails.email,
        phone: flags?.dataPreselected ? bookerDetails.phone.replace(/\s+/g, '') : `${code.value}${bookerDetails.phone.charAt(0) === '0' ? bookerDetails.phone.substring(1) : bookerDetails.phone}`,
        companyName: '',
        reference: '',
        traveller: true,
        receiveJourneyUpdates: true,
        languageIsoCode: locale,
      },
      travelIntent: 'UNKNOWN',
      creationSource: PortalType.WhileLabel,
      partnerReference: partnerReference || Date.now().toString(),
    };
    setIsLoading(true);

    return createBookingMutation({
      variables: {
        params: body,
      },
    })
      .then(({ data }: any) => {
        setBooking(data.createBooking);
        return data.createBooking;
      })
      .catch((e: any) => {
        throw (e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [booking, isLoading, queryData, createBooking, getQuotesFromQuery];
};
