import './inputSpinner.scss';

import { CInput } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { ReactComponent as MathMinus } from '../../assets/svgs/mathMinus.svg';
import { ReactComponent as MathPlus } from '../../assets/svgs/mathPlus.svg';

export const InputSpinner = (props: any) => {
  const {
    name,
    onChange,
    maxValue,
    initialValue,
  } = props;

  const [count, setCount] = useState(initialValue || 0);
  useEffect(() => setCount(initialValue || 0), [initialValue]);
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const incrementCount = () => {
    if (count < maxValue) {
      const newCount = count + 1;
      setCount(newCount);
      onChange(newCount);
    }
  };

  const decrementCount = () => {
    if (count) {
      const newCount = count - 1;
      setCount(newCount);
      onChange(newCount);
    }
  };

  const handleKeyboardCountChange = (e: any) => {
    if (isDesktop) {
      let val = e.target.value;
      if (val === '') {
        setCount('');
        return;
      }
      val = val.replace(/^0+/, '');
      const numVal = Math.floor(Number(val));
      if (!isNaN(numVal) && numVal >= 0 && numVal <= maxValue) {
        setCount(numVal);
        onChange(numVal);
      }
    }
  };

  return (
    <div className="input-spinner">
      <div role="button" aria-label="minus-button" className={count !== 0 ? '' : 'disabled'} tabIndex={0}
        onClick={decrementCount}
        onKeyPress={(e) => {
          e.key === 'Enter' && decrementCount();
        }}>
        <MathMinus />
      </div>
      <CInput name={name} type="text" min="0" step="1" className="input-spinner-field" value={count} disabled={!isDesktop}
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleKeyboardCountChange(e)}
        onBlur={() => {
          if (!count) {
            setCount(0);
            onChange(0);
          }
        }} />
      <div role="button" aria-label="plus-button" className={count < maxValue ? '' : 'disabled'} tabIndex={0}
        onClick={incrementCount}
        onKeyPress={(e) => {
          e.key === 'Enter' && incrementCount();
        }}>
        <MathPlus />
      </div>
    </div>
  );
};
