import { gql } from '@apollo/client';

export const HUBS = gql`
  query Hubs($params: HubsRequestParams!) {
    hubs(params: $params) {
      results {
        id
        position {
          lat
          lng
        }
        hubType
        autoCompleteName
        autoCompleteLocation
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalResults
      }
    }
  }
`;

export const HUB_DETAILS_BY_ID = gql`
  query GetHubById($id: Int!) {
    hubById(id: $id) {
      id
      details {
        code
        value
      }
      position {
        lat
        lng
      }
    }
  }
`;

export const MULTILINGUAL_HUBS = gql`
  query MultilingualHubs($params: MultilingualHubsRequestParams!) {
    multilingualHubs(params: $params) {
      results {
        id
        autoCompleteName
        autoCompleteLocation
        hubType
        position {
          lat
          lng
        }
        countryCode
        highlightedResult {
          autoCompleteName {
            value
          }
          autoCompleteLocation {
            value
          }
        }
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalResults
      }
    }
  }
`;
