import React, { HTMLAttributes, useMemo } from 'react';

import { ReactComponent as Luggage } from './luggage.svg';

interface ILuggageInputProps extends HTMLAttributes<HTMLDivElement> {
  luggagePopupOpen: boolean;
  luggageValue: number,
}

export function LuggageInput({ luggagePopupOpen, luggageValue, className, ...htmlAttr }: ILuggageInputProps) {
  const inputId = useMemo(() => Math.random().toString()
    .replaceAll(/^\d+/g, ''), []);
  return (
    <div className={`widget-details-luggage ${className}`} {...htmlAttr}>
      <label htmlFor={`luggage-id-${inputId}`}>
        <Luggage className="luggage-input-icon" />
      </label>
      <input
        id={`luggage-id-${inputId}`}
        type="text"
        readOnly
        value={luggageValue}
      />
    </div>
  );
}
