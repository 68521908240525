import TagManager from 'react-gtm-module';

export const useDataLayer = () => {
  const trackEvent = (dataLayer: any) => {
    TagManager.dataLayer({ dataLayer });
  };

  return {
    trackEvent,
  };
};
