import { gql } from '@apollo/client';

export const AUTO_COMPLETE_ADDRESSES = gql`
  query AutoCompleteAddresses($params: AutoCompleteAddressesParams!) { 
    autoCompleteAddresses(params: $params) {
      title
      subtitle
      formattedAddress
      type
      hubId
      googlePlaceId
      hereId
    }
  }
`;
