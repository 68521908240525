import React, { HTMLProps } from 'react';

import { iconMap, ResultType } from '../utils';
import styles from './SearchResultItem.module.scss';

export interface SearchResultItemProps extends HTMLProps<HTMLDivElement> {
  title: string;
  subtitle: string;
  type: ResultType;
}

export const SearchResultItem = ({ title, subtitle, type, ...divProps }: SearchResultItemProps) => {
  const Icon = iconMap[type];
  return (
    <div {...divProps} className={`${styles.searchResultItem} ${divProps.className}`}>
      <div className="icon-container">
        <Icon />
      </div>
      <div className="searchResultLabel">
        <div className="searchResultItemTitle">{title}</div>
        <div className="searchResultItemSubtitle">{subtitle}</div>
      </div>
    </div>
  );
};
