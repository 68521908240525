import BottomDrawer from '@transferz/fe-component-library/src/components/bottomDrawer/BottomDrawer';
import { useLocalize } from 'localize-react';
import React, { useRef } from 'react';
import { useMediaQuery, useOnClickOutside } from 'usehooks-ts';

import { LuggageInformation, LuggageOptions } from './LuggageOptions';

interface ILuggagePopupProps {
  show: boolean;
  onClose: () => void;
  onLugaggeChange: (luggageInfo: LuggageInformation) => void,
  initialLuggage: LuggageInformation,
  isBottom?: boolean | null;
}

export function LuggagePopup({ show, initialLuggage, onLugaggeChange, onClose, isBottom }: ILuggagePopupProps) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { translate: t } = useLocalize();
  const desktopPopupRef = useRef(null);

  const handleClose = () => {
    onClose();
    onLugaggeChange(initialLuggage);
  };

  useOnClickOutside(desktopPopupRef, handleClose);

  return isMobile
    ? (
      <BottomDrawer
      className="luggage-popup"
      title={t('Luggage')}
      isOpen={show}
      onClose={handleClose}
      enableDesktopView={true}
      >
        <LuggageOptions key={Date.now()} isMobile={isMobile} initialLuggage={initialLuggage} onLuggageChange={onLugaggeChange} />
      </BottomDrawer>
    ) : (
      <div onMouseLeave={handleClose} ref={desktopPopupRef} className={`luggage-popup-desktop ${isBottom ? 'bottom' : 'top'}`} hidden={!show}>
        <LuggageOptions isMobile={isMobile} initialLuggage={initialLuggage} onLuggageChange={onLugaggeChange} />
      </div>
    );
}
