import React from 'react';

import { useLocalize } from '../../hooks/useLocalize';

export const TermsAndConditionsTraveller: React.FC<any> = () => {
  const { translate: t } = useLocalize();
  return (
    <>
      <p className="terms-and-conditions-tab-body-title">{t('termsAndConditions.tab.traveller')}</p>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.introduction.title')}</strong>
          <ul>
            <li>
              <p>
                {t('termsAndConditions.travellerTerms.introduction.1.text.start')}
                <strong>{t('termsAndConditions.travellerTerms.introduction.1.text.middle')}</strong>
                {t('termsAndConditions.travellerTerms.introduction.1.text.end')}
              </p>
              <ul>
                <li>{t('termsAndConditions.travellerTerms.introduction.1.options.1')}</li>
                <li>{t('termsAndConditions.travellerTerms.introduction.1.options.2')}</li>
                <li>
                  {t('termsAndConditions.travellerTerms.introduction.1.options.3.text')}
                  <ul>
                    <li>{t('termsAndConditions.travellerTerms.introduction.1.options.3.options.1')}</li>
                    <li>{t('termsAndConditions.travellerTerms.introduction.1.options.3.options.2')}</li>
                  </ul>
                </li>
                <li>{t('termsAndConditions.travellerTerms.introduction.1.options.4')}</li>
                <li>{t('termsAndConditions.travellerTerms.introduction.1.options.5')}</li>
                <li>{t('termsAndConditions.travellerTerms.introduction.1.options.6')}</li>
                <li>{t('termsAndConditions.travellerTerms.introduction.1.options.7')}</li>
                <li>
                  {t('termsAndConditions.travellerTerms.introduction.1.options.8.start')}
                  <strong>{t('termsAndConditions.travellerTerms.introduction.1.options.8.bold1')}</strong>
                  {t('termsAndConditions.travellerTerms.introduction.1.options.8.middle1')}
                  <strong>{t('termsAndConditions.travellerTerms.introduction.1.options.8.bold2')}</strong>
                  {t('termsAndConditions.travellerTerms.introduction.1.options.8.middle2')}
                  <strong>{t('termsAndConditions.travellerTerms.introduction.1.options.8.bold3')}</strong>
                  {t('termsAndConditions.travellerTerms.introduction.1.options.8.end')}
                </li>
                <li>{t('termsAndConditions.travellerTerms.introduction.1.options.9')}</li>
                <li>{t('termsAndConditions.travellerTerms.introduction.1.options.10')}</li>
                <li>{t('termsAndConditions.travellerTerms.introduction.1.options.11')}</li>
                <li>
                  <strong>{t('termsAndConditions.travellerTerms.introduction.1.options.12.bold1')}</strong>
                  {t('termsAndConditions.travellerTerms.introduction.1.options.12.middle1')}
                  <strong>{t('termsAndConditions.travellerTerms.introduction.1.options.12.bold2')}</strong>
                  {t('termsAndConditions.travellerTerms.introduction.1.options.12.end')}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.contractualRelationship.title')}</strong>
          <ul>
            <li>{t('termsAndConditions.travellerTerms.contractualRelationship.options.1')}</li>
            <li>{t('termsAndConditions.travellerTerms.contractualRelationship.options.2')}</li>
            <li>{t('termsAndConditions.travellerTerms.contractualRelationship.options.3')}</li>
            <li>{t('termsAndConditions.travellerTerms.contractualRelationship.options.4')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.serviceAndLicense.title')}</strong>
          <ul>
            <li>{t('termsAndConditions.travellerTerms.serviceAndLicense.options.1')}</li>
            <li>{t('termsAndConditions.travellerTerms.serviceAndLicense.options.2')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.restrictions.title')}</strong>
          <ul>
            <li>
              <p>
                {t('termsAndConditions.travellerTerms.restrictions.1.text')}
              </p>
              <ul>
                <li>{t('termsAndConditions.travellerTerms.restrictions.1.options.1')}</li>
                <li>{t('termsAndConditions.travellerTerms.restrictions.1.options.2')}</li>
                <li>{t('termsAndConditions.travellerTerms.restrictions.1.options.3')}</li>
                <li>{t('termsAndConditions.travellerTerms.restrictions.1.options.4')}</li>
                <li>{t('termsAndConditions.travellerTerms.restrictions.1.options.5')}</li>
                <li>{t('termsAndConditions.travellerTerms.restrictions.1.options.6')}</li>
                <li>{t('termsAndConditions.travellerTerms.restrictions.1.options.7')}</li>
                <li>{t('termsAndConditions.travellerTerms.restrictions.1.options.8')}</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.useOfServices.title')}</strong>
          <ul>
            <li>{t('termsAndConditions.travellerTerms.useOfServices.options.1')}</li>
            <li>{t('termsAndConditions.travellerTerms.useOfServices.options.2')}</li>
            <li>{t('termsAndConditions.travellerTerms.useOfServices.options.3')}</li>
            <li>{t('termsAndConditions.travellerTerms.useOfServices.options.4')}</li>
            <li>{t('termsAndConditions.travellerTerms.useOfServices.options.5')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.thirdPartyServices.title')}</strong>
          <ul>
            <li>{t('termsAndConditions.travellerTerms.thirdPartyServices.options.1')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.userProvidedContent.title')}</strong>
          <ul>
            <li>
              {t('termsAndConditions.travellerTerms.userProvidedContent.options.1.start')}
              <strong>{t('termsAndConditions.travellerTerms.userProvidedContent.options.1.middle')}</strong>
              {t('termsAndConditions.travellerTerms.userProvidedContent.options.1.end')}
            </li>
            <li>{t('termsAndConditions.travellerTerms.userProvidedContent.options.2')}</li>
            <li>{t('termsAndConditions.travellerTerms.userProvidedContent.options.3')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.communication.title')}</strong>
          <ul>
            <li>{t('termsAndConditions.travellerTerms.communication.options.1')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.networkAccess.title')}</strong>
          <ul>
            <li>{t('termsAndConditions.travellerTerms.networkAccess.options.1')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.limitationOfLiability.title')}</strong>
          <ul>
            <li>{t('termsAndConditions.travellerTerms.limitationOfLiability.options.1')}</li>
            <li>{t('termsAndConditions.travellerTerms.limitationOfLiability.options.2')}</li>
            <li>{t('termsAndConditions.travellerTerms.limitationOfLiability.options.3')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.indemnity.title')}</strong>
          <ul>
            <li>{t('termsAndConditions.travellerTerms.indemnity.options.1')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <strong>{t('termsAndConditions.travellerTerms.disputeResolution.title')}</strong>
          <ul>
            <li>{t('termsAndConditions.travellerTerms.disputeResolution.options.1')}</li>
          </ul>
        </li>
      </ul>
    </>
  );
};
