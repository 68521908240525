import { HubType } from '@transferz/fe-models';
import { MultilingualHub } from 'src/queries/hub/hooks/useQueryMultilingualHubs';

import { ReactComponent as AirplaneIcon } from '../../../assets/svgs/airplane.svg';
import { ReactComponent as AnchorIcon } from '../../../assets/svgs/anchor.svg';
import { ReactComponent as PinIcon } from '../../../assets/svgs/pin.svg';
import { ReactComponent as PointOfInterestIcon } from '../../../assets/svgs/point-of-interest.svg';
import { ReactComponent as TrainIcon } from '../../../assets/svgs/train.svg';
import { SearchResultItemProps } from './searchResultItem/SearchResultItem';

export type ResultType = HubType | 'ADDRESS';
export type AddressPrediction = { description: string; place_id: string; };

export const iconMap = {
  [HubType.Airport]: AirplaneIcon,
  [HubType.Seaport]: AnchorIcon,
  [HubType.TrainStation]: TrainIcon,
  [HubType.PointOfInterest]: PointOfInterestIcon,
  ADDRESS: PinIcon,
};
export type SearchResultOption = SearchResultItemProps & {
  innerResult: AutoCompleteAddressItem;
};

export type SearchResults = SearchResultOption[] | 'NOT_RESULTS_FOUND';
export interface AddressSpec {
  address?: string | null;
  placeId?: string | null;
  addressHubInformation?: MultilingualHub;
}
export type SearchLocation = AutoCompleteAddressItem;
export const isHub = (location?: AutoCompleteAddressItem): boolean => {
  return !!location && location.type !== 'ADDRESS';
};
export const isAddress = (location?: AutoCompleteAddressItem): boolean => {
  return location?.type === 'ADDRESS';
};

export interface MultilingualHubWithPlaceId extends MultilingualHub {
  placeId?: string;
}

export const multiLingualHubFromAutoCompleteAddressItem = (autoCompleteAddressItem: AutoCompleteAddressItem): MultilingualHubWithPlaceId => {
  return {
    id: autoCompleteAddressItem.hubId!,
    autoCompleteLocation: autoCompleteAddressItem.title,
    autoCompleteName: autoCompleteAddressItem.formattedAddress,
    hubType: autoCompleteAddressItem.type,
    countryCode: '',
    position: { lat: 0, lng: 0 },
    highlightedResult: undefined,
    placeId: autoCompleteAddressItem.googlePlaceId,
  };
};

export const addressSpecFromAutoCompleteAddressItem = (autoCompleteAddressItem: AutoCompleteAddressItem): AddressSpec => {
  return {
    address: autoCompleteAddressItem.formattedAddress,
    placeId: autoCompleteAddressItem.googlePlaceId || undefined,
    addressHubInformation: isHub(autoCompleteAddressItem) ? multiLingualHubFromAutoCompleteAddressItem(autoCompleteAddressItem) : undefined,
  };
};

export const autoCompleteAddressItemFromAddressSpec = (addressSpec: AddressSpec): AutoCompleteAddressItem => {
  return {
    title: addressSpec.addressHubInformation?.autoCompleteLocation ?? '',
    formattedAddress: addressSpec.addressHubInformation?.autoCompleteName ?? addressSpec.address!,
    googlePlaceId: addressSpec.placeId ?? undefined,
    hubId: addressSpec.addressHubInformation?.id,
    type: 'ADDRESS',
    subtitle: '',
  };
};

export const autoCompleteAddressItemFromMultilingualHub = (multilingualHub: MultilingualHubWithPlaceId): AutoCompleteAddressItem => {
  return {
    title: multilingualHub.autoCompleteLocation,
    formattedAddress: multilingualHub.autoCompleteName,
    googlePlaceId: multilingualHub.placeId,
    hubId: multilingualHub.id,
    type: multilingualHub.hubType as HubType,
    subtitle: '',
  };
};
