import 'flag-icon-css/css/flag-icon.min.css';
import './language.scss';

import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import React, { useContext } from 'react';

import { useLocalize } from '../../hooks/useLocalize';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { setHtmlTextDirection } from '../../utils/utils';
import { ILanguageOption, LanguageContext } from '../localization/LanguageContext';

export const Language: React.FC = () => {
  const { setLocale, languages } = useContext(LanguageContext);
  const { locale } = useLocalize();
  const [, setValue] = useLocalStorage('preferredLanguage');
  const arrowStyles: any = {
    '--content-text-color': window.tz_globalConfigs?.styling?.backgroundContrastColor || '#000000',
  } as React.CSSProperties;

  const currentLanguageFlag = languages?.find((obj: ILanguageOption) => obj.language_iso_code === locale)?.language_flag;

  const changeLanguage = (lng: string) => {
    setValue(lng);
    setLocale?.(lng);
    setHtmlTextDirection(lng);
  };

  return (
    <CDropdown inNav className="language-container c-header-nav-items mx-2">
      <CDropdownToggle className="c-header-nav-link" color="secondary" caret={true} style={arrowStyles}>
        <img className="flag-icon" src={currentLanguageFlag?.filename} />
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="m-0">
        {
          languages?.map((obj: ILanguageOption) => locale !== `${obj.language_iso_code}`
            ? <CDropdownItem key={obj.language_iso_code} onClick={() => changeLanguage(`${obj.language_iso_code}`)}>
              <img className="mx-4 flag-icon" src={obj.language_flag?.filename} />
              {obj.language_name}
            </CDropdownItem>
            : null,
          )
        }
      </CDropdownMenu>
    </CDropdown>
  );
};
