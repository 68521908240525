import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { AUTO_COMPLETE_ADDRESSES } from '../autoCompleteAddressesTypeDefs';

interface TData {
  autoCompleteAddresses?: AutoCompleteAddressItem[] | null
}

export const useFetchAutoCompleteAddresses = () => {
  const client = useApolloClient();
  const fetchAutoCompleteAddresses = useCallback(async (params: AutoCompleteAddressesParams) => (
    client.query<TData>({ query: AUTO_COMPLETE_ADDRESSES, variables: { params } })
  ), [client]);
  return { fetchAutoCompleteAddresses };
};
