import './geoDirection.scss';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { useFetchHubByAddress } from '../../hooks/useFetchHubByAddress';
import { useLocalize } from '../../hooks/useLocalize';
import { useQueryHubDetailsById } from '../../queries/hub/hooks/useQueryHubDetailsById';

export const GeoDirection: any = (props: any) => {
  const {
    hasError = false,
    queryData,
  } = props;
  const { locale } = useLocalize();

  const [coordinateA, setCoordinateA] = useState<any>(null);
  const [coordinateB, setCoordinateB] = useState<any>(null);
  const { refetch: fetchHubDetails } = useQueryHubDetailsById({ variables: { id: Number(queryData.hubId) }, skip: true });
  const fetchHubByAddress = useFetchHubByAddress(locale);

  useEffect(() => {
    async function fetchData() {
      const service = new google.maps.places.PlacesService(document.createElement('div'));

      const hubId = (queryData.hubId && Number(queryData.hubId)) || (await fetchHubByAddress(queryData.hubName))?.id || 0;

      const result  = await fetchHubDetails({ id: hubId });
      const hubById = result?.data?.hubById;

      const initDirection = (result: any) => {
        const coordinateOne = {
          lat: queryData.placeId ? (result as any)?.geometry.location.lat() : queryData.destination.lat,
          lng: queryData.placeId ? (result as any)?.geometry.location.lng() : queryData.destination.lng,
        };

        const coordinateTwo = {
          lat: Number(hubById?.position?.lat),
          lng: Number(hubById?.position?.lng),
        };

        const origin = queryData?.directionality === 'INBOUND' ? coordinateOne : coordinateTwo;
        const destination = queryData?.directionality === 'INBOUND' ? coordinateTwo : coordinateOne;

        setCoordinateA(origin);
        setCoordinateB(destination);

        if (props.onOriginAndDestinationResolved) {
          props.onOriginAndDestinationResolved(origin, destination);
        }
      };

      if (queryData.placeId) {
        service.getDetails({
          placeId: queryData.placeId,
          fields: ['address_component', 'adr_address', 'formatted_address', 'geometry', 'name', 'place_id', 'type'],
        }, function (result) {
          initDirection(result);
        });
      } else {
        initDirection(queryData);
      }
    }
    fetchData();
  }, [ queryData.placeId, queryData.destination, queryData.directionality]);

  const classes = classNames({
    'google-map-container': true,
    error: hasError,
  });

  if (coordinateA && coordinateB) {
    const size = '410x250';
    const key = window.environmentConfigs.googleMapsApiKey;
    const markerA = `color%3Ared|label%3AA|${coordinateA.lat}%2C${coordinateA.lng}`;
    const markerB = `color%3Ared|label%3AB|${coordinateB.lat}%2C${coordinateB.lng}`;

    return (
      <div className={classes}>
        <img src={`https://maps.googleapis.com/maps/api/staticmap?size=${size}&maptype=roadmap&key=${key}&markers=${markerA}&markers=${markerB}&scale=2`} />
      </div>
    );
  }

  return null;
};
