import React from 'react';

import { ApolloProvider } from '../ApolloProvider/ApolloProvider';

interface ILayoutProps {
  children: any;
}

export const Layout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <ApolloProvider>
      {children}
    </ApolloProvider>
  );
};
