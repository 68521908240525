import 'react-datepicker/dist/react-datepicker.css';
import './dateTimePicker.scss';

import ar from 'date-fns/locale/ar-SA';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-AU';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import pt from 'date-fns/locale/pt';
import sv from 'date-fns/locale/sv';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useMediaQuery } from 'usehooks-ts';

import { ReactComponent as CalendarToday } from '../../../src/assets/svgs/calendar-today.svg';
import { ReactComponent as Clock } from '../../../src/assets/svgs/time.svg';
import { ReactComponent as ChevronRight } from '../../assets/svgs/chevron-right.svg';

registerLocale('en-AU', en);
registerLocale('de-DE', de);
registerLocale('es-ES', es);
registerLocale('fr-FR', fr);
registerLocale('it-IT', it);
registerLocale('nl-NL', nl);
registerLocale('pt-BR', pt);
registerLocale('da-DK', da);
registerLocale('sv-SE', sv);
registerLocale('ar-SA', ar);

export const DayPicker = ({ id, value, onChange, round, locale, minDate }: any) => {
  const isMobile = useMediaQuery('(max-width: 480px)');
  const dayRef = useRef(null) as any;
  setDefaultLocale(locale);
  const [offset, setOffset] = useState<any>({ offset: { offset: 0 } });

  function calcMobileOffset() {
    if (isMobile) {
      if (locale === 'ar-SA') {
        setOffset({ offset: { offset: (window.innerWidth - dayRef.current.getBoundingClientRect().x - dayRef.current.getBoundingClientRect().width - 5) } });
      } else {
        setOffset({ offset: { offset: -dayRef.current.getBoundingClientRect().x } });
      }
    } else {
      setOffset({ offset: { offset: 0 } });
    }
  }

  useEffect(() => {
    const input = dayRef.current.querySelector('input');
    input.setAttribute('readonly', true);
    calcMobileOffset();
  }, [locale, isMobile]);

  return (
    <div ref={dayRef} className={`picker-wrapper daypicker-wrapper ${round && 'daypicker-wrapper-large'}`} >
      <label htmlFor={id}>
        <CalendarToday className="c-icon-new" />
      </label>
      <DatePicker
        nextMonthButtonLabel={<ChevronRight/>}
        previousMonthButtonLabel={<ChevronRight/>}
        id={id}
        data-testid="date-picker-test"
        dateFormat="dd MMM yyyy"
        selected={value}
        onChange={(date: any) => onChange(date)}
        minDate={minDate || new Date()}
        value={value}
        popperModifiers={offset}
        popperPlacement="bottom-end"
        locale={locale === 'en-US' ? 'en-AU' : locale}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
      />
    </div>
  );
};

export const TimePicker = ({ id, value, onChange, round, minTime }: any) => {
  const timeRef = useRef(null) as any;
  const isMobile = useMediaQuery('(max-width: 480px)');

  const minutesStep = 5;

  const timeArray = useMemo(() => {
    const timeArray = [];
    for (let i = 0; i < 60 * 24; i += minutesStep) {
      let hours = Math.floor(i / 60);
      let minutes = i % 60;
      timeArray.push(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
    }
    return timeArray;
  }, [minutesStep]);

  function setFormatedTime(time: string) {
    let formatedTime = new Date();
    formatedTime.setHours(Number(time?.split(':')[0]));
    formatedTime.setMinutes(Number(time?.split(':')[1]));
    return formatedTime;
  }

  useEffect(() => {
    const input = timeRef.current.querySelector('input');
    input?.setAttribute('readonly', true);
  }, []);

  return (
    <div ref={timeRef} className={`picker-wrapper timepicker-wrapper ${round && 'timepicker-wrapper-large'}`}>
      <label htmlFor={id}>
        <Clock className="c-icon-new" />
      </label>
      {isMobile
        ? <select
          defaultValue="12:00"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(setFormatedTime(e.target.value))}
        >
          <optgroup>
            {timeArray.map((time: string) => {
              return <option key={time} value={time}> {time}</option>;
            })}
          </optgroup>
        </select>
        : <DatePicker
            id={id}
            locale="nl-NL"
            selected={value}
            onChange={(date: any) => onChange(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="HH:mm"
            minTime={minTime || new Date(0, 0, 0, 0, 0)}
            maxTime={new Date(0, 0, 0, 23, 59)}
        />}
    </div>
  );
};
