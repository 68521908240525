import { gql } from '@apollo/client';

export const BOOKING = gql`
  query Booking($id: Int!, $bookingCode: String!) {
    booking(id: $id, bookingCode: $bookingCode) {
      paymentRequestReference {
        paymentServicePaymentRequestId
      }
      bookerInfo {
        email
      }
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation CreateBooking($params: BookingParams!) {
    createBooking(params: $params) {
      id
      created
      bookerInfo {
        firstName
        lastName
        email
        phone
        companyName
        reference
        traveller
        receiveJourneyUpdates
        languageIsoCode
      }
      code
      pinCode
      totalPrice
      travelIntent
      partnerReference
      currencyCode
    }
  }
`;
