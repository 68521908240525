import React from 'react';

export const HeaderLogo: React.FC<any> = () => {
  const { partnerStylingOverrides } = window.tz_globalConfigs;
  const { logoUrl } = window.tz_globalConfigs.styling;
  const urlParams = new URLSearchParams(location.search);
  return (
    <a
      href={
        urlParams.get('initialUrl')
        || urlParams.get('origin')
        || `${window.tz_globalConfigs?.customPrefixUrl || ''}/`
      }
    >
      <img
        className="logo-image"
        src={partnerStylingOverrides?.['whitelabel.logoUrl'] || logoUrl}
        alt=""
      />
    </a>
  );
};
