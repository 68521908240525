import React, { useRef, useState } from 'react';

import { useLocalize } from '../../../../hooks/useLocalize';
import { SearchInput } from '../searchInput/SearchInput';
import { SearchModal } from '../searchModal/SearchModal';
import { iconMap } from '../utils';
import { SearchLocation } from '../utils';

export interface LocationSearchInputProps {
  placeholder?: string;
  modalTitle: string;
  allowAddressSearch?: boolean;
  location?: SearchLocation;
  setLocation: (newLocation: SearchLocation) => void;
  error?: string;
  bounds?: google.maps.LatLngBoundsLiteral;
  dataTestId?: string;
}

export const LocationSearchInput: React.FC<LocationSearchInputProps> = ({
  placeholder, modalTitle, allowAddressSearch = true, location, setLocation, error, bounds, dataTestId,
}) => {
  const { translate: t } = useLocalize();
  const [modalOpen, setModalOpen] = useState(false);

  const Icon = !location ? undefined : iconMap[location.type];
  const editablePlaceholder = t(allowAddressSearch ? 'search_placeholder_hub_or_address' : 'search_placeholder_hub');

  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputValue = location?.formattedAddress ?? '';

  return (
    <>
      <SearchInput
        inputRef={inputRef}
        value={inputValue}
        placeholder={placeholder}
        Icon={Icon}
        onClick={() => setModalOpen(true)}
        error={error}
        dataTestId={dataTestId}
      />
      <SearchModal
        show={modalOpen}
        modalTitle={modalTitle}
        searchInputPlaceholder={editablePlaceholder}
        allowAddressSearch={allowAddressSearch}
        onClose={() => setModalOpen(false)}
        onClosed={() => setTimeout(() => inputRef.current?.blur(), 1)}
        onLocationSelected={(location) => {
          setLocation(location);
          setModalOpen(false);
        }}
        bounds={bounds}
      />
    </>
  );
};
