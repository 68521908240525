import './index.scss';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { render } from 'react-dom';

import { App } from './app/App';
import { icons } from './assets/icons';
import { Layout } from './components/layout/Layout';
import { LanguageProvider } from './components/localization/LanguageProvider';
import { initDatadog } from './datadog';

(React as any).icons = icons;

(async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/configs`);
    const configs: any = await response.json();
    window.environmentConfigs = configs;

    initDatadog(configs?.env);

    let bucketNumber = Number(localStorage.getItem('bucket'));

    if (!bucketNumber) {
      const randomNumberFromOneToHundred = Math.floor(Math.random() * 100) + 1;
      bucketNumber = randomNumberFromOneToHundred;
      localStorage.setItem('bucket', randomNumberFromOneToHundred.toString());
    }

    const LDProvider = await asyncWithLDProvider({
      clientSideID: configs?.launchDarklyId,
      context: {
        kind: 'user',
        key: 'ANONYMOUS_USER',
        bucket: bucketNumber,
      },
      options: {
        streaming: false,
      },
    });

    render(
      <LDProvider>
        <Layout>
          <LanguageProvider>
            <App />
          </LanguageProvider>
        </Layout>
      </LDProvider>,
      document.getElementById('root'),
    );
  } catch (e) {
    render(
      <div className="splash-screen">An error occured while fetching environment configs.</div>,
      document.getElementById('root'),
    );
  }
})();

