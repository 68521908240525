export const countryCodes = [
  {
    code: 'AF',
    displayName: 'Afghanistan | افغانستان',
  },
  {
    code: 'AL',
    displayName: 'Albania | Shqipëria',
  },
  {
    code: 'DZ',
    displayName: 'Algeria | الجزائر',
  },
  {
    code: 'AD',
    displayName: 'Andorra',
  },
  {
    code: 'AO',
    displayName: 'Angola',
  },
  {
    code: 'AG',
    displayName: 'Antigua and Barbuda',
  },
  {
    code: 'AR',
    displayName: 'Argentina',
  },
  {
    code: 'AM',
    displayName: 'Armenia | Հայաստան',
  },
  {
    code: 'AU',
    displayName: 'Australia',
  },
  {
    code: 'AT',
    displayName: 'Austria | Österreich',
  },
  {
    code: 'AZ',
    displayName: 'Azerbaijan | Azərbaycan',
  },
  {
    code: 'AX',
    displayName: 'Åland Islands',
  },
  {
    code: 'AS',
    displayName: 'American Samoa',
  },
  {
    code: 'AI',
    displayName: 'Anguilla',
  },
  {
    code: 'AQ',
    displayName: 'Antarctica',
  },
  {
    code: 'AW',
    displayName: 'Aruba',
  },
  {
    code: 'BS',
    displayName: 'Bahamas',
  },
  {
    code: 'BH',
    displayName: 'Bahrain | البحرين',
  },
  {
    code: 'BD',
    displayName: 'Bangladesh | বাংলাদেশ',
  },
  {
    code: 'BB',
    displayName: 'Barbados',
  },
  {
    code: 'BY',
    displayName: 'Belarus | Беларусь',
  },
  {
    code: 'BE',
    displayName: 'Belgium | België | Belgique | Belgien',
  },
  {
    code: 'BZ',
    displayName: 'Belize',
  },
  {
    code: 'BJ',
    displayName: 'Benin | Bénin',
  },
  {
    code: 'BT',
    displayName: 'Bhutan | འབྲུག་ཡུལ།',
  },
  {
    code: 'BO',
    displayName: 'Bolivia | Wuliwya | Volívia | Buliwya',
  },
  {
    code: 'BA',
    displayName: 'Bosnia and Herzegovina | Bosna i Hercegovina',
  },
  {
    code: 'BW',
    displayName: 'Botswana',
  },
  {
    code: 'BR',
    displayName: 'Brazil | Brasil',
  },
  {
    code: 'BN',
    displayName: 'Brunei',
  },
  {
    code: 'BG',
    displayName: 'Bulgaria | България',
  },
  {
    code: 'BF',
    displayName: 'Burkina Faso',
  },
  {
    code: 'MM',
    displayName: 'Burma | မ္ရန္&zwnj;မာ',
  },
  {
    code: 'BI',
    displayName: 'Burundi | Uburundi',
  },
  {
    code: 'BM',
    displayName: 'Bermuda',
  },
  {
    code: 'BQ',
    displayName: 'Bonaire Sint Eustatius and Saba',
  },
  {
    code: 'BV',
    displayName: 'Bouvet Island',
  },
  {
    code: 'IO',
    displayName: 'British Indian Ocean Territory',
  },
  {
    code: 'KH',
    displayName: 'Cambodia | កម្ពុជា',
  },
  {
    code: 'CM',
    displayName: 'Cameroon | Cameroun',
  },
  {
    code: 'CA',
    displayName: 'Canada',
  },
  {
    code: 'CV',
    displayName: 'Cape Verde | Cabo Verde',
  },
  {
    code: 'CF',
    displayName: 'Central African Republic | Centrafrique | Bêafrîka',
  },
  {
    code: 'TD',
    displayName: 'Chad | Tchad | تشاد',
  },
  {
    code: 'CL',
    displayName: 'Chile',
  },
  {
    code: 'CN',
    displayName: 'China | 中國/中国',
  },
  {
    code: 'CO',
    displayName: 'Colombia',
  },
  {
    code: 'KM',
    displayName: 'Comoros | Komori | Comores | جزر القمر',
  },
  {
    code: 'CD',
    displayName: 'Congo, Dem. Rep. | Congo, Rép. dém.',
  },
  {
    code: 'CG',
    displayName: 'Congo, Republic | Congo, République',
  },
  {
    code: 'CR',
    displayName: 'Costa Rica',
  },
  {
    code: 'CI',
    displayName: 'Cote d’Ivoire | Côte d’Ivoire',
  },
  {
    code: 'HR',
    displayName: 'Croatia | Hrvatska',
  },
  {
    code: 'CU',
    displayName: 'Cuba',
  },
  {
    code: 'CY',
    displayName: 'Cyprus | Κύπρος | Kıbrıs',
  },
  {
    code: 'CZ',
    displayName: 'Czechia | Česko',
  },
  {
    code: 'KY',
    displayName: 'Cayman Islands',
  },
  {
    code: 'CX',
    displayName: 'Christmas Island',
  },
  {
    code: 'CC',
    displayName: 'Cocos (Keeling) Islands',
  },
  {
    code: 'CK',
    displayName: 'Cook Islands',
  },
  {
    code: 'CW',
    displayName: 'Curaçao',
  },
  {
    code: 'DK',
    displayName: 'Denmark | Danmark',
  },
  {
    code: 'DJ',
    displayName: 'Djibouti | جيبوتي',
  },
  {
    code: 'DM',
    displayName: 'Dominica',
  },
  {
    code: 'DO',
    displayName: 'Dominican Republic | República Dominicana',
  },
  {
    code: 'TL',
    displayName: 'East Timor | Timór-Leste | Timor-Leste',
  },
  {
    code: 'EC',
    displayName: 'Ecuador',
  },
  {
    code: 'EG',
    displayName: 'Egypt | مصر',
  },
  {
    code: 'SV',
    displayName: 'El Salvador',
  },
  {
    code: 'GQ',
    displayName:
      'Equatorial Guinea | Guinea Ecuatorial | Guinée équatoriale | Guiné Equatorial',
  },
  {
    code: 'ER',
    displayName: 'Eritrea | ኤርትራ | إرتريا',
  },
  {
    code: 'EE',
    displayName: 'Estonia | Eesti',
  },
  {
    code: 'ET',
    displayName: 'Ethiopia | ኢትዮጵያ',
  },
  {
    code: 'SZ',
    displayName: 'Eswatini',
  },
  {
    code: 'FJ',
    displayName: 'Fiji | Viti | फ़िजी',
  },
  {
    code: 'FI',
    displayName: 'Finland | Suomi',
  },
  {
    code: 'FR',
    displayName: 'France',
  },
  {
    code: 'FK',
    displayName: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FO',
    displayName: 'Faroe Islands',
  },
  {
    code: 'GF',
    displayName: 'French Guiana',
  },
  {
    code: 'PF',
    displayName: 'French Polynesia',
  },
  {
    code: 'TF',
    displayName: 'French Southern Territories',
  },
  {
    code: 'GA',
    displayName: 'Gabon',
  },
  {
    code: 'GM',
    displayName: 'Gambia',
  },
  {
    code: 'GE',
    displayName: 'Georgia | საქართველო',
  },
  {
    code: 'DE',
    displayName: 'Germany | Deutschland',
  },
  {
    code: 'GH',
    displayName: 'Ghana',
  },
  {
    code: 'GR',
    displayName: 'Greece | Ελλάδα',
  },
  {
    code: 'GD',
    displayName: 'Grenada',
  },
  {
    code: 'GT',
    displayName: 'Guatemala',
  },
  {
    code: 'GN',
    displayName: 'Guinea | Guinée',
  },
  {
    code: 'GW',
    displayName: 'Guinea-Bissau | Guiné-Bissau',
  },
  {
    code: 'GY',
    displayName: 'Guyana',
  },
  {
    code: 'GI',
    displayName: 'Gibraltar',
  },
  {
    code: 'GL',
    displayName: 'Greenland',
  },
  {
    code: 'GP',
    displayName: 'Guadeloupe',
  },
  {
    code: 'GU',
    displayName: 'Guam',
  },
  {
    code: 'GG',
    displayName: 'Guernsey',
  },
  {
    code: 'HT',
    displayName: 'Haiti | Haïti | Ayiti',
  },
  {
    code: 'HN',
    displayName: 'Honduras',
  },
  {
    code: 'HU',
    displayName: 'Hungary | Magyarország',
  },
  {
    code: 'HM',
    displayName: 'Heard Island and McDonald Islands',
  },
  {
    code: 'VA',
    displayName: 'Holy See',
  },
  {
    code: 'HK',
    displayName: 'Hong Kong',
  },
  {
    code: 'IS',
    displayName: 'Iceland | Ísland',
  },
  {
    code: 'IN',
    displayName: 'India | भारत',
  },
  {
    code: 'ID',
    displayName: 'Indonesia',
  },
  {
    code: 'IR',
    displayName: 'Iran | ایران',
  },
  {
    code: 'IQ',
    displayName: 'Iraq | العراق',
  },
  {
    code: 'IE',
    displayName: 'Ireland | Éire',
  },
  {
    code: 'IL',
    displayName: 'Israel | إسرائيل | ישראל',
  },
  {
    code: 'IT',
    displayName: 'Italy | Italia',
  },
  {
    code: 'IM',
    displayName: 'Isle of Man',
  },
  {
    code: 'JM',
    displayName: 'Jamaica',
  },
  {
    code: 'JP',
    displayName: 'Japan | 日本',
  },
  {
    code: 'JO',
    displayName: 'Jordan | الأردن',
  },
  {
    code: 'JE',
    displayName: 'Jersey',
  },
  {
    code: 'KZ',
    displayName: 'Kazakhstan | Қазақстан',
  },
  {
    code: 'KE',
    displayName: 'Kenya',
  },
  {
    code: 'KI',
    displayName: 'Kiribati',
  },
  {
    code: 'KP',
    displayName: 'Korea, North | 조선',
  },
  {
    code: 'KR',
    displayName: 'Korea, South | 한국',
  },
  {
    code: 'KV',
    displayName: 'Kosovo | Kosova | Косово',
  },
  {
    code: 'KW',
    displayName: 'Kuwait | الكويت',
  },
  {
    code: 'KG',
    displayName: 'Kyrgyzstan | Кыргызстан',
  },
  {
    code: 'LA',
    displayName: 'Laos | ປະເທດ​ລາວ',
  },
  {
    code: 'LV',
    displayName: 'Latvia | Latvija',
  },
  {
    code: 'LB',
    displayName: 'Lebanon | لبنان',
  },
  {
    code: 'LS',
    displayName: 'Lesotho',
  },
  {
    code: 'LR',
    displayName: 'Liberia',
  },
  {
    code: 'LY',
    displayName: 'Libya | ليبيا',
  },
  {
    code: 'LI',
    displayName: 'Liechtenstein',
  },
  {
    code: 'LT',
    displayName: 'Lithuania | Lietuva',
  },
  {
    code: 'LU',
    displayName: 'Luxembourg | Luxemburg | Lëtzebuerg',
  },
  {
    code: 'MK',
    displayName: 'Macedonia | Македонија',
  },
  {
    code: 'MG',
    displayName: 'Madagascar | Madagasikara',
  },
  {
    code: 'MW',
    displayName: 'Malawi | Malaŵi',
  },
  {
    code: 'MY',
    displayName: 'Malaysia',
  },
  {
    code: 'MV',
    displayName: 'Maldives | ދިވެހިރާއްޖެ',
  },
  {
    code: 'ML',
    displayName: 'Mali',
  },
  {
    code: 'MT',
    displayName: 'Malta',
  },
  {
    code: 'MH',
    displayName: 'Marshall Islands | Aelōñin Ṃajeḷ',
  },
  {
    code: 'MR',
    displayName: 'Mauritania | موريتانيا | Mauritanie',
  },
  {
    code: 'MU',
    displayName: 'Mauritius | Maurice',
  },
  {
    code: 'MX',
    displayName: 'Mexico | México | Mēxihco',
  },
  {
    code: 'FM',
    displayName: 'Micronesia',
  },
  {
    code: 'MD',
    displayName: 'Moldova',
  },
  {
    code: 'MC',
    displayName: 'Monaco',
  },
  {
    code: 'MN',
    displayName: 'Mongolia | Монгол улс',
  },
  {
    code: 'ME',
    displayName: 'Montenegro | Crna Gora / Црна Гора',
  },
  {
    code: 'MA',
    displayName: 'Morocco | المغرب',
  },
  {
    code: 'MZ',
    displayName: 'Mozambique | Moçambique',
  },
  {
    code: 'MO',
    displayName: 'Macao',
  },
  {
    code: 'MQ',
    displayName: 'Martinique',
  },
  {
    code: 'YT',
    displayName: 'Mayotte',
  },
  {
    code: 'MS',
    displayName: 'Montserrat',
  },
  {
    code: 'NA',
    displayName: 'Namibia',
  },
  {
    code: 'NR',
    displayName: 'Nauru | Naoero',
  },
  {
    code: 'NP',
    displayName: 'Nepal | नेपाल',
  },
  {
    code: 'NL',
    displayName: 'Netherlands | Nederland',
  },
  {
    code: 'NZ',
    displayName: 'New Zealand | Aotearoa',
  },
  {
    code: 'NI',
    displayName: 'Nicaragua',
  },
  {
    code: 'NE',
    displayName: 'Niger',
  },
  {
    code: 'NG',
    displayName: 'Nigeria',
  },
  {
    code: 'NO',
    displayName: 'Norway | Norge / Noreg',
  },
  {
    code: 'NC',
    displayName: 'New Caledonia',
  },
  {
    code: 'NU',
    displayName: 'Niue',
  },
  {
    code: 'NF',
    displayName: 'Norfolk Island',
  },
  {
    code: 'MP',
    displayName: 'Northern Mariana Islands',
  },
  {
    code: 'OM',
    displayName: 'Oman | عمان',
  },
  {
    code: 'PK',
    displayName: 'Pakistan | پاکستان',
  },
  {
    code: 'PW',
    displayName: 'Palau | Belau',
  },
  {
    code: 'PS',
    displayName: 'Palestine | فلسطين',
  },
  {
    code: 'PA',
    displayName: 'Panama | Panamá',
  },
  {
    code: 'PG',
    displayName: 'Papua New Guinea | Papua Niugini',
  },
  {
    code: 'PY',
    displayName: 'Paraguay | Paraguái',
  },
  {
    code: 'PE',
    displayName: 'Peru | Perú',
  },
  {
    code: 'PH',
    displayName: 'Philippines | Pilipinas',
  },
  {
    code: 'PL',
    displayName: 'Poland | Polska',
  },
  {
    code: 'PT',
    displayName: 'Portugal',
  },
  {
    code: 'PN',
    displayName: 'Pitcairn',
  },
  {
    code: 'PR',
    displayName: 'Puerto Rico',
  },
  {
    code: 'QA',
    displayName: 'Qatar | قطر',
  },
  {
    code: 'RO',
    displayName: 'Romania | România',
  },
  {
    code: 'RU',
    displayName: 'Russia | Россия',
  },
  {
    code: 'RW',
    displayName: 'Rwanda',
  },
  {
    code: 'RE',
    displayName: 'Réunion',
  },
  {
    code: 'KN',
    displayName: 'Saint Kitts and Nevis',
  },
  {
    code: 'LC',
    displayName: 'Saint Lucia',
  },
  {
    code: 'VC',
    displayName: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'WS',
    displayName: 'Samoa | Sāmoa',
  },
  {
    code: 'SM',
    displayName: 'San Marino',
  },
  {
    code: 'ST',
    displayName: 'Sao Tome and Principe | São Tomé e Príncipe',
  },
  {
    code: 'SA',
    displayName: 'Saudi Arabia | العربية السعودية',
  },
  {
    code: 'SN',
    displayName: 'Senegal | Sénégal',
  },
  {
    code: 'RS',
    displayName: 'Serbia | Србија / Srbija',
  },
  {
    code: 'SC',
    displayName: 'Seychelles | Sesel',
  },
  {
    code: 'SL',
    displayName: 'Sierra Leone',
  },
  {
    code: 'SG',
    displayName: 'Singapore | 新加坡 | Singapura | சிங்கப்பூர்',
  },
  {
    code: 'SK',
    displayName: 'Slovakia | Slovensko',
  },
  {
    code: 'SI',
    displayName: 'Slovenia | Slovenija',
  },
  {
    code: 'SB',
    displayName: 'Solomon Islands',
  },
  {
    code: 'SO',
    displayName: 'Somalia | Soomaaliya | الصومال',
  },
  {
    code: 'ZA',
    displayName: 'South Africa | Suid-Afrika',
  },
  {
    code: 'SS',
    displayName: 'South Sudan',
  },
  {
    code: 'ES',
    displayName: 'Spain | España',
  },
  {
    code: 'LK',
    displayName: 'Sri Lanka | ශ්&zwj;රී ලංකාව | இலங்கை ',
  },
  {
    code: 'BL',
    displayName: 'Saint Barthélemy',
  },
  {
    code: 'SH',
    displayName: 'Saint Helena Ascension and Tristan da Cunha',
  },
  {
    code: 'MF',
    displayName: 'Saint Martin',
  },
  {
    code: 'PM',
    displayName: 'Saint Pierre and Miquelon',
  },
  {
    code: 'SX',
    displayName: 'Sint Maarten',
  },
  {
    code: 'GS',
    displayName: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'SD',
    displayName: 'Sudan',
  },
  {
    code: 'SR',
    displayName: 'Suriname',
  },
  {
    code: 'SJ',
    displayName: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SE',
    displayName: 'Sweden',
  },
  {
    code: 'CH',
    displayName: 'Switzerland',
  },
  {
    code: 'SY',
    displayName: 'Syria',
  },
  {
    code: 'TW',
    displayName: 'Taiwan',
  },
  {
    code: 'TJ',
    displayName: 'Tajikistan',
  },
  {
    code: 'TZ',
    displayName: 'Tanzania',
  },
  {
    code: 'TH',
    displayName: 'Thailand',
  },
  {
    code: 'TG',
    displayName: 'Togo',
  },
  {
    code: 'TK',
    displayName: 'Tokelau',
  },
  {
    code: 'TO',
    displayName: 'Tonga',
  },
  {
    code: 'TT',
    displayName: 'Trinidad and Tobago',
  },
  {
    code: 'TN',
    displayName: 'Tunisia',
  },
  {
    code: 'TR',
    displayName: 'Turkey',
  },
  {
    code: 'TM',
    displayName: 'Turkmenistan',
  },
  {
    code: 'TC',
    displayName: 'Turks and Caicos Islands',
  },
  {
    code: 'TV',
    displayName: 'Tuvalu',
  },
  {
    code: 'UG',
    displayName: 'Uganda',
  },
  {
    code: 'UA',
    displayName: 'Ukraine',
  },
  {
    code: 'AE',
    displayName: 'United Arab Emirates',
  },
  {
    code: 'GB',
    displayName: 'United Kingdom | England',
  },
  {
    code: 'US',
    displayName: 'United States of America | USA',
  },
  {
    code: 'UM',
    displayName: 'United States Minor Outlying Islands',
  },
  {
    code: 'UY',
    displayName: 'Uruguay',
  },
  {
    code: 'UZ',
    displayName: 'Uzbekistan',
  },
  {
    code: 'VU',
    displayName: 'Vanuatu',
  },
  {
    code: 'VE',
    displayName: 'Venezuela',
  },
  {
    code: 'VN',
    displayName: 'Vietnam',
  },
  {
    code: 'VG',
    displayName: 'Virgin Islands (British)',
  },
  {
    code: 'VI',
    displayName: 'Virgin Islands (U.S.)',
  },
  {
    code: 'WF',
    displayName: 'Wallis and Futuna',
  },
  {
    code: 'EH',
    displayName: 'Western Sahara',
  },
  {
    code: 'YE',
    displayName: 'Yemen',
  },
  {
    code: 'ZM',
    displayName: 'Zambia',
  },
  {
    code: 'ZW',
    displayName: 'Zimbabwe',
  },
];
