import { memoize } from 'lodash';

export const fetchStoryblokStory = async (storyPath: string, lng: string) => {
  const { baseUrl, token } = window.environmentConfigs?.storyblok ?? {};

  const queryParams = `language=${lng}&token=${token}&version=published`;
  let result = null;

  try {
    result = await fetch(`${baseUrl}${storyPath}?${queryParams}`)
      .then((res) => res.json());

    return result?.story?.content ?? {};
  } catch (e) {
    console.log(`Error in fetching ${storyPath} from Storyblock - `, e);
    return {};
  }
};

export const fetchLanguageOptions = memoize(async (lng = 'en') => {
  const { bookingToolLanguageOptionsStoryPath } = window.environmentConfigs?.storyblok ?? {};
  const content = await fetchStoryblokStory(bookingToolLanguageOptionsStoryPath, lng);
  return content?.Options;
});
