import { getCurrencyInfo } from '@transferz/fe-utils';
import { TravelAddonType } from '@transferz/models';
import { useLocalize } from 'localize-react';
import React from 'react';

import { ReactComponent as Baby } from '../../assets/svgs/baby.svg';
import { ReactComponent as Booster } from '../../assets/svgs/booster.svg';
import { ReactComponent as CheckEmpty } from '../../assets/svgs/checkEmpty.svg';
import { ReactComponent as CheckFilled } from '../../assets/svgs/checkFilled.svg';
import { ReactComponent as ExtraStop } from '../../assets/svgs/extraStop.svg';
import { ReactComponent as MeetGreet } from '../../assets/svgs/meetGreet.svg';
import { ReactComponent as Pets } from '../../assets/svgs/pets.svg';
import { ReactComponent as SpecialLuggage } from '../../assets/svgs/specialLuggage.svg';
import { InputSpinner } from '../../components/inputSpinner/InputSpinner';

const renderIcon = (travelAddon: any) => {
  switch (travelAddon?.type) {
  case TravelAddonType.MeetGreet:
    return (<MeetGreet className="option-icon" />);
  case TravelAddonType.BabySeat:
    return (<Baby className="option-icon" />);
  case TravelAddonType.BoosterSeat:
    return (<Booster className="option-icon" />);
  case TravelAddonType.Pets:
    return (<Pets className="option-icon" />);
  case TravelAddonType.ExtraStop:
    return (<ExtraStop className="option-icon" />);
  case TravelAddonType.SpecialLuggage:
    return (<SpecialLuggage className="option-icon" />);
  default:
    return (<></>);
  }
};

interface IAddonContainerProps {
  travelAddon: any,
  quote: any,
  possibleSelectionAmount: any,
  selectAddonAmount: any,
  selectAddon: any
}

export default function AddonContainer({ travelAddon, quote, possibleSelectionAmount, selectAddonAmount, selectAddon }: IAddonContainerProps) {
  const { translate: t } = useLocalize();
  const formatPrice = (price: number) => {
    return price % 1 !== 0 ? price.toFixed(2) : price;
  };
  return (
    travelAddon ? <div className="travel-addon-container" >
      <div className="d-flex">
        {renderIcon(travelAddon)}
        <div>
          <div data-testid="select_travel-addon" className="travel-addon">
            <div className="travel-addon-title">{t(travelAddon.type)}</div>
            {travelAddon.price.price
              ? (
                <div className="travel-addon-price">
                  {getCurrencyInfo(quote.currencyCode).symbol}&nbsp;{formatPrice(travelAddon.price.price * quote.ids.length)}&nbsp;
                  {(travelAddon.type === TravelAddonType.BabySeat || travelAddon.type === TravelAddonType.BoosterSeat) && (
                    <>
                      <span className="ml-1 mr-1">/</span>
                      <span>{t('seat')}</span>
                    </>
                  )}
                </div>
              )
              : (!travelAddon.price.price && travelAddon.type === TravelAddonType.MeetGreet) ? <div className="travel-addon-price">{t('includedFree')}</div> : < div className="travel-addon-price">{t('free')}</div>
            }
          </div>
        </div>
      </div>
      <div>
        {(travelAddon.type === TravelAddonType.BabySeat || travelAddon.type === TravelAddonType.BoosterSeat)
          ? (
            <InputSpinner
              maxValue={possibleSelectionAmount?.[quote?.ids?.[0]]?.[travelAddon?.type]?.maxAllowed ? possibleSelectionAmount?.[quote?.ids?.[0]]?.[travelAddon?.type]?.maxAllowed.length : travelAddon.maxAllowed}
              name={travelAddon.type}
              onChange={(value: number) => selectAddonAmount(quote, travelAddon, value)}
            />
          )
          : (
            <label className="custom-control">
              <input
                onClick={(event) => {
                  selectAddon(event, quote, travelAddon);
                }}
                className="custom-control-input"
                type="checkbox"
                defaultChecked={!travelAddon.price.price && travelAddon.type === TravelAddonType.MeetGreet}
                disabled={!travelAddon.price.price && travelAddon.type === TravelAddonType.MeetGreet}
              />
              <CheckEmpty />
              <CheckFilled style={!travelAddon.price.price && travelAddon.type === TravelAddonType.MeetGreet ? { filter: 'brightness(0) saturate(100%) invert(36%) sepia(97%) saturate(510%) hue-rotate(115deg) brightness(100%) contrast(96%)' } : {}} />
            </label>
          )
        }
      </div>
    </div > : null
  );
}
