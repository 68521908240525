import './results.scss';

import { CCol, CRow } from '@coreui/react';
import React from 'react';
import { useParams } from 'react-router';

import { useLocalize } from '../../hooks/useLocalize';

export const Results: React.FC<any> = ({ status, email }) => {
  const { translate: t } = useLocalize();
  const { code } = useParams();
  return (
    <>
      {
        (status === 'Authorised' || status === 'Pending')
          ? (
            <CRow>
              <CCol className="payment-result">
                <div className="payment-result-status"
                  style={{ backgroundColor: window.tz_globalConfigs?.styling?.primaryColor || '#48a947' }}>
                  <img className="check-icon" src={`${window.tz_globalConfigs.primaryUrl}/transferz/images/icon_success.png`} alt="" />
                  <div data-testid="result_success-text" className="h1 font-bold" style={{ color: window.tz_globalConfigs?.styling?.backgroundContrastColor || '#fff' }}>
                    {t('paymentResults.successText')}
                  </div>
                  <div className="h4 font-bold" style={{ color: window.tz_globalConfigs?.styling?.backgroundContrastColor || '#fff' }}>
                    {t('paymentResults.completeText')}
                  </div>
                </div>
                <div className="payment-result-info"
                  style={{ color: window.tz_globalConfigs?.styling?.backgroundContrastColor || '#fff' }}>
                  <div className="next-steps pb-3">{t('paymentResults.nextSteps')}:</div>
                  <div className="email-info pb-3">
                    {status === 'Authorised' ? t('paymentResults.authorisedTextStart') : t('paymentResults.pendingTextStart')}
                    <strong> {email}</strong>
                    {status === 'Authorised' ? t('paymentResults.authorisedTextEnd') : t('paymentResults.pendingTextEnd')}
                  </div>
                  <div className="pb-3">
                    {t('paymentResults.infoTextMiddle')}
                  </div>
                  <div className="pb-3">
                    {t('paymentResults.infoTextEnd')}
                  </div>
                  <div className="booking-code">
                    <strong>{t('paymentResults.bookingCode')}</strong>: {code}
                  </div>
                </div>
              </CCol>
            </CRow>
          )
          : (
            <CRow className={`justify-content-center rounded-lg pt-3 pb-3 ${status !== 'Authorised' && status !== 'Pending' ? 'border-danger' : 'border-success'}`}>
              <h4 className="text-danger mb-0 pl-2 pr-2">
                {t('paymentResults.errorMessage')}
              </h4>
            </CRow>
          )
      }
    </>
  );
};
