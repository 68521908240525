import './termsAndConditions.scss';

import React, { useState } from 'react';

import { Header } from '../../components/header/Header';
import { useLocalize } from '../../hooks/useLocalize';
import { TermsAndConditionsTransportation } from './TermsAndConditionsTransportation';
import { TermsAndConditionsTraveller } from './TermsAndConditionsTraveller';

enum Terms {
  TravellerTerm = 'TRAVELLER_TERM',
  TransportationTerm = 'TRANSPORTATION_TERM',
}

export const TermsAndConditions: React.FC<any> = () => {
  const { translate: t } = useLocalize();
  const [selectedTab, setSelectedTab] = useState(Terms.TravellerTerm);

  return (
    <>
      <div className="terms-and-conditions-header">
        <h1>{t('termsAndConditions.title')}</h1>
        <Header showCurrency={false} />
      </div>
      <div className="terms-and-conditions">
        <div className="terms-and-conditions-body">
          <h4>
            {t('termsAndConditions.subTitle', {
              user: selectedTab === Terms.TravellerTerm ? 'user' : '',
            }).toUpperCase()}
          </h4>
          <div className="terms-and-conditions-tabs">
            <span
              onClick={() => setSelectedTab(Terms.TravellerTerm)}
              className={`${selectedTab === Terms.TravellerTerm ? 'active' : ''}`}
            >
              {t('termsAndConditions.tab.traveller')}
            </span>
            <span
              onClick={() => setSelectedTab(Terms.TransportationTerm)}
              className={`${selectedTab === Terms.TransportationTerm ? 'active' : ''}`}
            >
              {t('termsAndConditions.tab.transportation')}
            </span>
          </div>
          <div className="terms-and-conditions-tab-body">
            {selectedTab === Terms.TravellerTerm
              ? <TermsAndConditionsTraveller />
              : <TermsAndConditionsTransportation />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
