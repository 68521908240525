import CIcon from '@coreui/icons-react';
import React, { useMemo, useRef, useState } from 'react';
import { components, ValueContainerProps } from 'react-select';
import _AsyncSelect from 'react-select/async';

import { ReactComponent as SearchIcon } from '../../../../assets/svgs/search.svg';
import { useLocalize } from '../../../../hooks/useLocalize';
import { useSearchLocation } from '../../../../hooks/useSearchLocation';
import { ReactComponent as DangerIcon } from '../danger.svg';
import { SearchResultItem } from '../searchResultItem/SearchResultItem';
import { SearchLocation } from '../utils';
import { SearchResultOption } from '../utils';
import { iconMap } from '../utils';
import styles from './LocationSearchInputDesktop.module.scss';

class AsyncSelect extends _AsyncSelect<SearchResultOption, false> { }

export interface LocationSearchInputProps {
  placeholder?: string;
  allowAddressSearch?: boolean;
  location?: SearchLocation;
  setLocation: (newLocation: SearchLocation | undefined) => void;
  error?: string;
  bounds?: google.maps.LatLngBoundsLiteral;
  dataTestId?: string;
}

export const LocationSearchInputDesktop: React.FC<LocationSearchInputProps> = ({
  placeholder, allowAddressSearch = true, location, setLocation, error, bounds, dataTestId,
}) => {
  const nativeRef = useRef<HTMLDivElement | null>(null);
  const { translate: t } = useLocalize();
  const { handleSearch } = useSearchLocation(allowAddressSearch, bounds);
  const [inputValue, setInputValue] = useState<string>();
  const value = useMemo(() => location ? {
    title: location.title,
    subtitle: location.subtitle,
    type: location.type,
    label: location.formattedAddress,
    innerResult: location,
  } : undefined, [location]);

  const Icon = !location ? SearchIcon : iconMap[location.type];
  const editablePlaceholder = t(allowAddressSearch ? 'search_placeholder_hub_or_address' : 'search_placeholder_hub');

  const ValueContainer = useMemo(() => (props: React.PropsWithChildren<ValueContainerProps<SearchResultOption, false>>) => (
    <>
      {Icon && <Icon className={styles.icon} />}
      {!value && <span className="direction-placeholder"><b>{placeholder}</b></span>}
      <components.ValueContainer {...props} className={styles.valueContainer} />
      {value && <span className="clear-icon" onClick={() => {
        setLocation(undefined);
        setTimeout(() => nativeRef.current?.querySelector('input')?.focus(), 1);
      }}>
        <CIcon size="md" name="cil-x-circle" />
      </span>}
    </>
  ), [Icon, value, placeholder, setLocation]);

  const loadOptions = (inputValue: string, callback: any) => {
    setInputValue(inputValue);
    handleSearch(inputValue, (results) =>  {
      if (results !== 'NOT_RESULTS_FOUND') {
        callback(results);
      } else {
        callback([]);
      }
    });
  };

  return (
    <div className={styles.componentWrapper} ref={nativeRef} data-testid={dataTestId}>
      <AsyncSelect
        className={`${styles.locationSearchInputDesktop} destination-select`}
        components={{
          ValueContainer: ValueContainer,
          Menu: (props) => (
            (inputValue?.length ?? 0) >= 3 ? <components.Menu {...props} className={styles.suggestionList} /> : null
          ),
          Option: (props) => (
            <components.Option {...props} className="suggestion-item">
              <SearchResultItem
                {...props.data}
                className="suggestion-item__content"
                style={{ padding: '0.5rem 1rem' }}
              />
            </components.Option>
          ),
        }}
        styles={{
          control: (base) => ({
            ...base,
            height: '50px',
            gap: '8px',
            border: `1px solid ${ error ? '#FC363B' : '#bfcbd4' } !important`,
            borderRadius: '4px',
            boxSizing: 'content-box',
            cursor: 'pointer',
            ...(error ? { background: '#FDF0ED' } : {}),
          }),
          menuList: (base) => ({
            ...base,
            padding: '0',
            maxHeight: '240px',
          }),
          placeholder: (base) => ({
            ...base,
            marginTop: '1px',
          }),
          noOptionsMessage: (base) => ({
            ...base,
            color: '#000',
            fontVariantNumeric: 'lining-nums proportional-nums',
            fontFeatureSettings: '\'clig\' off, \'liga\' off',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontStyle: 'italic',
            fontWeight: 500,
            lineHeight: '20px',
            textAlign: 'left',
          }),
        }}
        key="destination"
        loadOptions={loadOptions}
        placeholder={editablePlaceholder}
        noOptionsMessage={({ inputValue }) => inputValue.length >= 3
          ? t(`search_no_results_found${allowAddressSearch ? '_hub_or_address' : '_hub'}`)
          : undefined
        }
        value={value}
        onChange={(option) => {
          const innerResult = option?.innerResult;
          setLocation(innerResult!);
        }}
        isClearable={!!value}
        backspaceRemovesValue={false}
        escapeClearsValue={false}
      />
      {!!error && <div className="error-message"><DangerIcon /><span>{error}</span></div>}
    </div>
  );
};
