import moment from 'moment';

import { ILanguageOption } from '../components/localization/LanguageContext';
import { languagesMap } from '../constants/languagesMap';

export const getQueryStringParams = (query: string) => {
  return query
    ? ((/^[?#]/).test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params: any, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
};

export const parseQuoteValue = (inputStr: string) => {
  const parsedData = JSON.parse(inputStr);
  if (Array.isArray(parsedData) && parsedData.length && parsedData[0].quoteId) {
    return parsedData[0].quoteId;
  }
  return '';
};

export const setHtmlTextDirection = (lng: string) => {
  if (lng === 'ar-SA') {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    document.body.classList.add('rtl');
  } else {
    document.getElementsByTagName('html')[0].removeAttribute('dir');
    document.body.classList.remove('rtl');
  }
};

export const getDefaultLanguage = (supportedLanguages: ILanguageOption[]): string => {
  const browserLanguage: string = languagesMap[navigator?.language];
  let lng: string = browserLanguage;

  try {
    const preferredLanguage = localStorage?.getItem('preferredLanguage');
    if (preferredLanguage) {
      lng = JSON.parse(preferredLanguage!);
    }
  } catch (e) {
    console.error(e);
  }

  const isLanguageSupported = supportedLanguages.some((supportedLanguage) => supportedLanguage.language_iso_code === lng);

  return isLanguageSupported ? lng : supportedLanguages[0].language_iso_code;
};

export const formatDate = (date: string) => {
  return moment(date).format('LLLL')
    .replace(/^(\w)/, (match) => match.toUpperCase());
};

export const formatTimeInterval = (timeInSecond: number, locale: any = 'en-US') => {
  const hours = Math.floor(timeInSecond / 3600);
  const minutes = Math.floor((timeInSecond % 3600) / 60);

  // Create a formatter for hours
  const hourFormatter = new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: 'hour',
    unitDisplay: 'long',
  });

  // Create a formatter for minutes
  const minuteFormatter = new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: 'minute',
    unitDisplay: 'long',
  });

  // Format the hours and minutes
  const formattedHours = hourFormatter.format(hours);
  const formattedMinutes = minuteFormatter.format(minutes);

  // Return the formatted time interval
  return hours ? `${formattedHours} ${formattedMinutes}` : formattedMinutes;
};
