import './pricebreakdown.scss';

import { getCurrencyInfo } from '@transferz/fe-utils';
import { TravelAddonType } from '@transferz/models';
import React from 'react';

import { useLocalize } from '../../hooks/useLocalize';

interface PriceBreakdownProps {
  travelAddons: any;
  totalPrice: string;
  initialPrice: string;
  preferredCurrencyCode: string;
  isRoundTrip: string;
  pricePerPassenger?: string;
}

const PriceBreakdown: React.FC<PriceBreakdownProps> = ({ travelAddons, totalPrice, initialPrice, pricePerPassenger, preferredCurrencyCode, isRoundTrip }) => {
  const { translate: t } = useLocalize();
  const formatPrice = (price: number) => {
    return price.toFixed(2);
  };

  const currancySymbol = getCurrencyInfo(preferredCurrencyCode).symbol;

  return (
    <div className="price-card">
      <h3>{t('priceBreakdown')}</h3>
      <div className="addon">
        <div>{t('baseJourneyPrice')}</div>
        <div className="addonPrice">
          {initialPrice}
          {pricePerPassenger !== 'undefined' && <div className="seat">{`${currancySymbol} ${pricePerPassenger} / ${t('person')}`}</div>}
        </div>
      </div>
      {travelAddons.map((addon: any) => <div className="addon" key={addon.type}>
        <div>{t(addon.type)}{addon.amount > 1 && ` (${addon.amount})`}</div>
        <div className="addonPrice">
          {addon.price.price ? <>
            {currancySymbol}&nbsp;{formatPrice((isRoundTrip === 'false' ? addon.price.price : addon.price.price * 2) * addon.amount)}
            {(addon.type === TravelAddonType.BabySeat || addon.type === TravelAddonType.BoosterSeat) && addon.amount > 1 && (
              <span className="seat">{`(${currancySymbol} ${formatPrice(isRoundTrip === 'false' ? addon.price.price : addon.price.price * 2)} / ${t('seat')})`}</span>
            )} </> : <span>{t('free')}</span>}
        </div>
      </div>)}
      <div className="summary">
        <div>
          <div>{t('totalPrice')}</div>
          <div className="tax">{t('taxIncluded')}</div>
        </div>
        <div data-testid="totalPrice">{totalPrice}</div>
      </div>
    </div>
  );
};

export default PriceBreakdown;
