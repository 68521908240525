import { gql } from '@apollo/client';

export const QUOTES = gql`
  query Quotes($params: RetrieveQuotesParams!) {
    quotes(params: $params) {
      vehicleCategory
      passengerCapacity
      luggageCapacity
      pricePerPassenger
      ids
      price 
      requestId
      discount
      combinedBabyAndBoosterSeatsCapacity
      travelAddons {
        type
        fare
        quoteTravelAddonIdPairs
        price {
          price
          vat
          fareVat
          partnerMargin
          partnerMarginVat
          platformMargin
          platformMarginVat
        }
        maxAllowed
      }
      currencyCode
      includedWaitingTimeInMinutes
      vehicleModels
    }
  }
`;
