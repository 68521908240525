import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import { useDataLayer } from '../hooks/useDataLayer';
import Booking from '../routes/booking/Booking';
import ContactInformation from '../routes/contactInformation/ContactInformation';
import Home from '../routes/home/Home';
import PaymentResult from '../routes/paymentResult/PaymentResult';
import PrivacyPolicy from '../routes/privacyPolicy/PrivacyPolicy';
import Start from '../routes/start/Start';
import TermsAndConditions from '../routes/termsAndConditions/TermsAndConditions';
import VehicleSelection from '../routes/vehicleSelection/VehicleSelection';

const Payment = React.lazy(() => import('../routes/payment/Payment'));

export const AppRoutes = () => {
  const flags = useFlags();
  const { trackEvent } = useDataLayer();

  useEffect(() => {
    const flagKeys = Object.keys(flags);
    if (flagKeys.length > 0) {
      Object.keys(flags).forEach((key) => {
        trackEvent({
          event: 'feature_flag_initiated',
          feature_flag_name: key,
          feature_flag_value: flags[key],
        });
      });
    }
  }, [flags]);

  const bookingRoute = (
    <>
      <Route path="/vehicle-selection" element={<VehicleSelection />} />
      <Route path="/contact-information" element={<ContactInformation />} />
      <Route path="/payment/:code/:id/:totalPrice/:currencyCode" element={<Payment />} />
    </>
  );
  return (
    <BrowserRouter >
      <Routes basename={`${window.tz_globalConfigs?.customPrefixUrl || ''}`}>
        <Route path="/" element={<Home />} />
        <Route path="/start" element={<Start />} />
        <Route path="/payment-result/:code/:totalPrice/:currencyCode/:countryCode/:id" element={<PaymentResult />} />
        <Route path="/booking" element={<Booking />}>
          {bookingRoute}
        </Route>
        <Route path="/booking/:code" element={<Booking />}>
          {bookingRoute}
        </Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </BrowserRouter >
  );
};
