import { useQueryMultilingualHubs } from '../queries/hub/hooks/useQueryMultilingualHubs';

export const useFetchHubByAddress = (locale: string) => {
  const { refetch: getMultilingualHubs } = useQueryMultilingualHubs({
    variables: {
      params: {
        page: 0,
        size: 10,
        preferredLanguage: locale,
        query: '',
      },
    },
    skip: true,
  });

  const getHubByAddress = async (hubAddress: string) => {
    try {
      const result = await getMultilingualHubs({
        params: {
          page: 0,
          size: 10,
          preferredLanguage: locale,
          query: hubAddress,
        },
      });

      return result?.data?.multilingualHubs?.results[0];
    } catch (e) {
      console.error(e);
    }
  };

  return getHubByAddress;
};
