import CIcon from '@coreui/icons-react';
import { getDateDiff } from '@transferz/utils';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useSessionStorage } from '../../../hooks/sessionStorage/useSessionStorage';
import { useLocalize } from '../../../hooks/useLocalize';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useQueryMultilingualHubs } from '../../../queries/hub/hooks/useQueryMultilingualHubs';
import { getQueryStringParams, setHtmlTextDirection } from '../../../utils/utils';
import { LanguageContext } from '../../localization/LanguageContext';
import { DayPicker, TimePicker } from '../dateTimePicker/DateTimePicker';
import { Search } from '../search/Search';

const PLACE_TYPES = [
  'establishment',
  'intersection',
  'route',
  'street_address',
  'street_number',
  'premise',
  'point_of_interest',
  'landmark',
];

export const Widget: React.FC<any> = () => {
  useMemo(() => import('./styles'), []);
  const { setLocale } = useContext(LanguageContext);
  const { translate: t, locale } = useLocalize();
  const [storedValue, setSessionValue] = useSessionStorage('widgetData');
  const [, setInitialUrl] = useSessionStorage('initialUrl');
  const { refetch: getMultilingualHubs } = useQueryMultilingualHubs({
    variables: {
      params: {
        page: 0,
        size: 10,
        preferredLanguage: locale,
        query: '',
      },
    },
    skip: true,
  });
  const date = new Date();
  const plusOneDay = date.setDate(date.getDate() + 1);
  const plusOneHour = date.setTime(date.getTime() + (60 * 60 * 1000));
  const plusTwoDays = date.setDate(date.getDate() + 1);
  const plusTwoHours = date.setTime(date.getTime() + (60 * 60 * 1000));

  const [roundTrip, setRoundTrip] = useState(false);
  const [reversed, setReversed] = useState(true);
  const [passengerSelectOpen, setPassengerSelectOpen] = useState(false);
  const [passengersArray, setPassengersArray] = useState([]);
  const [suitcaseSelectOpen, setSuitcaseSelectOpen] = useState(false);
  const [suitcasesArray, setSuitcasesArray] = useState([]);
  const [passenger, setPassenger] = useState(2);
  const [suitcase, setSuitcase] = useState(0);
  const [day, setDay] = useState(plusOneDay) as any;
  const [time, setTime] = useState(plusOneHour) as any;
  const [dayRound, setDayRound] = useState(plusTwoDays) as any;
  const [timeRound, setTimeRound] = useState(plusTwoHours) as any;
  const [errors, setErrors] = useState([]) as any;
  const [defaultValues, setDefaultValues] = useState({}) as any;
  const [placeId, setPlaceId] = useState('');
  const [openResultsInsideIframe, setOpenResultsInsideIframe] = useState(false);
  const [initialBounds, setInitialBounds] = useState({});
  const [, setValue] = useLocalStorage('preferredLanguage');

  const addressRef = useRef(null) as any;
  const hubRef = useRef(null) as any;
  const passengerRef = useRef(null) as any;
  const suitcaseRef = useRef(null) as any;
  const buttonRef = useRef(null) as any;

  const service = new google.maps.places.AutocompleteService();

  const handleClickOutside = (event: any) => {
    if (!passengerRef?.current.contains(event.target)) {
      setPassengerSelectOpen(false);
    }
    if (!suitcaseRef?.current.contains(event.target)) {
      setSuitcaseSelectOpen(false);
    }
  };

  const setAddresses = (hubAddress: string, googleAddress: string) => {
    if (googleAddress) {
      service.getPlacePredictions({ input: googleAddress }, (address: any) => {
        if (address) {
          address.forEach((_address: any) => {
            if (_address?.types?.some((type: string) => PLACE_TYPES.includes(type))) {
              addressRef.current = {
                address: _address.description,
              };
              setPlaceId(_address.place_id);
              setDefaultValues({
                address: googleAddress,
              });
            }
          });
        }
      });
    }

    if (hubAddress) {
      (async () => {
        try {
          const result = await getMultilingualHubs({
            params: {
              page: 0,
              size: 10,
              preferredLanguage: locale,
              query: hubAddress,
            },
          });

          if (result?.data?.multilingualHubs) {
            const hubAddress = result?.data?.multilingualHubs?.results[0];
            if (hubAddress) {
              hubRef.current = {
                id: hubAddress.id,
                autoCompleteName: hubAddress.autoCompleteName,
                autoCompleteLocation: hubAddress.autoCompleteLocation,
                hubType: hubAddress.hubType,
                position: hubAddress.position,
                countryCode: hubAddress.countryCode,
              };
              setDefaultValues({
                hub: {
                  value: hubAddress.id,
                  label: hubAddress.autoCompleteName,
                  labelSecondary: hubAddress.autoCompleteLocation,
                  hubType: hubAddress.hubType,
                },
              });
            }
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  };

  useEffect(() => {
    let params = {} as any;
    let initialUrl;

    if (storedValue) {
      if (storedValue.destination) {
        storedValue.destination = { address: decodeURIComponent(JSON.parse(storedValue.destination).address) };
      }
      params = storedValue;
    }
    if (window.location.search) {
      const urlParams = getQueryStringParams(window.location.search);
      initialUrl = urlParams.initialUrl;
      if (urlParams.destination) {
        urlParams.destination = JSON.parse(params.destination);
      }
      params = {
        ...params,
        ...urlParams,
      };
    }
    setWidgetData(params);

    /**
     * we need to save the url from where the user came to be able to redirect back to the first page
     * when the user clicks 'edit' from the address details section.
     * in case when an application opens in an iframe the parent url should be saved.
     * iframe should get initialUrl from parent application.
     */
    if (!initialUrl) {
      if (window.location.href.indexOf('/start') > -1) {
        initialUrl = document.referrer || window.location.href.replace('/start', '');
      } else {
        initialUrl = window.location.href;
      }
    }
    setInitialUrl(initialUrl);
    // eslint-disable-next-line
  }, []);

  const params = getQueryStringParams(window.location.search);
  useEffect(() => {
    setTimeout(() => {
      if (params?.autoSubmit === 'true') {
        buttonRef.current.click();
      }
    }, 2000);
  }, []);

  useEffect(() => {
    setPassengersArray(Array.from(Array(100).keys()) as any);
    setSuitcasesArray(Array.from(Array(16).keys()) as any);
    document.addEventListener('click', handleClickOutside);
    document.body.style.backgroundColor = window.tz_globalConfigs.partnerStylingOverrides?.['whitelabel.page.backgroundColor'] || window.tz_globalConfigs.styling.backgroundColor || 'white';
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const setWidgetData = (params: any) => {
    if (params?.passengers) {
      setPassenger(params.passengers);
    }
    if (params?.luggage) {
      setSuitcase(params.luggage);
    }
    if (params?.directionality?.includes('ROUNDTRIP')) {
      setRoundTrip(true);
    } else {
      setRoundTrip(false);
    }
    if (params?.directionality?.includes('INBOUND')) {
      setReversed(false);
    }
    if (params?.directionality?.includes('INBOUND')) {
      if (params?.inboundPickup && params.inboundPickup !== 'null') {
        setDay(new Date(params.inboundPickup));
        setTime(new Date(params.inboundPickup));
      }
      if (params?.outboundPickup && params.outboundPickup !== 'null') {
        setDayRound(new Date(params.outboundPickup));
        setTimeRound(new Date(params.outboundPickup));
      }
    } else {
      if (params?.inboundPickup && params.inboundPickup !== 'null') {
        setDayRound(new Date(params.inboundPickup));
        setTimeRound(new Date(params.inboundPickup));
      }
      if (params?.outboundPickup && params.outboundPickup !== 'null') {
        setDay(new Date(params.outboundPickup));
        setTime(new Date(params.outboundPickup));
      }
    }
    if (params?.pickupTime && params.pickupTime !== 'null') {
      setDay(new Date(params.pickupTime));
      setTime(new Date(params.pickupTime));
    }
    if (params?.destination?.address && params?.hubName && params?.hubId) {
      addressRef.current = params.destination;
      hubRef.current = {
        id: params?.hubId,
        autoCompleteName: params?.hubName,
        autoCompleteLocation: params?.hubNameSecondary,
        hubType: params?.hubType,
        countryCode: params?.countryCode,
        position: {
          lat: +params?.hubLat,
          lng: +params?.hubLng,
        },
      };
      setDefaultValues({
        address: params.destination.address,
        hub: {
          value: params.hubId,
          label: params.hubName,
          labelSecondary: params.hubNameSecondary,
          hubType: params.hubType,
        },
      });
      setInitialBounds(hubRef.current.position);
    }
    if (params?.pickupAddress || params?.dropoffAddress) {
      if (reversed) {
        setAddresses(params.pickupAddress, params.dropoffAddress);
      } else {
        setAddresses(params.dropoffAddress, params.pickupAddress);
      }
    }
    if (params?.placeId) {
      setPlaceId(params?.placeId);
    }
    if (params?.redirectWithinCurrentFrame) {
      setOpenResultsInsideIframe(true);
    }
    if (params?.language) {
      const language = params.language === 'pt-PT' ? 'pt-BR' : params.language;
      // Known issue: without setTimeout only widget will be translated.
      setTimeout(() => {
        setValue(language);
        setLocale?.(language);
        setHtmlTextDirection(language);
      });
    }
    if (params?.showHubFirst === 'true') {
      setReversed(true);
    } else if (params?.showHubFirst === 'false') {
      setReversed(false);
    } else {
      setReversed(window.tz_globalConfigs?.settings?.showHubFirst);
    }
  };

  const validateForm = () => {
    const formErrors: any = [];
    if (!addressRef.current?.address) {
      formErrors.push({
        name: 'address',
        message: 'Address is required',
      });
    }
    if (!hubRef.current) {
      formErrors.push({
        name: 'hub',
        message: 'Hub is required',
      });
    }
    if (!addressRef.current?.address || !hubRef.current) {
      setErrors(formErrors);
      return false;
    }
    return true;
  };

  const getDirectionality = () => {
    let directionality = '';
    if (roundTrip) {
      directionality = reversed ? 'ROUNDTRIP_OUTBOUND' : 'ROUNDTRIP_INBOUND';
    } else {
      directionality = reversed ? 'OUTBOUND' : 'INBOUND';
    }
    return directionality;
  };

  const getISOFormattedDateWithTimeZone = (date: string) => {
    const newDate = new Date(date);
    return new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      const inboundPickupDay = getISOFormattedDateWithTimeZone(day).split('T')[0];
      const inboundPickupTime = new Date(time).toTimeString()
        .split(' ')[0].split(':').slice(0, 2)
        .join(':');
      const outboundPickupDay = getISOFormattedDateWithTimeZone(dayRound).split('T')[0];
      const outboundPickupTime = new Date(timeRound).toTimeString()
        .split(' ')[0].split(':').slice(0, 2)
        .join(':');
      const queryParams: any = {
        partnerId: window.tz_globalConfigs?.partnerId,
        hubId: hubRef.current?.id,
        hubName: hubRef.current?.autoCompleteName,
        hubNameSecondary: hubRef.current?.autoCompleteLocation,
        hubLat: hubRef.current?.position?.lat,
        hubLng: hubRef.current?.position?.lng,
        hubType: hubRef.current?.hubType,
        directionality: getDirectionality(),
        destination: JSON.stringify(addressRef.current),
        passengers: passenger,
        luggage: suitcase,
        inboundPickup: reversed ? `${outboundPickupDay}T${outboundPickupTime}:00` : `${inboundPickupDay}T${inboundPickupTime}:00`,
        outboundPickup: reversed ? `${inboundPickupDay}T${inboundPickupTime}:00` : `${outboundPickupDay}T${outboundPickupTime}:00`,
        isRoundTrip: roundTrip,
        placeId: placeId,
        countryCode: hubRef.current?.countryCode,
      };
      let url = `${window.tz_globalConfigs.primaryUrl}/booking/vehicle-selection`;
      const params: any = getQueryStringParams(window.location.search);

      Object.keys(params).forEach((key: any) => {
        url += `${url.includes('?') ? '&' : '?'}${key}=${encodeURIComponent(params[key])}`;
      });
      Object.keys(queryParams).forEach((key: any) => {
        url += `${url.includes('?') ? '&' : '?'}${key}=${encodeURIComponent(queryParams[key])}`;
      });
      const trackData = getTrackEventData(inboundPickupDay, outboundPickupDay, inboundPickupTime, outboundPickupTime);
      url += `&widgetTrackData=${encodeURIComponent(JSON.stringify(trackData))}`;

      if (openResultsInsideIframe) {
        window.open(url, '_self');
      } else {
        window.open(url, params?.newWindow === 'true' ? '_blank' : '_parent');
      }

      setWidgetDataInSessionStorage(queryParams);
    } else {
      console.log('Form validation fail');
    }
  };

  const setWidgetDataInSessionStorage = (queryParams: any) => {
    setSessionValue(queryParams);
  };

  const getTrackEventData = (inbound: string, outbound: string, inboundTime: string, outboundTime: string) => {
    return {
      event: 'show_possibilities',
      app_platform: 'web',
      page_layout: window.matchMedia('(min-width: 1241px)').matches ? 'desktop' : 'mobile',
      environment: window.tz_globalConfigs?.environmentConfigs.environment,
      language: locale || 'nl-NL',
      website_id: window.tz_globalConfigs?.name,
      widget_to: reversed
        ? addressRef.current?.address
        : `${hubRef.current?.autoCompleteName} ${hubRef.current?.countryCode}`,
      widget_from: reversed
        ? `${hubRef.current?.autoCompleteName} ${hubRef.current?.countryCode}`
        : addressRef.current?.address,
      widget_travel_date: inbound,
      widget_travel_date_return: roundTrip ? outbound : '',
      widget_days_from_now: getDateDiff(reversed ? outbound : inbound),
      widget_amount_travellers: passenger,
      widget_amount_suitcases: suitcase,
      widget_roundtrip: roundTrip ? 'yes' : 'no',
      widget_hub_id: hubRef.current?.id,
      widget_directionality: reversed ? 'outbound' : 'inbound',
      widget_travel_time: inboundTime,
      widget_travel_time_return: roundTrip ? outboundTime : '',
    };
  };

  const clearError = (name: string) => {
    const otherErrors = errors.filter((error: any) => error.name !== name);
    setErrors(otherErrors);
  };

  const customWidgetStyles = {
    '--widget-background-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.widget.backgroundColor'] || window.tz_globalConfigs?.styling?.primaryColor || '#fc3',
    '--label-text-color': window.tz_globalConfigs?.styling?.primaryContrastColor || '#3c4b64',
    '--whitelabel-widget-text-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.widget.textColor'],
    '--whitelabel-forms-label-text-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.forms.labelTextColor'],
    '--form-field-text-color': window.tz_globalConfigs?.styling?.backgroundContrastColor,
    '--primary-button-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.widget.buttonColor'] || window.tz_globalConfigs?.styling?.secondaryColor || '#48a947',
    '--primary-button-text-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.widget.buttonTextColor'] || window.tz_globalConfigs?.styling?.secondaryContrastColor || '#fff',
  } as React.CSSProperties;

  const getMinutesAfterTime = (time: Date) => {
    return new Date(time).setMinutes(new Date(time).getMinutes() + 1);
  };

  const setReturnDate = (day: Date) => {
    setDayRound(dayRound < day ? day : dayRound);
  };

  const setReturnTime = (day: Date, time: Date, dayRound: Date) => {
    if (new Date(dayRound).toDateString() === new Date(day).toDateString()) {
      if (new Date(timeRound).getHours() < new Date(time).getHours()) {
        setTimeRound(getMinutesAfterTime(time));
      } else if (new Date(timeRound).getHours() === new Date(time).getHours()) {
        setTimeRound(new Date(timeRound).getMinutes() <= new Date(time).getMinutes()
          ? getMinutesAfterTime(time)
          : timeRound);
      }
    }
  };

  return (
    <div className="widget font-regular" style={customWidgetStyles}>
      <h2 className="font-bold widget-title">{t('transferWorldwide')}</h2>
      <form noValidate>
        <Search
          onAddressChange={(address: string, placeId: string) => {
            if (addressRef.current) {
              addressRef.current.address = address;
            } else {
              addressRef.current = {
                address,
              };
            }
            setPlaceId(placeId);
          }}
          onHubChange={(hub: any) => hubRef.current = hub}
          handleReverse={(reverse: boolean) => setReversed(reverse)}
          reversed={reversed}
          clearError={(name: string) => clearError(name)}
          errors={errors}
          defaultValues={defaultValues}
          styles={{
            formFieldTextColor: window.tz_globalConfigs?.styling?.backgroundContrastColor,
          }}
          initialBounds={initialBounds}
        />
        <div className="widget-details">
          <div className="widget-details-date">
            <div className="widget-details-date-picker">
              <DayPicker
                id="date-id"
                value={day}
                onChange={(value: any) => [setDay(value), setReturnDate(value), setReturnTime(value, time, dayRound)]}
                round={false}
                locale={locale}
              />
            </div>
            <div className="widget-details-time-picker">
              <TimePicker
                id="time-id"
                value={time}
                onChange={(value: any) => [setTime(value), setReturnTime(day, value, dayRound)]}
                round={false}
              />
            </div>
          </div>
          <div className="widget-details-info">
            <div className="widget-details-passenger">
              <label htmlFor="passenger-id">
                <CIcon size="lg" name="cil-people" className="mfe-2" />
              </label>
              <input
                id="passenger-id"
                ref={passengerRef}
                type="text"
                readOnly
                value={passenger}
                onClick={() => setPassengerSelectOpen(!passengerSelectOpen)}
                onChange={() => { }}
              />
              {(passengerSelectOpen && passengersArray.length) && (
                <div className="widget-details-passenger-dropdown">
                  {passengersArray.map((i: number) => <span className={`${passenger === i + 1 ? 'passenger-active' : ''}`} key={i} onClick={() => setPassenger(i + 1)}>{i + 1}</span>)}
                </div>
              )}
            </div>
            <div className="widget-details-suitcase">
              <label htmlFor="suitcase-id">
                <CIcon size="lg" name="cil-briefcase" className="mfe-2" />
              </label>
              <input
                id="suitcase-id"
                ref={suitcaseRef}
                type="text"
                readOnly
                value={suitcase}
                onClick={() => setSuitcaseSelectOpen(!suitcaseSelectOpen)}
                onChange={() => { }}
              />
              {(suitcaseSelectOpen && suitcasesArray.length) && (
                <div className="widget-details-passenger-dropdown">
                  {suitcasesArray.map((i: number) => <span className={`${suitcase === i ? 'luggage-active' : ''}`} key={i} onClick={() => setSuitcase(i)}>{i}</span>)}
                </div>
              )}
            </div>
          </div>
          <div className="widget-details-action">
            <div className="widget-details-action-checkbox checkbox-primary">
              <input type="checkbox" checked={roundTrip} id="round" onChange={() => setRoundTrip(!roundTrip)} />
              <label htmlFor="round" className="font-bold">
                <span>{t('roundTrip')}</span>
              </label>
            </div>
          </div>
        </div>
        <div className={`widget-details ${roundTrip ? 'widget-details-round' : ''}`}>
          {roundTrip && (
            <div className="widget-details-date">
              <div className="widget-details-date-picker">
                <span>{t('pickupDate')}</span>
                <DayPicker
                  id="round-trip-date-id"
                  value={dayRound}
                  onChange={(value: any) => [setDayRound(value), setReturnTime(day, time, value)]}
                  round={true}
                  locale={locale}
                  minDate={day}
                />
              </div>
              <div className="widget-details-time-picker">
                <span>{t('pickupTime')}</span>
                <TimePicker
                  id="round-trip-time-id"
                  value={timeRound}
                  onChange={(value: any) => setTimeRound(value)}
                  round={true}
                  minTime={new Date(dayRound).toDateString() === new Date(day).toDateString() ? getMinutesAfterTime(time) : null}
                />
              </div>
            </div>
          )}
          <div className="widget-details-button">
            <button ref={buttonRef} onClick={handleSubmit}>{t('showPossibilities')}</button>
          </div>
        </div>
      </form>
    </div>
  );
};
