// import { Loading } from '@transferz/fe-components';
import { LocalizationProvider } from 'localize-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useQueryTranslations } from '../../queries/translations/hooks/useQueryTranslations';
import { getDefaultLanguage } from '../../utils/utils';
import { ILanguageOption, LanguageContext } from './LanguageContext';
import { fetchLanguageOptions } from './storyblokClient';

const useLanguageOptions = () => {
  const [languageOptions, setLanguageOptions] = useState<ILanguageOption[] | undefined>(undefined);

  useEffect(() => {
    fetchLanguageOptions().then((options) => {
      setLanguageOptions(options);
    })
      .catch((e) => {
        console.error('Error in fetching language options - ', e);
      });
  }, []);

  return languageOptions;
};

export const LanguageProvider: React.FC = ({ children }) => {
  const languageOptions = useLanguageOptions();

  if (!languageOptions) {
    return <span>Loading...</span>;
  }

  return (
    <LanguageProviderInner languageOptions={languageOptions}>
      {children}
    </LanguageProviderInner>
  );
};

const localeMapping = {
  zh: 'zh-cn',
} as Record<string, string>;

const LanguageProviderInner: React.FC<{languageOptions: ILanguageOption[]}> = ({ children, languageOptions }) => {
  const [lastTranslationData, setLastTranslationData] = useState<any>({});
  const [currentLocale, setLocale] = useState(getDefaultLanguage(languageOptions));
  const currentLanguageCode = currentLocale.split('-')[0];
  const { data: translationsData } = useQueryTranslations({
    variables: {
      app: 'booking-tool',
      lng: currentLanguageCode,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (translationsData) {
      setLastTranslationData(translationsData);
    }
  }, [translationsData]);

  useEffect(() => {
    const momentLocale = localeMapping[currentLanguageCode] || currentLanguageCode;
    import(`moment/locale/${momentLocale}`)
      .catch(() => {})
      .finally(() => moment.locale(momentLocale));
  }, [currentLanguageCode]);

  return (
    <LanguageContext.Provider
      value={{
        languages: languageOptions,
        currentLocale,
        setLocale,
      }}
    >
      <LocalizationProvider
        locale={currentLocale}
        translations={{
          [currentLocale]: lastTranslationData?.translations?.content,
        }}
      >
        {children}
      </LocalizationProvider>
    </LanguageContext.Provider>
  );
};
