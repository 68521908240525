import CIcon from '@coreui/icons-react';
import { CInput, CLabel } from '@coreui/react';
import { HubType, HubTypesDisplayName } from '@transferz/models';
import debounce from 'lodash/debounce';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { useLocalize } from '../../../hooks/useLocalize';
import { MultilingualHub, useQueryMultilingualHubs } from '../../../queries/hub/hooks/useQueryMultilingualHubs';

const PLACE_TYPES = [
  'establishment',
  'intersection',
  'route',
  'street_address',
  'street_number',
  'premise',
  'point_of_interest',
  'landmark',
];

export const Search: React.FC<any> = ({
  onAddressChange,
  onHubChange,
  handleReverse,
  reversed,
  clearError,
  errors,
  defaultValues,
  styles = {},
  initialBounds = {},
}: any) => {
  useMemo(() => import('./styles'), []);
  const { translate: t, locale } = useLocalize();
  const { refetch: getMultilingualHubs } = useQueryMultilingualHubs({
    variables: {
      params: {
        page: 0,
        size: 10,
        preferredLanguage: locale,
        query: '',
      },
    },
    skip: true,
  });

  const customStyles = {
    menu: (base: any) => ({
      ...base,
      margin: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      borderRadius: '4px',
      maxHeight: '242px',
      boxShadow: '-10px 10px 10px rgba(0, 0, 0, 0.18)',
    }),
    groupHeading: (base: any) => ({
      ...base,
      color: '#2f3030',
      height: '40px',
      lineHeight: '40px',
      fontWeight: 700,
      backgroundColor: 'rgba(0,0,0,.15)',
      fontSize: 12,
      textAlign: 'center',
      margin: 0,
      padding: 0,
      textTransform: 'capitalize',
    }),
    group: (base: any) => ({
      ...base,
      padding: 0,
    }),
    option: (base: any, props: any) => ({
      ...base,
      backgroundColor: props.isFocused ? 'var(--secondary) !important' : 'inherit',
    }),
    input: (base: any) => ({
      ...base,
      color: styles.formFieldTextColor || '#5c6873',
    }),
  };

  const service = new google.maps.places.AutocompleteService();

  const [hubs, setHubs] = useState<MultilingualHub[]>([]);
  const [deg, setDeg] = useState(0);
  const [placeSuggestion, setPlaceSuggestion] = useState([]) as any;
  const [showSuggestionContainer, setShowSuggestionContainer] = useState(null) as any;
  const [hubValue, setHubValue] = useState(null) as any;
  const [addressValue, setAddressValue] = useState('') as any;
  const [bounds, setBounds] = useState(initialBounds) as any;
  const [selectOptions, setSelectOptions] = useState([]) as any;

  const hubRef = useRef(null) as any;
  const addressRef = useRef(null) as any;
  const originRef = useRef(null) as any;
  const iconRef = useRef(null) as any;

  const handleKeyDown = (event: any) => {
    if (showSuggestionContainer) {
      const arrayFromItems = Array.from(document.querySelectorAll('.suggestion-item'));
      const active = arrayFromItems.find((item: any) => item.classList.value.includes('hovered-item'));
      if (event.which === 40) {
        event.preventDefault();
        if (active) {
          const index = arrayFromItems.indexOf(active);
          if (arrayFromItems[index + 1]) {
            active.classList.remove('hovered-item');
            arrayFromItems[index + 1].classList.add('hovered-item');
          }
        } else {
          arrayFromItems[0].classList.add('hovered-item');
        }
      }
      if (event.which === 38) {
        event.preventDefault();
        if (active) {
          const index = arrayFromItems.indexOf(active);
          if (arrayFromItems[index - 1]) {
            active.classList.remove('hovered-item');
            arrayFromItems[index - 1].classList.add('hovered-item');
          }
        }
      }
      if (event.which === 13) {
        event.preventDefault();
        if (active) {
          const index = arrayFromItems.indexOf(active);
          handleClick(placeSuggestion[index]);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    if (initialBounds.lat) {
      setBounds(initialBounds);
    }
    // eslint-disable-next-line
  }, [initialBounds.lat]);

  useEffect(() => {
    if (defaultValues.address) {
      setAddressValue(defaultValues.address);
    }

    if (defaultValues.hub) {
      setHubValue(defaultValues.hub);
      setSelectOptions([{
        label: HubTypesDisplayName[defaultValues.hub.type as HubType],
        options: [
          {
            value: defaultValues.hub.value,
            label: defaultValues.hub.label,
            labelSecondary: defaultValues.hub.labelSecondary,
            hubType: defaultValues.hub.hubType,
          },
        ],
      }]);
    }
    // eslint-disable-next-line
  }, [defaultValues]);

  const getValue = (obj: any) => ({
    value: obj.id,
    label: obj.highlightedResult.autoCompleteName.value,
    labelSecondary: obj.highlightedResult.autoCompleteLocation.value,
    hubType: obj.hubType,
  });

  const fetchHubs = async (query: string, callback: any) => {
    try {
      const result = await getMultilingualHubs({
        params: {
          page: 0,
          size: 10,
          preferredLanguage: locale,
          query,
        },
      });
      if (result?.data?.multilingualHubs) {
        const hubsList = result?.data?.multilingualHubs?.results;
        const airports: any = [];
        const stations: any = [];
        const seaPorts: any = [];
        const pointOfInterests: any = [];
        hubsList?.forEach((hub: any) => {
          if (hub.hubType === HubType.Airport) {
            airports.push(hub);
          } else if (hub.hubType === HubType.TrainStation) {
            stations.push(hub);
          } else if (hub.hubType === HubType.Seaport) {
            seaPorts.push(hub);
          } else if (hub.hubType === HubType.PointOfInterest) {
            pointOfInterests.push(hub);
          }
        });

        const options: any = [
          {
            label: HubTypesDisplayName[HubType.Airport],
            options: airports.map((airport: any) => getValue(airport)),
          },
          {
            label: HubTypesDisplayName[HubType.TrainStation],
            options: stations.map((station: any) => getValue(station)),
          },
          {
            label: HubTypesDisplayName[HubType.Seaport],
            options: seaPorts.map((seaPort: any) => getValue(seaPort)),
          },
          {
            label: HubTypesDisplayName[HubType.PointOfInterest],
            options: pointOfInterests.map((pointOfInterest: any) => getValue(pointOfInterest)),
          },
        ];

        setSelectOptions(options);
        setHubs(hubsList);
        callback(options);
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const debouncedLoadOptions = debounce(fetchHubs, 300);

  const loadOptions = (query: string = '', callback: any): any => {
    if (query) {
      debouncedLoadOptions(query, callback);
    } else {
      return [];
    }
  };

  const handleClick = (place: any) => {
    setAddressValue(place.description);
    setShowSuggestionContainer(false);
    onAddressChange(place.description, place.place_id);
    if (locale === 'ar-SA') {
      addressRef.current.style.direction = 'ltr';
    }
  };

  const showPlaceSuggestions = () => {
    const matchString = (place: any) => {
      const startTag = '<b className="m-1">';
      const endTag = '</b>';
      const startTagPosition = place.structured_formatting.main_text_matched_substrings?.[0]?.offset;
      const endTagPosition = startTagPosition + place.structured_formatting.main_text_matched_substrings?.[0]?.length;
      const mainText = place.structured_formatting.main_text;

      return { __html: [mainText.slice(0, startTagPosition), startTag, mainText.slice(startTagPosition, endTagPosition), endTag, mainText.slice(endTagPosition)].join('') };
    };

    return placeSuggestion?.map((place: any) => {
      return (
        <div
          className="suggestion-item"
          key={place.place_id}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => handleClick(place)}
          data-testid="suggestion-item"
        >
          <span dangerouslySetInnerHTML={matchString(place)}></span>
          <br />
          <span className="secondary-text">{place.structured_formatting.secondary_text}</span>
        </div>
      );
    });
  };

  const clearAddressField = () => {
    setAddressValue('');
    if (locale === 'ar-SA') {
      addressRef.current.style.direction = 'rtl';
    }
    onAddressChange(null);
    setPlaceSuggestion([]);
  };

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (!input) {
      clearAddressField();
      return;
    }
    setAddressValue(input);
    const error = errors?.find((error: any) => error.name === 'address');
    if (error) {
      clearError('address');
      originRef.current.classList.remove('error-address-border');
    }
    if (input) {
      const defaultBounds = {
        north: bounds.lat + 0.6,
        south: bounds.lat - 0.6,
        east: bounds.lng + 0.6,
        west: bounds.lng - 0.6,
      };
      service.getPlacePredictions({ input, bounds: defaultBounds }, (address: any) => {
        if (address) {
          let suggestions: string[] = [];
          address.forEach((_address: any) => {
            if (_address?.types?.some((type: string) => PLACE_TYPES.includes(type))) {
              if (suggestions.length < 4) {
                suggestions.push(_address);
              }
            }
          });
          setPlaceSuggestion(suggestions);
          setShowSuggestionContainer(true);
        }
      });
    } else {
      setPlaceSuggestion([]);
      setShowSuggestionContainer(false);
    }
  };

  const renderError = (name: string) => {
    const error = errors?.find((error: any) => error.name === name);
    if (error) {
      if (error.name === 'hub') {
        hubRef.current.classList.add('error-hub-border');
      } else {
        originRef.current.classList.add('error-address-border');
      }
      return (
        <span className="error-address">
          <CIcon size="sm" name="cil-warning" />
          <div className="tooltip">
            {
              error.name === 'hub'
                ? <span className="tooltiptext">Whoops, Looks like you forgot to input an <strong>airport</strong></span>
                : <span className="tooltiptext">Whoops, Looks like you forgot your <strong>address</strong> there</span>
            }
          </div>
        </span>
      );
    }
    return null;
  };

  const customSearchStyles = {
    '--form-field-placeholder-text-color': styles.formFieldPlaceholderTextColor || '#51536d',
    '--form-field-text-color': styles.formFieldTextColor || '#5c6873',
  } as React.CSSProperties;

  const renderHubInput = () => {
    return (
      <div className="search-destination">
        <CLabel>
          <div className="destination" ref={hubRef}>
            {(hubValue?.hubType === HubType.Airport || !hubValue?.hubType) && (
              <span className="address-icon">
                <CIcon size="lg" name="cil-airplane-mode" className="mfe-2" />
                <span className="font-bold">{reversed ? t('fromAirport') : t('toAirport')}</span>
              </span>
            )}
            {hubValue?.hubType === HubType.TrainStation && (
              <span className="address-icon">
                <CIcon size="lg" name="cil-locomotive" className="mfe-2" />
                <span className="font-bold">{reversed ? t('fromTrainStation') : t('toTrainStation')}</span>
              </span>
            )}
            {hubValue?.hubType === HubType.Seaport && (
              <span className="address-icon">
                <CIcon size="lg" name="cil-boat-alt" className="mfe-2" />
                <span className="font-bold">{reversed ? t('fromSeaport') : t('toSeaport')}</span>
              </span>
            )}
            {hubValue?.hubType === HubType.PointOfInterest && (
              <span className="address-icon">
                <CIcon size="lg" name="cil-building" className="mfe-2" />
                <span className="font-bold">{reversed ? t('fromPointOfInterest') : t('toPointOfInterest')}</span>
              </span>
            )}
            <AsyncSelect
              className="destination-select"
              components={{
                GroupHeading: (props: any) => (
                  <components.GroupHeading {...props} />
                ),
                Option: (props: any) => (
                  <components.Option {...props} className="suggestion-item">
                    <div dangerouslySetInnerHTML={{ __html: props.data.label }}></div>
                    <div dangerouslySetInnerHTML={{ __html: props.data.labelSecondary }} className="secondary-text"></div>
                  </components.Option>
                ),
              }}
              styles={customStyles}
              key="destination"
              loadOptions={loadOptions}
              placeholder=""
              noOptionsMessage={() => t('typeToSelect')}
              defaultOptions={selectOptions}
              value={hubValue}
              onInputChange={() => {
                const error = errors?.find((error: any) => error.name === 'hub');
                if (error) {
                  clearError('hub');
                  hubRef.current.classList.remove('error-hub-border');
                }
              }}
              onChange={(hub: any) => {
                const selectedHub: any = hubs.find((_hub: any) => _hub.id === hub.value);
                hub.label = hub.label.replace(/(<\/em>|<em>)/g, '');
                hub.labelSecondary = hub.labelSecondary.replace(/(<\/em>|<em>)/g, '');
                setHubValue(hub);
                if (selectedHub) {
                  setBounds(selectedHub.position);
                  onHubChange(selectedHub);
                }
              }}
            />
            {hubValue && (
              <span className="clear-icon" onClick={() => {
                setHubValue(null);
                onHubChange(null);
              }}>
                <CIcon size="sm" name="cil-x-circle" />
              </span>
            )}
            {renderError('hub')}
          </div>
        </CLabel>
      </div>
    );
  };

  const renderAddressInput = () => {
    return (
      <div className="search-origin">
        <CLabel>
          <div className="origin" ref={originRef}>
            <span className="address-icon">
              <CIcon size="lg" name="cil-location-pin" className="mfe-2" />
              <span className="font-bold">{reversed ? t('to') : t('from')}</span>
            </span>
            <div className="suggestion" onBlur={() => setShowSuggestionContainer(false)}>
              <CInput
                innerRef={addressRef}
                className="origin-select"
                name="originLocation.address.formattedAddress"
                type="text"
                autoComplete="off"
                onChange={handleAddressChange}
                placeholder={t('addressStation')}
                value={addressValue}
              />
              {showSuggestionContainer && (
                <div className="suggestion-container">
                  {showPlaceSuggestions()}
                </div>
              )}
              {renderError('address')}
            </div>
            {addressValue && (
              <span className="clear-icon" onClick={clearAddressField}>
                <CIcon size="sm" name="cil-x-circle" />
              </span>
            )}
          </div>
        </CLabel>
      </div>
    );
  };

  return (
    <div className={`search ${reversed && 'search-reversed'}`} style={customSearchStyles}>
      {reversed ? renderHubInput() : renderAddressInput()}
      <div
        className="switcher"
        ref={iconRef}
        onClick={() => {
          handleReverse(!reversed);
          setDeg(deg + 180);
          iconRef.current.style.transform = `rotate(${deg + 180}deg)`;
        }}
      >
        <CIcon size="sm" name="cil-transfer" />
      </div>
      {reversed ? renderAddressInput() : renderHubInput()}
    </div>
  );
};
