import React, { Suspense, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { DocumentTitle } from '../components/documentTitle/DocumentTitle';
import { ErrorBoundary } from '../components/errorBoundary/ErrorBoundary';
import { useSessionStorage } from '../hooks/sessionStorage/useSessionStorage';
import { useLocalPreferredCurrencyCode } from '../hooks/useLocalPreferredCurrencyCode';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useQueryIPAddressInfo } from '../queries/ip/hooks/useQueryIPAddressInfo';
import { useQueryPartnerConfigs } from '../queries/partnerConfigs/hooks/useQueryPartnerConfigs';
import { AppRoutes } from './AppRoutes';

export const App: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(true);
  const { data: IPAddressInfo } = useQueryIPAddressInfo();
  const IPAddressInfoCurrencyCode = IPAddressInfo?.IPAddressInfo?.currencyCode;
  const [preferredLanguage] = useLocalStorage('preferredLanguage');
  const [, setPreferredCurrency] = useLocalPreferredCurrencyCode();
  const [, setFormDataInSession] = useSessionStorage('formData');
  const params = new URLSearchParams(window.location.search);
  const { data: configData, error: configError, loading: configLoading } = useQueryPartnerConfigs({
    variables: {
      domain: params.get('partner') || process.env.REACT_APP_PARTNER_DOMAIN || window.location.hostname,
    },
  });

  useEffect(() => {
    IPAddressInfoCurrencyCode && setPreferredCurrency(IPAddressInfoCurrencyCode);
  }, [IPAddressInfo]);

  const setQueryDataIntoSessionStorage = () => {
    const formData = {
      other: (params.get('travellerFirstName') !== params.get('bookerFirstName') || params.get('travellerLastName') !== params.get('bookerLastName')),
      bookerDetails: {
        email: params.get('bookerEmail'),
        firstName: params.get('bookerFirstName'),
        lastName: params.get('bookerLastName'),
        phone: params.get('phone'),
      },
      travellerDetails: {
        firstName: params.get('travellerFirstName'),
        lastName: params.get('travellerLastName'),
        email: params.get('travellerEmail'),
      },
      number: params.get('phone'),
      outboundFlightNumber: params.get('flightNrOut'),
      inboundFlightNumber: params.get('flightNumberIn'),
      terms: false,
      country: {
        value: params.get('countryCode'),
      },
      code: {
        label: params.get('phoneCountry'),
        value: params.get('phoneCountry'),
      },
    };
    setFormDataInSession(formData);
  };

  const setupGTM = () => {
    window.tz_globalConfigs.tagManagers.forEach(tagManagerConfig => {
      TagManager.initialize({
        gtmId: tagManagerConfig,
        dataLayer: {
          event: 'page_load',
          app_platform: 'web',
          website_id: window.tz_globalConfigs.name,
          page_layout: window.matchMedia('(min-width: 1241px)').matches ? 'desktop' : 'mobile',
          environment: window.tz_globalConfigs.environmentConfigs.environment,
          page_type: 'purchase',
          customer: {
            store_language: preferredLanguage || 'en-US',
          },
        },
      });
    });
  };

  const loadGoogleMapsApiScript = async (key: string) => {
    return new Promise((resolve: Function) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places,drawing`;
      script.async = true;

      document.head.appendChild(script);

      script.onload = () => {
        resolve();
      };
    });
  };

  useEffect(() => {
    const onlineBookingToolEnabled = configData?.partnerConfigs?.onlineBookingToolEnabled;
    if (!configLoading && window.location.pathname.indexOf('/help') === -1 && !onlineBookingToolEnabled) {
      window.location.href = `${configData?.partnerConfigs?.customPrefixUrl || ''}/help`;
    }
    const customPrefixUrl = configData?.partnerConfigs?.customPrefixUrl;
    if (customPrefixUrl && window.location.pathname.indexOf(customPrefixUrl) === -1) {
      const newUrl = new URL(window.location.href);
      newUrl.pathname = `${customPrefixUrl}${newUrl.pathname}`;
      window.location.href = newUrl.toString();
    }

    if (configData?.partnerConfigs) {
      (async () => {
        const partnerStylingOverrides: { [key: string]: string } = {};
        configData.partnerConfigs?.stylingOverrides?.forEach((stylingOverride: any) => {
          partnerStylingOverrides[stylingOverride.code] = stylingOverride.value;
        });
        window.tz_globalConfigs = { ...configData.partnerConfigs, partnerStylingOverrides };
        if (process.env.REACT_APP_PRIMARY_URL_OVERRIDE) {
          window.tz_globalConfigs.primaryUrl = process.env.REACT_APP_PRIMARY_URL_OVERRIDE;
        }
        if (window.location.pathname.indexOf('/booking') === -1) {
          setQueryDataIntoSessionStorage();
        }

        if (preferredLanguage === 'ar-SA') {
          document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
          document.body.classList.add('rtl');
        }
        loadGoogleMapsApiScript(window.tz_globalConfigs.environmentConfigs.googleMapsApiKey)
          .then(() => {
            !window.location.host.includes('local') && setupGTM();
          })
          .then(() => {
            setLoading(false);
          });
      })();
    }
    // eslint-disable-next-line
  }, [configData]);

  if (configError) {
    return <div>An error occured while fetching environment configs.</div>;
  }

  if (configLoading || loading) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <DocumentTitle />
        <AppRoutes/>
      </Suspense>
    </ErrorBoundary>
  );
};
