import { gql } from '@apollo/client';

export const TRACKING_DATA = gql`
  query TrackingData($bookingCode: String, $hubName: String) {
    trackingData(bookingCode: $bookingCode, hubName: $hubName) {
      event
      currency
      value
      tax
      commission
      items {
        price
        priceTax
        currency
        quantity
        commission
        inbound
        passengers
        luggage
        item_id
        item_name
        item_characteristics
      }
      transaction_id
      payment_method
      payment_status
      order_date
      hub_ids
    }
  }
`;
