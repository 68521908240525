import './search.scss';

import CIcon from '@coreui/icons-react';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { useLocalize } from '../../hooks/useLocalize';
import { MultilingualHub } from '../../queries/hub/hooks/useQueryMultilingualHubs';
import {
  LocationSearchInput,
} from './input/locationSearchInput/LocationSearchInput';
import { LocationSearchInputDesktop } from './input/locationSearchInputDesktop/LocationSearchInputDesktop';
import {
  addressSpecFromAutoCompleteAddressItem,
  autoCompleteAddressItemFromAddressSpec,
  autoCompleteAddressItemFromMultilingualHub,
  isAddress,
  isHub, multiLingualHubFromAutoCompleteAddressItem, SearchLocation,
} from './input/utils';
import { AddressSpec } from './input/utils';

export interface SearchProps {
  hub?: MultilingualHub;
  address?: AddressSpec;
  onAddressChange: (address: AddressSpec | undefined) => void;
  onHubChange: (hub: MultilingualHub | undefined) => void;
  setIsOutbound: (reversed: boolean) => void;
  isOutbound: boolean;
  clearError: (name: string | string[]) => void;
  errors?: Array<{name: string}>;
  styles: any;
}

export const Search: React.FC<SearchProps> = ({
  hub,
  address,
  onAddressChange,
  onHubChange,
  setIsOutbound: setIsOutbound,
  isOutbound: isOutbound,
  clearError,
  errors,
  styles = {},
}) => {
  const isMobile = useMediaQuery('(max-width: 1100px)');
  const { translate: t } = useLocalize();
  const [originLocation, setOriginLocation] = useState<SearchLocation | undefined>();
  const [destinationLocation, setDestinationLocation]
    = useState<SearchLocation | undefined>();

  const [deg, setDeg] = useState(0);
  const [bounds, setBounds] = useState<google.maps.LatLngBoundsLiteral>();

  const switchIconRef = useRef(null) as any;

  const originError = errors?.find(
    (error: any) => (isOutbound && error.name === 'hub')
      || (!isOutbound && error.name === 'address'),
  );
  const destinationError = errors?.find(
    (error: any) => (!isOutbound && error.name === 'hub')
      || (isOutbound && error.name === 'address'),
  );

  useEffect(() => {
    const isOutbound = !isAddress(originLocation);
    setIsOutbound(isOutbound);
    const hubLocation = isOutbound ? originLocation : destinationLocation;
    const addressLocation = !isOutbound ? originLocation : destinationLocation;

    onHubChange(hubLocation && multiLingualHubFromAutoCompleteAddressItem(hubLocation));
    onAddressChange(addressLocation && addressSpecFromAutoCompleteAddressItem(addressLocation));
  }, [originLocation, destinationLocation]);

  useEffect(() => {
    const [hubLocation, setHubLocation] = isOutbound
      ? [originLocation, setOriginLocation]
      : [destinationLocation, setDestinationLocation];
    const [addressLocation, setAddressLocation] = !isOutbound
      ? [originLocation, setOriginLocation]
      : [destinationLocation, setDestinationLocation];

    const newHub = hub && autoCompleteAddressItemFromMultilingualHub(hub);
    !isEqual(newHub, hubLocation) && setHubLocation(newHub);

    if (address?.addressHubInformation) {
      const newAddress = autoCompleteAddressItemFromMultilingualHub(address?.addressHubInformation);
      !isEqual(newAddress, addressLocation) && setAddressLocation(newAddress);
    } else if (address?.address && address?.placeId) {
      const newLocation = autoCompleteAddressItemFromAddressSpec(address);
      !isEqual(addressLocation, newLocation) && setAddressLocation(newLocation);
    } else {
      !isEqual(addressLocation, undefined) && setAddressLocation(undefined);
    }
  }, [hub, address]);

  const customSearchStyles = {
    '--form-field-placeholder-text-color':
      styles.formFieldPlaceholderTextColor || '#51536d',
    '--form-field-text-color': styles.formFieldTextColor || '#5c6873',
  } as React.CSSProperties;

  const switchOriginAndDestination = () => {
    setOriginLocation(destinationLocation);
    setDestinationLocation(originLocation);
    clearError(['hub', 'address']);
  };

  return (
    <div
      className={`search ${isOutbound && 'search-reversed'}`}
      style={customSearchStyles}
    >
      {isMobile ? (
        <LocationSearchInput
          location={originLocation}
          modalTitle={t('search_origin_modal_title')}
          placeholder={t('search_origin_placeholder')}
          allowAddressSearch={
            !destinationLocation || isHub(destinationLocation)
          }
          setLocation={(location: SearchLocation): void => {
            setOriginLocation(location);
            // if directionality is changed, we need to clear the error of the other location
            isOutbound === !isAddress(location) ? clearError(originError?.name ?? '') : clearError(destinationError?.name ?? '');
          }}
          error={originError && t('search_error_pickup_location')}
          bounds={bounds}
          dataTestId="origin-location"
        />
      ) : (
        <LocationSearchInputDesktop
          placeholder={t('search_origin_placeholder')}
          allowAddressSearch={!destinationLocation || isHub(destinationLocation)}
          location={originLocation}
          setLocation={(location: SearchLocation | undefined): void => {
            setOriginLocation(location);
            // if directionality is changed, we need to clear the error of the other location
            isOutbound === !isAddress(location) ? clearError(originError?.name ?? '') : clearError(destinationError?.name ?? '');
          }}
          bounds={bounds}
          error={originError && t('search_error_pickup_location')}
          dataTestId="origin-location"
        />
      )}
      <div
        className="switcher"
        ref={switchIconRef}
        onClick={() => {
          switchOriginAndDestination();
          setDeg(deg + 180);
          switchIconRef.current.style.transform = `rotate(${deg + 180}deg)`;
        }}
      >
        <CIcon size="sm" name="cil-transfer" />
      </div>
      {isMobile ? (
        <LocationSearchInput
          location={destinationLocation}
          setLocation={(location: SearchLocation): void => {
            setDestinationLocation(location);

            /*
             * directionality only depends on the origin location, so we don't
             * need to clear the error of the other location
             */
            clearError(destinationError?.name ?? '');
          }}
          modalTitle={t('search_destination_modal_title')}
          placeholder={t('search_destination_placeholder')}
          allowAddressSearch={!originLocation || isHub(originLocation)}
          error={destinationError && t('search_error_dropoff_location')}
          bounds={bounds}
          dataTestId="destination-location"
        />
      ) : (
        <LocationSearchInputDesktop
          placeholder={t('search_destination_placeholder')}
          allowAddressSearch={!originLocation || isHub(originLocation)}
          location={destinationLocation}
          setLocation={(location: SearchLocation | undefined): void => {
            setDestinationLocation(location);

            /*
             * directionality only depends on the origin location, so we don't
             * need to clear the error of the other location
             */
            clearError(destinationError?.name ?? '');
          }}
          bounds={bounds}
          error={destinationError && t('search_error_dropoff_location')}
          dataTestId="destination-location"
        />
      )}
    </div>
  );
};
