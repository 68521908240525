export const languagesMap: any = {
  en: 'en-US',
  'en-AU': 'en-US',
  'en-BZ': 'en-US',
  'en-CA': 'en-US',
  'en-CB': 'en-US',
  'en-GB': 'en-US',
  'en-IE': 'en-US',
  'en-JM': 'en-US',
  'en-NZ': 'en-US',
  'en-PH': 'en-US',
  'en-TT': 'en-US',
  'en-US': 'en-US',
  'en-ZA': 'en-US',
  'en-ZW': 'en-US',
  de: 'de-DE',
  'de-AT': 'de-DE',
  'de-CH': 'de-DE',
  'de-DE': 'de-DE',
  'de-LI': 'de-DE',
  'de-LU': 'de-DE',
  es: 'es-ES',
  'es-AR': 'es-ES',
  'es-BO': 'es-ES',
  'es-CL': 'es-ES',
  'es-CO': 'es-ES',
  'es-CR': 'es-ES',
  'es-DO': 'es-ES',
  'es-EC': 'es-ES',
  'es-ES': 'es-ES',
  'es-GT': 'es-ES',
  'es-HN': 'es-ES',
  'es-MX': 'es-ES',
  'es-NI': 'es-ES',
  'es-PA': 'es-ES',
  'es-PE': 'es-ES',
  'es-PR': 'es-ES',
  'es-PY': 'es-ES',
  'es-SV': 'es-ES',
  'es-UY': 'es-ES',
  'es-VE': 'es-ES',
  fr: 'fr-FR',
  'fr-BE': 'fr-FR',
  'fr-CA': 'fr-FR',
  'fr-CH': 'fr-FR',
  'fr-FR': 'fr-FR',
  'fr-LU': 'fr-FR',
  'fr-MC': 'fr-FR',
  'it-IT': 'it-IT',
  nl: 'nl-NL',
  'nl-BE': 'nl-NL',
  'nl-NL': 'nl-NL',
  pt: 'pt-BR',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-BR',
};
