import { gql } from '@apollo/client';

export const PARTNER_CONFIGS = gql`
  query PartnerConfigs($domain: String) {
    partnerConfigs(domain: $domain) {
      id
      name
      partnerId
      customPrefixUrl
      applicationSettings
      styling {
        backgroundColor
        backgroundContrastColor
        primaryColor
        primaryContrastColor
        secondaryColor
        secondaryContrastColor
        tertiaryColor
        tertiaryContrastColor
        logoUrl
        heroImageUrl
      }
      settings {
        showHubFirst
      }
      stylingOverrides {
        code
        value
      }
      tagManagers
      primaryUrl
      onlineBookingToolEnabled
      environmentConfigs {
        googleMapsApiKey
        adyenClientKey
        adyenMerchant
        environment
        sentryDSN
      }
    }
  }
`;

export const PARTNER_WHITELABEL = gql`
  query PartnerWhitelabel($id: Int) {
    partnerWhiteLabel(id: $id) {
      helpdeskUrl
    }
  }
`;

export const APPLICATION_SETTINGS = gql`
  query ApplicationSettings($params: ApplicationSettingsRequestParams!) {
    applicationSettings(params: $params) {
      results {
        id
        partnerId
        code
        type
        value
        category
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalResults
      }
    }
  }
`;
