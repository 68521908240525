import { gql } from '@apollo/client';

export const TRANSLATIONS = gql`
  query Translations($app: String!, $lng: String!) {
    translations(app: $app, lng: $lng) {
      content
      languageCodes
    }
  }
`;
