import { ApolloClient, ApolloLink, createHttpLink, DefaultOptions, InMemoryCache } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';

export const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL });

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const errorLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }: any) => {
      console.error(path, message);
    });
  }
  if (networkError) {
    console.error(networkError);
  }
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions,
});
