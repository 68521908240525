import './header.scss';

import { CHeader, CHeaderNav, CLink } from '@coreui/react';
import React from 'react';

import { useLocalize } from '../../hooks/useLocalize';
import { useQueryPartnerWhitelabel } from '../../queries/partnerConfigs/hooks/useQueryPartnerWhitelabel';
import { Currency } from '../currency/Currency';
import { CurrencyStandalone } from '../currency/CurrencyStandlone';
import { Language } from '../language/Language';

export interface HeaderProps {
  showCurrency?: boolean;
  showCurrencyStandalone?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  showCurrency = true,
  showCurrencyStandalone = false,
}) => {
  const { translate: t } = useLocalize();
  const { id } = window.tz_globalConfigs;
  const { data: partnerWhitelabelData } = useQueryPartnerWhitelabel({ variables: { id } });
  const helpdeskUrl = partnerWhitelabelData?.partnerWhiteLabel?.helpdeskUrl;

  return (
    <CHeader className="d-flex justify-content-end align-items-center page-header position-sticky">
      <CHeaderNav className="px-3">
        {showCurrency && <Currency />}
        {showCurrencyStandalone && <CurrencyStandalone />}
        <Language />
        <CLink
          className="link"
          style={{ color: window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.page.textColor'] || '#000' }}
          href={helpdeskUrl || 'https://support.taxi2airport.com'}
          target="_blank">{t('helpdesk')}
        </CLink>
      </CHeaderNav>
    </CHeader>
  );
};
