import { CInput } from '@coreui/react';
import React, { HTMLProps } from 'react';

import { ReactComponent as ClearIcon } from '../../../../assets/svgs/clear.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/svgs/search.svg';
import { ReactComponent as DangerIcon } from '../danger.svg';
import styles from './SearchInput.module.scss';

interface SearchInputProps extends HTMLProps<HTMLDivElement>{
  value?: string;
  placeholder: string | undefined;
  editable?: boolean;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  Icon?: React.FC;
  setValue?: (value: string) => void;
  error?: string;
  dataTestId?: string;
}

export const SearchInput = ({ value, placeholder, editable, setValue, inputRef, Icon = SearchIcon, error, dataTestId, ...rest }: SearchInputProps) => {
  const handleClear = () => {
    if (value === undefined && inputRef?.current) {
      inputRef.current.value = '';
    }
    setValue?.('');
    setTimeout(() => inputRef?.current?.focus(), 1);
  };

  return (
    <div className={styles.searchInput}>
      <div {...rest} className={`input-wrapper ${error ? 'has-error' : ''}`}>
        <span className="icon-wrapper">
          <Icon />
        </span>
        <CInput
          data-testid={dataTestId}
          type="text"
          value={value}
          placeholder={placeholder}
          readOnly={!editable}
          onInput={(ev) => setValue?.((ev.target as HTMLInputElement).value)}
          innerRef={inputRef} style={{ overflow: 'hidden' }}
        />
        {editable && !!value?.length && <span className="icon-wrapper" onClick={handleClear}>
          <ClearIcon />
        </span>}
      </div>
      {!!error && <div className="error-message"><DangerIcon /><span>{error}</span></div>}
    </div>
  );
};

