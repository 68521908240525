import { gql } from '@apollo/client';

export const PERFORM_PAYMENT = gql`
  mutation PerformPayment($id: Int!, $params: PaymentParams!) {
    performPayment(id: $id, params: $params) {
      action
      resultCode
    }
  }
`;

export const INITIATE_PAYMENT = gql`
  mutation InitiatePayment($id: Int!) {
    initiatePayment(id: $id) {
      paymentServicePaymentRequestId
    }
  }
`;

export const PAYMENT_RESULT_CODE = gql`
  mutation PaymentResultCode($id: Int!, $params: PaymentResultCodeParams!) {
    paymentResultCode(id: $id, params: $params) {
      pspReference
      resultCode
      merchantReference
      amount {
        value
        currency
      }
    }
  }
`;

export const PAYMENT_METHODS  = gql`
  query PaymentMethods($params: PaymentMethodsRequestParams!) {
    paymentMethods(params: $params) {
      paymentMethods {
        details {
          items {
            id
            name
          }
          key
          type
        }
        name
        type
        brands
        configuration {
          merchantId
          merchantName
        }
      }
    }
  }
`;
