import { useState } from 'react';

import { useQueryGetQuotes } from '../queries/quote/hooks/useQueryGetQuotes';
import { MissingParamsError } from '../utils/exceptions';
import { useFetchHubByAddress } from './useFetchHubByAddress';
import { useLocalize } from './useLocalize';

type QuotesRequiredParams = 'hub' | 'destination' | 'partnerId' | 'directionality' | 'inboundPickup' | 'outboundPickup';

export const validateQuotesParams = (sessionData?: any) => {
  const data = sessionData || Object.fromEntries(new URLSearchParams(window.location.search).entries());
  const missingRequiredParams: QuotesRequiredParams[] = [];

  const partnerId = Number(data.partnerId) || window.tz_globalConfigs?.partnerId;
  const address = data.destination?.address ? data.destination.address : data.destination ? JSON.parse(data.destination)?.address : undefined;

  !data.hubId && !data.hubName && missingRequiredParams.push('hub');
  !address && missingRequiredParams.push('destination');
  !partnerId && missingRequiredParams.push('partnerId');
  !data.directionality && missingRequiredParams.push('directionality');

  if (data.directionality?.includes?.('ROUNDTRIP')) {
    !data.inboundPickup && missingRequiredParams.push('inboundPickup');
    !data.outboundPickup && missingRequiredParams.push('outboundPickup');
  } else if (data.directionality?.includes?.('OUTBOUND')) {
    !data.outboundPickup && missingRequiredParams.push('outboundPickup');
  } else if (data.directionality?.includes?.('INBOUND')) {
    !data.inboundPickup && missingRequiredParams.push('inboundPickup');
  }

  if (missingRequiredParams.length) {
    throw new MissingParamsError<QuotesRequiredParams>(missingRequiredParams);
  }
};

export const useRetrieveQuotes = () => {
  const { translate: t, locale } = useLocalize();
  const [isLoading, setIsLoading] = useState(false);
  const [quotes, setQuotes] = useState([]) as any;
  const { refetch: getQuotes } = useQueryGetQuotes({
    variables: {
      params: {},
    },
    skip: true,
  });
  const fetchHubByAddress = useFetchHubByAddress(locale);
  const [error, setError] = useState('');

  const getQueryData = async (sessionData?: any): Promise<any> => {
    const data = sessionData || Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if (!data.hubId && data.hubName) {
      const hub = await fetchHubByAddress(data.hubName);
      data.hubId = hub?.id;
    }
    const partnerId = Number(data.partnerId) || window.tz_globalConfigs?.partnerId;
    const address = data.destination?.address ? data.destination.address : data.destination ? JSON.parse(data.destination)?.address : undefined;
    const isRoundTrip = data.isRoundTrip === 'true' || (!data.isRoundTrip && data.directionality?.includes?.('ROUNDTRIP'));
    return {
      partnerId: partnerId,
      hub: Number(data.hubId),
      directionality: data.directionality,
      destination: {
        address,
      },
      isRoundTrip,
      ...(!data.adultPassengers ? { passengers: Number(data.passengers) } : { adultPassengerCount: Number(data.adultPassengers) }),
      ...(data.childPassengers && { childPassengerCount: Number(data.childPassengers) }),
      ...(data.infantPassengers && { infantPassengerCount: Number(data.infantPassengers) }),
      luggage: Number(data.luggage) || 2,
      inboundPickup: data.inboundPickup === 'null' ? '' : data.inboundPickup,
      outboundPickup: data.outboundPickup === 'null' ? '' : data.outboundPickup,
    };
  };

  const retrieveQuotes = async (preferredCurrencyCode: string, sessionData?: any) => {
    const queryData = await getQueryData(sessionData);

    if (preferredCurrencyCode) {
      queryData.preferredCurrencyCode = preferredCurrencyCode;
    }

    setIsLoading(true);
    setError('');

    return await getQuotes({
      params: { ...queryData, destination: queryData?.destination?.address },
    })
      .then(({ data, errors }: any) => {
        if (errors || !data.quotes.length) {
          setError('noPrice');
        }
        setQuotes(data.quotes);
        return data;
      })
      .catch((e: any) => {
        console.log('Error while requesting quotes...', e);
        throw (e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [quotes, isLoading, error, retrieveQuotes, setError];
};
