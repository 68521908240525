import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';

import { isHub, SearchResultOption, SearchResults } from '../components/search/input/utils';
import { useFetchAutoCompleteAddresses } from '../queries/autoCompleteAddresses/hooks/useQueryAutoCompleteAddresses';
import { useLocalize } from './useLocalize';

export const useSearchLocation = (allowAddressSearch: boolean, bounds?: google.maps.LatLngBoundsLiteral) => {
  const latestSearchTerm = useRef<string>();
  const { locale } = useLocalize();
  const { fetchAutoCompleteAddresses } = useFetchAutoCompleteAddresses();

  const [searchResults, setSearchResults] = useState<SearchResults>([]);

  /*
   * We need to keep a single reference of the following across renders for the handleSearch closure
   * to work correctly. Otherwise inside the closure we always get the initial value of searchTerm
   */
  const allowAddressSearchRef = useRef<boolean>();
  allowAddressSearchRef.current = allowAddressSearch;
  const boundsRef = useRef<google.maps.LatLngBoundsLiteral>();
  boundsRef.current = bounds;

  const handleSearch = useCallback(debounce(async (text: string, callback?: (searchResults: SearchResults) => void) => {
    latestSearchTerm.current = text;
    if (text.length < 3) {
      setSearchResults([]);
      callback?.([]);
    } else {
      const { data: { autoCompleteAddresses } } = await fetchAutoCompleteAddresses({
        text,
        language: locale,
        mode: allowAddressSearchRef.current ? 'FULL' : 'CASCADE',
        size: 20,
      });
      const results: SearchResultOption[] = autoCompleteAddresses
        ?.filter(item => isHub(item) || allowAddressSearchRef.current)
        .map((autoCompleteAddressesItem) => ({
          title: autoCompleteAddressesItem.title,
          subtitle: autoCompleteAddressesItem.subtitle,
          type: autoCompleteAddressesItem.type,
          innerResult: autoCompleteAddressesItem,
        })) ?? [];

      /*
       * if text does not match with the latest value of the latest call to handleSearch,
       * this is an obsolete search, so we discard the results
       */
      if (text === latestSearchTerm.current) {
        if (results.length === 0) {
          setSearchResults('NOT_RESULTS_FOUND');
          callback?.('NOT_RESULTS_FOUND');
        } else {
          setSearchResults(results);
          callback?.(results);
        }
      }
    }
  }, 300), []);

  return { searchResults, handleSearch };
};
