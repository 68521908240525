import React from 'react';

import { ReactComponent as Passenger } from '../../assets/svgs/passenger.svg';

interface IPassengerInputProps {
  setPassengersPopupOpen: (arg: boolean) => void,
  passengersPopupOpen: boolean;
  passengersValue: number,
}

export function PassengersInput({ setPassengersPopupOpen, passengersPopupOpen, passengersValue }: IPassengerInputProps) {
  return (
    <div className="widget-details-passenger">
      <label htmlFor="passenger-id">
        <Passenger className="passenger-input-icon" />
      </label>
      <input
        id="passenger-id"
        type="text"
        readOnly
        value={passengersValue}
        onClick={() => setPassengersPopupOpen(!passengersPopupOpen)}
      />
    </div>
  );
}
