import React from 'react';

import { Widget as WidgetLegacy } from '../../components/embeddedWidgetLegacy/widget/Widget';

export const Start: React.FC<any> = () => {
  return (
    <WidgetLegacy />
  );
};

export default Start;
