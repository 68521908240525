import { useState } from 'react';

export const useSessionStorage = (key: string, initialValue: string = ''): any => {
  const [storedSessionValue, setStoredValue] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (e) {
      console.error(e);
    }
  });

  const setSessionValue = (value: string) => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
      setStoredValue(value);
    } catch (e) {
      console.error(e);
    }
  };

  const removeSessionValue = (value: string = '') => {
    try {
      window.sessionStorage.removeItem(key);
      setStoredValue(value);
    } catch (e) {
      console.error(e);
    }
  };

  const getSessionValue = () => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (e) {
      console.error(e);
    }
  };

  return [storedSessionValue, setSessionValue, removeSessionValue, getSessionValue];
};
