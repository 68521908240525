import { createContext } from 'react';

interface ITravelAddon {
  quoteTravelAddonIdPairs: object;
  type: string;
  fare: number;
  price: object;
}

interface IQoute {
  price: number;
  ids: Array<number>,
  vehicleCategory: string;
  passengerCapacity: number;
  luggageCapacity: number;
  travelAddons: Array<ITravelAddon>;
  currencyCode: string;
}

interface IBookingContext {
  quotes: Array<IQoute>;
  isLoading: boolean;
  error: string;
  retrieveQuotes: (preferredCurrencyCode?: string, sessionData?: any) => Promise<any>;
  setError: (error: string) => void;
  booking: any,
  isBookingLoading: boolean,
  queryData: any,
  createBooking: (formData: any) => Promise<any>,
  sessionData: any,
  preferredCurrencyCode: string,
  setPreferredCurrencyCode: (preferredCurrencyCode: string) => void;
  getQuotesFromQuery: () => any;
  showWidget: boolean,
  setShowWidget: (showWidget: boolean) => void;
}

export const BookingContext = createContext<IBookingContext>({} as any);
