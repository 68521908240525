import { useCallback, useEffect, useState } from 'react';

export default function isElementAtBottom<ElementType extends HTMLElement>(): { isBottom: boolean, setRef: (node: ElementType) => void } {
  const [isBottom, setIsBottom] = useState(false);
  const [element, setElement] = useState<ElementType | null>(null);

  const refCallback = useCallback((node: ElementType | null) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  useEffect(() => {
    if (!element) {
      return;
    }

    function getElementPosition() {
      element && ((window.innerHeight / element.getBoundingClientRect().y) < 2) ? setIsBottom(true) : setIsBottom(false);
    }

    getElementPosition();

    const handleChange = () => {
      getElementPosition();
    };

    window.addEventListener('resize', handleChange);
    window.addEventListener('scroll', handleChange);

    return () => {
      window.removeEventListener('scroll', handleChange);
      window.removeEventListener('resize', handleChange);
    };
  }, [element]);

  return { isBottom, setRef: refCallback };
}
