import './privacyPolicy.scss';

import React from 'react';

import { Header } from '../../components/header/Header';
import { useLocalize } from '../../hooks/useLocalize';

export const PrivacyPolicy: React.FC<any> = () => {
  const { translate: t } = useLocalize();

  return (
    <>
      <div className="privacy-policy-header">
        <h1>{t('privacyPolicy.title')}</h1>
        <Header showCurrency={false} />
      </div>
      <div className="privacy-policy">
        <div className="privacy-policy-body">
          <h4>{t('privacyPolicy.privacyAndCookiePolicy')}</h4>

          <>
            <h4>{t('privacyPolicy.introduction.title')}</h4>
            <p>
              {t('privacyPolicy.introduction.paragraph1.start')}
              <strong>{t('privacyPolicy.introduction.paragraph1.bold1')}</strong>
              {t('privacyPolicy.introduction.paragraph1.middle')}
              <strong>{t('privacyPolicy.introduction.paragraph1.bold2')}</strong>
              {t('privacyPolicy.introduction.paragraph1.end')}
            </p>
            <p>{t('privacyPolicy.introduction.paragraph2')}</p>
            <p>{t('privacyPolicy.introduction.paragraph3')}</p>
            <p>{t('privacyPolicy.introduction.paragraph4')}</p>
          </>

          <>
            <h4>{t('privacyPolicy.personalData.title')}</h4>
            <p>{t('privacyPolicy.personalData.t2aCollects.title')}</p>
            <ul>
              <li>{t('privacyPolicy.personalData.t2aCollects.option1')}</li>
              <li>{t('privacyPolicy.personalData.t2aCollects.option2')}</li>
              <li>{t('privacyPolicy.personalData.t2aCollects.option3')}</li>
            </ul>
            <p>{t('privacyPolicy.personalData.onBehalf')}</p>
            <u>{t('privacyPolicy.personalData.first.title')}</u>
            <p>{t('privacyPolicy.personalData.thisIncludes')}</p>
            <ul>
              <li>{t('privacyPolicy.personalData.first.option1')}</li>
              <li>{t('privacyPolicy.personalData.first.option2')}</li>
              <li>{t('privacyPolicy.personalData.first.option3')}</li>
              <li>{t('privacyPolicy.personalData.first.option4')}</li>
            </ul>
            <u>{t('privacyPolicy.personalData.second.title')}</u>
            <p>{t('privacyPolicy.personalData.thisIncludes')}</p>
            <ul>
              <li>{t('privacyPolicy.personalData.second.option1')}</li>
              <li>{t('privacyPolicy.personalData.second.option2')}</li>
              <li>{t('privacyPolicy.personalData.second.option3')}</li>
              <li>{t('privacyPolicy.personalData.second.option4')}</li>
            </ul>
            <u>{t('privacyPolicy.personalData.third.title')}</u>
            <p>{t('privacyPolicy.personalData.thisIncludes')}</p>
            <ul>
              <li>{t('privacyPolicy.personalData.third.option1')}</li>
              <li>{t('privacyPolicy.personalData.third.option2')}</li>
              <li>{t('privacyPolicy.personalData.third.option3')}</li>
              <li>{t('privacyPolicy.personalData.third.option4')}</li>
              <li>{t('privacyPolicy.personalData.third.option5')}</li>
              <li>{t('privacyPolicy.personalData.third.option6')}</li>
              <li>{t('privacyPolicy.personalData.third.option7')}</li>
            </ul>
            <p>{t('privacyPolicy.personalData.combineCollectedData')}</p>
          </>

          <>
            <h4>{t('privacyPolicy.howT2AUsesPersonalData.title')}</h4>
            <p>{t('privacyPolicy.howT2AUsesPersonalData.t2aCollects.title')}</p>
            <ul>
              <li>{t('privacyPolicy.howT2AUsesPersonalData.t2aCollects.option1')}</li>
              <li>{t('privacyPolicy.howT2AUsesPersonalData.t2aCollects.option2')}</li>
              <li>{t('privacyPolicy.howT2AUsesPersonalData.t2aCollects.option3')}</li>
              <li>{t('privacyPolicy.howT2AUsesPersonalData.t2aCollects.option4')}</li>
              <li>{t('privacyPolicy.howT2AUsesPersonalData.t2aCollects.option5')}</li>
              <li>{t('privacyPolicy.howT2AUsesPersonalData.t2aCollects.option6')}</li>
            </ul>
            <p>{t('privacyPolicy.howT2AUsesPersonalData.doesNotShareOrSale')}</p>
          </>

          <>
            <h4>{t('privacyPolicy.forWhichPurpose.title')}</h4>
            <p>{t('privacyPolicy.forWhichPurpose.t2aUses')}</p>
            <p>
              <u>{t('privacyPolicy.forWhichPurpose.providingServicesAndFeatures.title.start')}</u>
              {t('privacyPolicy.forWhichPurpose.providingServicesAndFeatures.title.end')}
            </p>
            <ul>
              <li>{t('privacyPolicy.forWhichPurpose.providingServicesAndFeatures.option1')}</li>
              <li>{t('privacyPolicy.forWhichPurpose.providingServicesAndFeatures.option2')}</li>
              <li>{t('privacyPolicy.forWhichPurpose.providingServicesAndFeatures.option3')}</li>
              <li>{t('privacyPolicy.forWhichPurpose.providingServicesAndFeatures.option4')}</li>
              <li>{t('privacyPolicy.forWhichPurpose.providingServicesAndFeatures.option5')}</li>
              <li>{t('privacyPolicy.forWhichPurpose.providingServicesAndFeatures.option6')}</li>
            </ul>
            <p>
              <u>{t('privacyPolicy.forWhichPurpose.safetyAndSecurity.title.start')}</u>
              {t('privacyPolicy.forWhichPurpose.safetyAndSecurity.title.end')}
            </p>
            <ul>
              <li>{t('privacyPolicy.forWhichPurpose.safetyAndSecurity.option1')}</li>
              <li>{t('privacyPolicy.forWhichPurpose.safetyAndSecurity.option2')}</li>
            </ul>
            <p>
              <u>{t('privacyPolicy.forWhichPurpose.customerSupport.title.start')}</u>
              {t('privacyPolicy.forWhichPurpose.customerSupport.title.end')}
            </p>
            <ul>
              <li>{t('privacyPolicy.forWhichPurpose.customerSupport.option1')}</li>
              <li>{t('privacyPolicy.forWhichPurpose.customerSupport.option2')}</li>
              <li>{t('privacyPolicy.forWhichPurpose.customerSupport.option3')}</li>
            </ul>
            <p>
              <u>{t('privacyPolicy.forWhichPurpose.researchAndDevelopment.start')}</u>
              {t('privacyPolicy.forWhichPurpose.researchAndDevelopment.end')}
            </p>
            <p>
              <u>{t('privacyPolicy.forWhichPurpose.enablingCommunication.start')}</u>
              {t('privacyPolicy.forWhichPurpose.enablingCommunication.end')}
            </p>
            <p>
              <u>{t('privacyPolicy.forWhichPurpose.marketing.start')}</u>
              {t('privacyPolicy.forWhichPurpose.marketing.end')}
            </p>
            <p>{t('privacyPolicy.forWhichPurpose.sendCommunications')}</p>
            <p>{t('privacyPolicy.forWhichPurpose.useData')}</p>
            <p>
              <u>{t('privacyPolicy.forWhichPurpose.nonMarketingCommunications.start')}</u>
              {t('privacyPolicy.forWhichPurpose.nonMarketingCommunications.end')}
            </p>
            <p>
              <u>{t('privacyPolicy.forWhichPurpose.legalProceedingsAndRequirements.start')}</u>
              {t('privacyPolicy.forWhichPurpose.legalProceedingsAndRequirements.end')}
            </p>
            <p>{t('privacyPolicy.forWhichPurpose.weOnlyCollect')}</p>
            <p>{t('privacyPolicy.forWhichPurpose.weCollectAndUse.title')}</p>
            <ul>
              <li>
                <u>{t('privacyPolicy.forWhichPurpose.weCollectAndUse.option1.title')}</u>
                <ul>
                  <li>{t('privacyPolicy.forWhichPurpose.weCollectAndUse.option1.subOption1.title')}</li>
                </ul>
              </li>
              <li>
                <u>{t('privacyPolicy.forWhichPurpose.weCollectAndUse.option2.title')}</u>
                <ul>
                  <li>
                    {t('privacyPolicy.forWhichPurpose.weCollectAndUse.option2.subOption1.title')}
                    <ul>
                      <li>{t('privacyPolicy.forWhichPurpose.weCollectAndUse.option2.subOption1.subOption1.title')}</li>
                      <li>{t('privacyPolicy.forWhichPurpose.weCollectAndUse.option2.subOption1.subOption2.title')}</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li><u>{t('privacyPolicy.forWhichPurpose.weCollectAndUse.option3.title')}</u></li>
              <li><u>{t('privacyPolicy.forWhichPurpose.weCollectAndUse.option4.title')}</u></li>
            </ul>
          </>

          <>
            <h4>{t('privacyPolicy.exchangeOfYourPersonalData.title')}</h4>
            <p>{t('privacyPolicy.exchangeOfYourPersonalData.someOfProducts')}</p>
            <p>
              <u>{t('privacyPolicy.exchangeOfYourPersonalData.withOtherDrivers.start')}</u>
              {t('privacyPolicy.exchangeOfYourPersonalData.withOtherDrivers.end')}
            </p>
            <p>
              <u>{t('privacyPolicy.exchangeOfYourPersonalData.withTheGeneralPublic.start')}</u>
              {t('privacyPolicy.exchangeOfYourPersonalData.withTheGeneralPublic.end')}
            </p>
            <p>{t('privacyPolicy.exchangeOfYourPersonalData.t2AWebsite')}</p>
            <p>
              <u>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AAccountOwner.start')}</u>
              {t('privacyPolicy.exchangeOfYourPersonalData.withT2AAccountOwner.end')}
            </p>
            <p>
              <u>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.title.start')}</u>
              {t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.title.end')}
            </p>
            <ul>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option1')}</li>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option2')}</li>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option3')}</li>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option4')}</li>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option5')}</li>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option6')}</li>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option7')}</li>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option8')}</li>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option9')}</li>
              <li>{t('privacyPolicy.exchangeOfYourPersonalData.withT2AServiceProvider.option10')}</li>
            </ul>
            <p>
              <u>{t('privacyPolicy.exchangeOfYourPersonalData.forLegalReasons.start')}</u>
              {t('privacyPolicy.exchangeOfYourPersonalData.forLegalReasons.end')}
            </p>
            <p>{t('privacyPolicy.exchangeOfYourPersonalData.thisIncludesSharing')}</p>
            <p>
              <u>{t('privacyPolicy.exchangeOfYourPersonalData.withConsent.start')}</u>
              {t('privacyPolicy.exchangeOfYourPersonalData.withConsent.end')}
            </p>
            <p>{t('privacyPolicy.exchangeOfYourPersonalData.forDataProcessed')}</p>
          </>

          <>
            <h4>{t('privacyPolicy.security.title')}</h4>
            <p>{t('privacyPolicy.security.t2ASecures')}</p>
          </>

          <>
            <h4>{t('privacyPolicy.yourRights.title')}</h4>
            <p>{t('privacyPolicy.yourRights.subjectToTheTerms.title')}</p>
            <ul>
              <li>{t('privacyPolicy.yourRights.subjectToTheTerms.option1')}</li>
              <li>{t('privacyPolicy.yourRights.subjectToTheTerms.option2')}</li>
              <li>{t('privacyPolicy.yourRights.subjectToTheTerms.option3')}</li>
              <li>{t('privacyPolicy.yourRights.subjectToTheTerms.option4')}</li>
              <li>{t('privacyPolicy.yourRights.subjectToTheTerms.option5')}</li>
              <li>{t('privacyPolicy.yourRights.subjectToTheTerms.option6')}</li>
              <li>{t('privacyPolicy.yourRights.subjectToTheTerms.option7')}</li>
              <li>{t('privacyPolicy.yourRights.subjectToTheTerms.option8')}</li>
              <li>{t('privacyPolicy.yourRights.subjectToTheTerms.option9')}</li>
            </ul>
            <p>
              {t('privacyPolicy.yourRights.shouldYouHave.start')}
              <a href="mailto:Privacy@transferz.com">Privacy@transferz.com</a>
            </p>
            <p>
              {t('privacyPolicy.yourRights.ifYouBelieve.start')}
              <a target="_blank" rel="noopener noreferrer" href={`${window.tz_globalConfigs?.customPrefixUrl || ''}${t('privacyPolicy.yourRights.complaintLink')}`}>{t('privacyPolicy.yourRights.complaintLink')}</a>
            </p>
          </>

          <>
            <h4>{t('privacyPolicy.cookies.title')}</h4>
            <h4>{t('privacyPolicy.cookies.title1')}</h4>
            <p>{t('privacyPolicy.cookies.textFiles')}</p>
            <p>{t('privacyPolicy.cookies.types')}</p>
          </>

          <>
            <h4>{t('privacyPolicy.whichCookies.title')}</h4>
            <p>{t('privacyPolicy.whichCookies.text')}</p>
            <table>
              <tbody>
                <tr><td>{t('privacyPolicy.whichCookies.table.name')}</td><td>{t('privacyPolicy.whichCookies.table.storagePeriod')}</td><td>{t('privacyPolicy.whichCookies.table.content')}</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
              </tbody>
            </table>
          </>

          <>
            <h4>{t('privacyPolicy.managingCookies.title')}</h4>
            <p>{t('privacyPolicy.managingCookies.text')}</p>
          </>

          <>
            <h4>{t('privacyPolicy.questionsOrComplaints.title')}</h4>
            <p>
              {t('privacyPolicy.questionsOrComplaints.text')}
              <a href="mailto:Privacy@transferz.com">Privacy@transferz.com</a>
            </p>
          </>

        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
