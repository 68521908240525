import './luggageWidget.scss';

import React, { useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import isElementAtBottom from '../passengersWidget/isElementAtBottom';
import { LuggageInput } from './LuggageInput';
import { LuggageInformation } from './LuggageOptions';
import { LuggagePopup } from './LuggagePopup';

interface ILuggageWidgetProps {
  setLuggage: (luggage: LuggageInformation) => void,
  luggage: LuggageInformation,
}

export function LuggageWidget({ setLuggage, luggage }: ILuggageWidgetProps) {
  const [luggagePopupOpen, setLuggagePopupOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const { setRef, isBottom } = isElementAtBottom<HTMLDivElement>();

  return (
    <div className="luggage-widget-wrapper" ref={setRef}>
      <LuggageInput
        luggagePopupOpen={luggagePopupOpen}
        luggageValue={luggage.checkedLuggage + luggage.carryOnLuggage}
        onClick={() => setLuggagePopupOpen(true)}
      />
      <LuggagePopup
        isBottom={isBottom}
        show={luggagePopupOpen}
        initialLuggage={luggage}
        onLugaggeChange={(luggage) => {
          setLuggage(luggage);
          isMobile && setLuggagePopupOpen(false);
        }}
        onClose={() => setLuggagePopupOpen(false)}
      />
    </div>);
}
