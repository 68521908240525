import './currency.scss';

import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import { getCurrencyInfo } from '@transferz/utils';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSessionStorage } from '../../hooks/sessionStorage/useSessionStorage';
import { useBookingContext } from '../../hooks/useBookingContext';
import { useLocalize } from '../../hooks/useLocalize';
import { useQueryCurrencies } from '../../queries/currency/hooks/useQueryCurrencies';

const quoteTravelAddons = (id: number, selectedTravelAddons: any, quote: any) => {
  return selectedTravelAddons.map((travelAddon: any) => {
    const addon = quote.travelAddons.find((quoteAddon: any) => quoteAddon.type === travelAddon.type);

    return {
      quoteTravelAddonId: addon.quoteTravelAddonIdPairs[id],
      amount: travelAddon.amount,
    };
  });
};

const getSelectedQuoteAndAddons = (quote: any, data: any) => {
  const quotes: Array<any> = [];
  const selectedTravelAddons = JSON.parse(data.travelAddons);

  quote.ids.forEach((id: number) => {
    quotes.push({
      quoteId: id,
      quoteTravelAddons: !selectedTravelAddons.length ? [] : quoteTravelAddons(id, selectedTravelAddons, quote),
    });
  });
  return quotes;
};

const urlFromObject = (url: any) => {
  let urlStr = '?';
  for (let key of Object.keys(url)) {
    urlStr += `${key}=${url[key]}&`;
  }
  // remove last & character
  return urlStr.slice(0, -1);
};

export const Currency: React.FC = () => {
  const { translate: t } = useLocalize();
  const { isLoading, retrieveQuotes, setError, createBooking, sessionData, preferredCurrencyCode, setPreferredCurrencyCode } = useBookingContext();
  const [, , , getSessionValue] = useSessionStorage('formData');
  const navigate = useNavigate();
  const { partnerStylingOverrides } = window.tz_globalConfigs;
  const arrowStyles: any = {
    '--content-text-color': partnerStylingOverrides?.['whitelabel.page.textColor'] || window.tz_globalConfigs?.styling?.backgroundContrastColor || '#000000',
  } as React.CSSProperties;
  let searchKeys = '';
  let inputKeyTimeout: any = 0;
  const currencyDropDown = document.querySelector('.currency-container .dropdown-menu');
  const observerCurrencyDropDown = new MutationObserver(() => {
    searchKeys = '';
    unselectElements();
    const currencyOpenedDropDown = document.querySelector('.currency-container .dropdown-menu.show');
    if (currencyOpenedDropDown) {
      currencyOpenedDropDown.scrollTop = 0;
    }
  });
  const { data: currencyData } = useQueryCurrencies();

  const constructUrlBasedOnCurrency = (data: any, quotes: any, preferredCurrencyCode: string) => {
    if (data.vehicleCategory) {
      const selectedTravelAddonsFromUrl = typeof data.travelAddons === 'string' ? JSON.parse(data.travelAddons) : data.travelAddons;
      data.travelAddons = typeof data.travelAddons === 'string' ? data.travelAddons : JSON.stringify(data.travelAddons);
      const selectedQuote = quotes?.quotes?.find((quote: any) => quote.vehicleCategory === data.vehicleCategory);
      if (!selectedQuote) {
        setError('errorBecauseOfCurrencyChange');
        return null;
      }
      const selectedQuoteAndAddons = getSelectedQuoteAndAddons(selectedQuote, data);
      const selectedTravelAddonsFromQuote = selectedTravelAddonsFromUrl.map((urlAddon: any) => {
        const selectedAddon = selectedQuote.travelAddons.find((quoteAddon: any) => urlAddon.type === quoteAddon.type);
        if (selectedAddon) {
          return {
            ...selectedAddon,
            amount: urlAddon.amount,
          };
        }
        return {};
      });
      const travelAddonPrice = selectedTravelAddonsFromQuote.reduce((total: any, addon: any) => addon && (total + (addon.amount * addon.price.price)), 0);
      const totalPrice = selectedQuote.price + (selectedQuote.ids.length * travelAddonPrice);
      data.price = `${getCurrencyInfo(preferredCurrencyCode).symbol} ${totalPrice.toFixed(2)}`;
      data.initialPrice = `${getCurrencyInfo(preferredCurrencyCode).symbol} ${selectedQuote.price.toFixed(2)}`;
      data.pricePerPassenger = `${selectedQuote.pricePerPassenger?.toFixed(2)}`;
      data.quotes = JSON.stringify(selectedQuoteAndAddons);
      data.travelAddons = JSON.stringify(selectedTravelAddonsFromQuote);
      data.destination = data.destination?.address ? JSON.stringify(data.destination) : data.destination;
    }

    return new URLSearchParams(data);
  };

  const handleClick = async (preferredCurrencyCode: string) => {
    const formData = getSessionValue();
    setPreferredCurrencyCode(preferredCurrencyCode);
    try {
      const quotes = sessionData.partnerId ? await retrieveQuotes(preferredCurrencyCode, sessionData) : await retrieveQuotes(preferredCurrencyCode);
      if (quotes?.quotes?.length) {
        const urlParams = new URLSearchParams(window.location.search);
        const bookingInformation = sessionData.partnerId ? constructUrlBasedOnCurrency(sessionData, quotes, preferredCurrencyCode) : constructUrlBasedOnCurrency(Object.fromEntries(urlParams.entries()), quotes, preferredCurrencyCode);
        if (urlParams.get('countryCode')) {
          bookingInformation?.set('countryCode', urlParams.get('countryCode') || '');
        }

        navigate({
          pathname: window.location.pathname,
          search: `?${bookingInformation?.toString()}`,
        });

        if (bookingInformation?.get('countryCode') && Object.keys(formData.bookerDetails).length) {
          const booking = await createBooking(formData);
          if (booking.code && booking.totalPrice) {
            const totalPrice = booking.currencyCode ? `${getCurrencyInfo(booking.currencyCode).symbol} ${booking.totalPrice}` : booking.totalPrice;
            navigate({
              pathname: `${window.tz_globalConfigs?.customPrefixUrl || ''}/booking/payment/${booking.code}/${booking.id}/${booking.totalPrice}/${booking.currencyCode}?${bookingInformation?.toString()}&totalPrice=${totalPrice}&countryCode=${formData.country.value}&countryName=${formData.country.label}`,
            });
          }
        }
      }
    } catch (e) {
      console.log('Error because of currency change', e);
      setError('errorBecauseOfCurrencyChange');
    }
  };

  const unselectElements = () => {
    const selectedElements: Array<Element> = Array.from(document.getElementsByClassName('dropdown-item_select'));
    selectedElements.forEach((element: Element) => element.classList.remove('dropdown-item_select'));
  };

  const handleKeyDown = (event: any) => {
    const currencyDropDown = document.querySelector('.currency-container .dropdown-menu.show');

    if (!currencyDropDown) {
      return;
    }
    unselectElements();
    if (event.which === 8 || event.which === 46) {
      searchKeys = searchKeys.slice(0, -1);
    } else if ((event.which >= 65 && event.which <= 90) || event.which === 32) {
      searchKeys += event.key;
    }

    const currencyElements: Array<HTMLElement> = Array.from(document.querySelectorAll(`[data-currency*="${searchKeys}" i]`));
    const currencyElemTopPosition = currencyElements[0]?.offsetTop || 0;

    if (!searchKeys.length) {
      currencyDropDown.scrollTop = 0;
    }

    if (currencyElements[0]) {
      currencyDropDown.scrollTop = currencyElemTopPosition - 50;
      currencyElements.forEach((elem: HTMLElement) => elem.parentElement?.classList.add('dropdown-item_select'));
    }

    clearTimeout(inputKeyTimeout);
    inputKeyTimeout = setTimeout(() => {
      searchKeys = '';
    }, 1500);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    if (currencyDropDown) {
      observerCurrencyDropDown.observe(currencyDropDown, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      observerCurrencyDropDown.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  if (!currencyData) {
    return null;
  }

  return (
    <CDropdown inNav className="currency-container c-header-nav-items mx-2">
      <CDropdownToggle data-testid="preferred-currency-code" className="c-header-nav-link" color="secondary" caret={true} style={arrowStyles} disabled={isLoading}>
        <span>{preferredCurrencyCode}</span>
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="m-0">
        {
          currencyData.currencies.map((currencyItem: string) => <CDropdownItem key={currencyItem} onClick={() => handleClick(currencyItem)}>
            <span data-currency={getCurrencyInfo(currencyItem).currency}>{getCurrencyInfo(currencyItem).symbol}</span>
            {t(`currencies.${currencyItem}`)}
          </CDropdownItem>,
          )
        }
      </CDropdownMenu>
    </CDropdown>
  );
};
