import { useState } from 'react';

export const useLocalStorage = (key: string, initialValue: string = ''): any => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage?.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (e) {
      console.error(e);
    }
  });

  const setValue = (value: string) => {
    try {
      window.localStorage?.setItem(key, JSON.stringify(value));
      setStoredValue(value);
    } catch (e) {
      console.error(e);
    }
  };

  const removeValue = (value: string = '') => {
    try {
      window.localStorage?.removeItem(key);
      setStoredValue(value);
    } catch (e) {
      console.error(e);
    }
  };

  return [storedValue, setValue, removeValue];
};
