import React from 'react';

import { ReactComponent as IndicatorOneWay } from './indicator-one-way.svg';
import { ReactComponent as IndicatorRoundtrip } from './indicator-roundtrip.svg';
import styles from './JourneyInfoCondensed.module.scss';
import { ReactComponent as PenIcon } from './pen.svg';

interface JourneyInfoCondensedProps {
  roundtrip: boolean;
  onClick: () => void;
  origin: string;
  destination: string;
}

export const JourneyInfoCondensed: React.FC<JourneyInfoCondensedProps> = ({
  roundtrip,
  onClick,
  origin,
  destination,
}) => {
  const Icon = roundtrip ? IndicatorRoundtrip : IndicatorOneWay;

  return (
    <div className={styles['journey-condensed']} onClick={onClick}>
      <div className={styles.frame}>
        <Icon />
        <div className={styles.group}>
          <div className={styles.caption}>{origin}</div>
          <div className={styles['text-wrapper']}>{destination}</div>
        </div>
        <div className={styles['buttons-elements']}>
          <PenIcon className={styles['icon-l']} color="white" />
        </div>
      </div>
    </div>
  );
};
