import { useQuery } from '@apollo/client';

import { MULTILINGUAL_HUBS } from '../hubTypeDefs';

export interface MultilingualHub {
  id: number;
  autoCompleteName: string;
  autoCompleteLocation: string;
  hubType: string;
  position: {
    lat: number;
    lng: number;
  };
  countryCode: string;
  highlightedResult?: {
    autoCompleteName: {
      value: string;
    };
    autoCompleteLocation: {
      value: string;
    };
  };
}

export const useQueryMultilingualHubs = (options?: any) => (
  useQuery<{multilingualHubs: PaginatedResult<MultilingualHub>}>(MULTILINGUAL_HUBS, options)
);
