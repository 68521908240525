import { gql } from '@apollo/client';

export const ROAD_DISTANCE = gql`
  query RoadDistance($origin: GeoCoordinate!, $destination: GeoCoordinate!, $measurementSystem: String!) {
    roadDistance(origin: $origin, destination: $destination, measurementSystem: $measurementSystem) {
      distance
      durationInSeconds
      unit
    }
  }
`;
