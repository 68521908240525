import { ApolloProvider as ApolloProviderService } from '@apollo/client';
import React from 'react';

import { apolloClient } from '../../apollo/apolloClient';

export const ApolloProvider: React.FC = ({ children }) => {
  return (
    <ApolloProviderService client={apolloClient}>
      {children}
    </ApolloProviderService>
  );
};
