import React from 'react';

import { useLocalize } from '../../hooks/useLocalize';

export const TermsAndConditionsTransportation: React.FC<any> = () => {
  const { translate: t } = useLocalize();
  return (
    <>
      <p className="terms-and-conditions-tab-body-title">{t('termsAndConditions.tab.transportation')}</p>

      <ul className="terms-and-conditions-block">
        <li>
          <u>{t('termsAndConditions.transportationTerms.important.title')}</u>
          <ul className="underlined-start">
            <li>
              <span>{t('termsAndConditions.transportationTerms.important.options.1.start')}</span>
              {t('termsAndConditions.transportationTerms.important.options.1.end')}
            </li>
            <li>
              <span>{t('termsAndConditions.transportationTerms.important.options.2.start')}</span>
              {t('termsAndConditions.transportationTerms.important.options.2.end')}
            </li>
            <li>
              <span>{t('termsAndConditions.transportationTerms.important.options.3.start')}</span>
              {t('termsAndConditions.transportationTerms.important.options.3.end')}
            </li>
            <li>
              <span>{t('termsAndConditions.transportationTerms.important.options.4.start')}</span>
              {t('termsAndConditions.transportationTerms.important.options.4.end')}
            </li>
            <li>
              <span>{t('termsAndConditions.transportationTerms.important.options.5.start')}</span>
              {t('termsAndConditions.transportationTerms.important.options.5.end')}
            </li>
            <li>
              <span>{t('termsAndConditions.transportationTerms.important.options.6.start')}</span>
              {t('termsAndConditions.transportationTerms.important.options.6.end')}
            </li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.definitions.title.start')}
            <u>{t('termsAndConditions.transportationTerms.definitions.title.end')}</u>
          </p>
          <p>{t('termsAndConditions.transportationTerms.definitions.text')}</p>
          <ul>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.1.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.1.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.2.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.2.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.3.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.3.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.4.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.4.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.5.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.5.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.6.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.6.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.7.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.7.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.8.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.8.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.9.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.9.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.10.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.10.middle')}
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.10.bold')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.10.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.11.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.11.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.12.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.12.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.13.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.13.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.14.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.14.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.15.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.15.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.16.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.16.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.17.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.17.end')}
            </li>
            <li>
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.18.start')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.18.middle')}
              <strong>{t('termsAndConditions.transportationTerms.definitions.options.18.bold')}</strong>
              {t('termsAndConditions.transportationTerms.definitions.options.18.end')}
            </li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.contractualRelationship.title.start')}
            <u>{t('termsAndConditions.transportationTerms.contractualRelationship.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.contractualRelationship.options.1')}</li>
            <li>{t('termsAndConditions.transportationTerms.contractualRelationship.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.contractualRelationship.options.3')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.whatWeWillDo.title.start')}
            <u>{t('termsAndConditions.transportationTerms.whatWeWillDo.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.whatWeWillDo.options.1')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatWeWillDo.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatWeWillDo.options.3')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatWeWillDo.options.4')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.whatYouNeedToDo.title.start')}
            <u>{t('termsAndConditions.transportationTerms.whatYouNeedToDo.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.1')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.3')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.4')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.5')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.6')}</li>
            <li>
              {t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.7.title')}
              <ul>
                <li>
                  {t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.7.options.1')}
                </li>
                <li>
                  {t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.7.options.2')}
                </li>
              </ul>
            </li>
            <li>{t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.8')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatYouNeedToDo.options.9')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.priceAndPayment.title.start')}
            <u>{t('termsAndConditions.transportationTerms.priceAndPayment.title.end')}</u>
          </p>
          <ul>
            <li>
              {t('termsAndConditions.transportationTerms.priceAndPayment.options.1.start')}
              <strong>{t('termsAndConditions.transportationTerms.priceAndPayment.options.1.middle')}</strong>
              {t('termsAndConditions.transportationTerms.priceAndPayment.options.1.end')}
            </li>
            <li>{t('termsAndConditions.transportationTerms.priceAndPayment.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.priceAndPayment.options.3')}</li>
            <li>{t('termsAndConditions.transportationTerms.priceAndPayment.options.4')}</li>
            <li>{t('termsAndConditions.transportationTerms.priceAndPayment.options.5')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.repairAndCleaning.title.start')}
            <u>{t('termsAndConditions.transportationTerms.repairAndCleaning.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.repairAndCleaning.options.1')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.amendments.title.start')}
            <u>{t('termsAndConditions.transportationTerms.amendments.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.amendments.options.1')}</li>
            <li>{t('termsAndConditions.transportationTerms.amendments.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.amendments.options.3')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.cancellation.title.start')}
            <u>{t('termsAndConditions.transportationTerms.cancellation.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.cancellation.options.1')}</li>
            <li>{t('termsAndConditions.transportationTerms.cancellation.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.cancellation.options.3')}</li>
            <li>
              {t('termsAndConditions.transportationTerms.cancellation.options.4.title')}
              <ul>
                <li>
                  {t('termsAndConditions.transportationTerms.cancellation.options.4.options.1')}
                </li>
                <li>
                  {t('termsAndConditions.transportationTerms.cancellation.options.4.options.2')}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.limitationOfLiability.title.start')}
            <u>{t('termsAndConditions.transportationTerms.limitationOfLiability.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.limitationOfLiability.options.1')}</li>
            <li>{t('termsAndConditions.transportationTerms.limitationOfLiability.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.limitationOfLiability.options.3')}</li>
            <li>{t('termsAndConditions.transportationTerms.limitationOfLiability.options.4')}</li>
            <li>{t('termsAndConditions.transportationTerms.limitationOfLiability.options.5')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.refundPolicy.title.start')}
            <u>{t('termsAndConditions.transportationTerms.refundPolicy.title.end')}</u>
          </p>
          <ul>
            <li>
              {t('termsAndConditions.transportationTerms.refundPolicy.options.1.title')}
              <ul>
                <li>
                  {t('termsAndConditions.transportationTerms.refundPolicy.options.1.options.1')}
                </li>
                <li>
                  {t('termsAndConditions.transportationTerms.refundPolicy.options.1.options.2')}
                </li>
                <li>
                  {t('termsAndConditions.transportationTerms.refundPolicy.options.1.options.3')}
                </li>
                <li>
                  {t('termsAndConditions.transportationTerms.refundPolicy.options.1.options.4')}
                </li>
                <li>
                  {t('termsAndConditions.transportationTerms.refundPolicy.options.1.options.5')}
                </li>
                <li>
                  {t('termsAndConditions.transportationTerms.refundPolicy.options.1.options.6')}
                </li>
              </ul>
            </li>
            <li>{t('termsAndConditions.transportationTerms.refundPolicy.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.refundPolicy.options.3')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.whatIfGoesWrong.title.start')}
            <u>{t('termsAndConditions.transportationTerms.whatIfGoesWrong.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.whatIfGoesWrong.options.1')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatIfGoesWrong.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.whatIfGoesWrong.options.3')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.general.title.start')}
            <u>{t('termsAndConditions.transportationTerms.general.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.general.options.1')}</li>
            <li>{t('termsAndConditions.transportationTerms.general.options.2')}</li>
            <li>{t('termsAndConditions.transportationTerms.general.options.3')}</li>
          </ul>
        </li>
      </ul>

      <ul className="terms-and-conditions-block">
        <li>
          <p>
            {t('termsAndConditions.transportationTerms.contactUs.title.start')}
            <u>{t('termsAndConditions.transportationTerms.contactUs.title.end')}</u>
          </p>
          <ul>
            <li>{t('termsAndConditions.transportationTerms.contactUs.options.1')}</li>
            <li>{t('termsAndConditions.transportationTerms.contactUs.options.2')}</li>
          </ul>
        </li>
      </ul>

    </>
  );
};
