import { gql } from '@apollo/client';

export const EXCHANGE_RATE = gql`
  query ExchangeRate($currencyCode: String!, $base: String!) {
    exchangeRate(currencyCode: $currencyCode, base: $base) {
      rate
      currency
    }
  }
`;
