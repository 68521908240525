import { CRow } from '@coreui/react';
import { Button } from '@transferz/fe-component-library/src/components/button/Button';
import { useLocalize } from 'localize-react';
import React, { useState } from 'react';

import { InputSpinner } from '../inputSpinner/InputSpinner';
import { ReactComponent as CheckedLuggageIcon } from './checked-luggage.svg';
import { ReactComponent as CarryOnLuggageIcon } from './luggage.svg';

export interface LuggageInformation {
  checkedLuggage: number,
  carryOnLuggage: number,
}

export interface ILuggageOptionsProps {
  isMobile?: boolean;
  initialLuggage: LuggageInformation,
  onLuggageChange: (arg: LuggageInformation) => void,
}

export function LuggageOptions({ isMobile = false, initialLuggage, onLuggageChange }: ILuggageOptionsProps) {

  const { translate: t } = useLocalize();
  const [luggageInformation, setLuggageInformation] = useState<LuggageInformation>(initialLuggage);

  const options = [
    {
      name: 'checkedLuggage',
      Icon: CheckedLuggageIcon,
      title: t('checkedLuggage'),
      initialCount: initialLuggage.checkedLuggage,
      setCount: (value: number) => {
        setLuggageInformation({ ...luggageInformation, checkedLuggage: value });
        !isMobile && onLuggageChange({ ...luggageInformation, checkedLuggage: value });
      },
    },
    {
      name: 'carryOnLuggage',
      Icon: CarryOnLuggageIcon,
      title: t('carryOnLuggage'),
      initialCount: initialLuggage.carryOnLuggage,
      setCount: (value: number) => {
        setLuggageInformation({ ...luggageInformation, carryOnLuggage: value });
        !isMobile && onLuggageChange({ ...luggageInformation, carryOnLuggage: value });
      },
    },
  ];

  return (
    <>
      <CRow className="mr-0 ml-0 ">
        <div className="booking-options w-100 show-options" >
          <ul className="pl-0 pr-0">
            {options.map(({ Icon, name, title, initialCount, setCount }) => (
              <li key={name} className="option">
                <div className="travel-addon-container">
                  <div className="d-flex">
                    <Icon className="luggage-icon" />
                    <div>
                      <div data-testid="select_travel-addon" className="travel-addon">
                        <div className="travel-addon-title">{title}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <InputSpinner
                      maxValue={99}
                      name={`spinner-${name}`}
                      initialValue={initialCount}
                      onChange={setCount}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </CRow>
      {isMobile && <div className="luggagesFooter d-flex justify-content-flex-end">
        <Button
          className="submitBtn"
          label={t('Done')}
          priority="primary"
          onClick={() => {
            onLuggageChange(luggageInformation);
          }}
          variant="info"
          data-button-id="set-luggage-button"
          fullWidth
        />
      </div>}
    </>
  );
}
