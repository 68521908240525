import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export function initDatadog(env: {[k: string]: string}) {
  if (env.REACT_APP_ENV) {
    datadogLogs.init({
      clientToken: env.REACT_APP_WHITELABEL_DATADOG_CLIENT_TOKEN!,
      site: 'datadoghq.eu',
      service: 'whitelabel',
      forwardErrorsToLogs: true,
      env: env.REACT_APP_ENV,
      sessionSampleRate: 100,
    });

    datadogRum.init({
      applicationId: env.REACT_APP_WHITELABEL_DATADOG_APPLICATION_ID!,
      clientToken: env.REACT_APP_WHITELABEL_DATADOG_CLIENT_TOKEN!,

      /*
       * `site` refers to the Datadog site parameter of your organization
       * see https://docs.datadoghq.com/getting_started/site/
       */
      site: 'datadoghq.eu',
      service: 'whitelabel',
      env: env.REACT_APP_ENV,

      /*
       * Specify a version number to identify the deployed version of your application in Datadog
       * version: '1.0.0',
       */
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [/https:\/\/.*\.transferz\.com/, /https:\/\/.*\.taxi2airport\.com/, /^https:\/\/([a-zA-Z0-9-]+\.)*transferz\.com\/?$/, /^https:\/\/([a-zA-Z0-9-]+\.)*taxi2airport\.com\/?$/],
    });
  }
}
