import { useLocalStorage } from './useLocalStorage';

export const useLocalPreferredCurrencyCode = () => {
  const [preferredCurrencyCode, setPreferredCurrencyCode] = useLocalStorage('preferredCurrency', 'EUR');

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('preferredCurrencyCode') && urlParams.get('preferredCurrencyCode') !== preferredCurrencyCode) {
    setPreferredCurrencyCode(urlParams.get('preferredCurrencyCode'));
  }

  return [
    preferredCurrencyCode,
    setPreferredCurrencyCode,
  ];
};
