import { gql } from '@apollo/client';

export const FLIGHT_INFO = gql`
  query FlightInfo($params: FlightInfoRequestParams!, $skip: Boolean!) {
    flightInfo(params: $params) @skip(if: $skip) {
      flightNumbers {
        flightNumber
      }
    }
  }
`;
