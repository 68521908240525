import { gql } from '@apollo/client';

export const CLIENT_IP = gql`
  query clientIp {
    clientIp
  }
`;

export const IP_ADDRESS_INFO = gql`
  query IPAddressInfo {
    IPAddressInfo {
      ipAddress
      type
      continentCode
      countryCode
      regionCode
      countryPhonePrefix
      timeZone
      currencyCode
      isp
      proxy
      crawler
      tor
    }
  }
`;
