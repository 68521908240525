import BottomDrawer from '@transferz/fe-component-library/src/components/bottomDrawer/BottomDrawer';
import { useLocalize } from 'localize-react';
import React, { useRef, useState } from 'react';
import { useMediaQuery, useOnClickOutside } from 'usehooks-ts';

import { PassengersOptions } from './PassengersOptions';

interface IPassengerPopupProps {
  passengersPopupOpen: boolean;
  setPassengersPopupOpen: (arg: boolean) => void,
  passengers: { adultPassengers: number, childPassengers: number, infantPassengers: number },
  setPassengers: (arg: {
    adultPassengers: number;
    childPassengers: number;
    infantPassengers: number;
  }) => void
  isBottom?: boolean | null;
}

export function PassengersPopup({ passengersPopupOpen, setPassengersPopupOpen, passengers, setPassengers, isBottom }: IPassengerPopupProps) {
  const { translate: t, locale } = useLocalize();
  const [popupPassengers, setPopupPassengers] = useState(passengers);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const desktopPopupRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(desktopPopupRef, () => {
    setPopupPassengers(passengers);
    setPassengersPopupOpen(false);
  });

  return (
    <>
      {isMobile
        ? <BottomDrawer className="passengers-popup" title={t('Passengers')} isOpen={passengersPopupOpen} onClose={() => {
          setPassengersPopupOpen(false); setPopupPassengers(passengers);
        }} enableDesktopView={true}>
          <PassengersOptions isMobile={true} setPassengersPopupOpen={setPassengersPopupOpen} passengers={passengers} setPassengers={setPassengers} popupPassengers={popupPassengers} setPopupPassengers={setPopupPassengers} />
        </BottomDrawer>
        : <div onMouseLeave={() => {
          setPopupPassengers(passengers);
          setPassengersPopupOpen(false);
        }
        } ref={desktopPopupRef} className={`passengers-popup-desktop ${isBottom ? 'bottom' : 'top'}`} hidden={!passengersPopupOpen}>
          <PassengersOptions isMobile={false} setPassengersPopupOpen={setPassengersPopupOpen} passengers={passengers} setPassengers={setPassengers} popupPassengers={popupPassengers} setPopupPassengers={setPopupPassengers} />
        </div>
      }
    </>
  );
}
