import './passengersWidget.scss';

import React, { useState } from 'react';

import isElementAtBottom from './isElementAtBottom';
import { PassengersInput } from './PassengersInput';
import { PassengersPopup } from './PassengersPopup';

interface IPassengerWidgetProps {
  setPassengers: (arg: {
    adultPassengers: number;
    childPassengers: number;
    infantPassengers: number;
  }) => void,
  passengers: { adultPassengers: number, childPassengers: number, infantPassengers: number },
}

export function PassengersWidget({ setPassengers, passengers }: IPassengerWidgetProps) {
  const [passengersPopupOpen, setPassengersPopupOpen] = useState(false);

  const { setRef, isBottom } = isElementAtBottom<HTMLDivElement>();

  return (
    <div className="passengers-widget-wrapper" ref={setRef}>
      <PassengersInput passengersPopupOpen={passengersPopupOpen} setPassengersPopupOpen={setPassengersPopupOpen} passengersValue={passengers.adultPassengers + passengers.childPassengers + passengers.infantPassengers}
      />
      <PassengersPopup key={Date.now()} isBottom={isBottom} passengersPopupOpen={passengersPopupOpen} setPassengersPopupOpen={setPassengersPopupOpen} passengers={passengers} setPassengers={setPassengers} />
    </div>);
}
