import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';

import { useLocalize } from '../../../../hooks/useLocalize';
import { useSearchLocation } from '../../../../hooks/useSearchLocation';
import { SearchInput } from '../searchInput/SearchInput';
import { SearchResultItem } from '../searchResultItem/SearchResultItem';
import styles from './SearchModal.module.scss';

interface SearchModalProps {
  show: boolean;
  modalTitle: string;
  searchInputPlaceholder?: string;
  allowAddressSearch?: boolean;
  onClose: () => void;
  onClosed?: () => void;
  onLocationSelected: (location: AutoCompleteAddressItem) => void;
  bounds?: google.maps.LatLngBoundsLiteral;
}

export const SearchModal: React.FC<SearchModalProps> = ({
  show, modalTitle, searchInputPlaceholder, onClose, onClosed, allowAddressSearch = true, onLocationSelected, bounds,
}) => {
  const { translate: t } = useLocalize();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const { searchResults, handleSearch } = useSearchLocation(allowAddressSearch, bounds);

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, handleSearch]);

  return (
    <div className={styles.modalWrapper}>
      <CModal
        show={show}
        onClose={onClose}
        onOpened={() => inputRef.current?.focus()}
        onClosed={onClosed}
        className={styles.modal}
      >
        <CModalHeader>
          {modalTitle}
          <button type="button" className="close" onClick={onClose}>
            &times;
          </button>
        </CModalHeader>
        <CModalBody>
          <SearchInput
            inputRef={inputRef}
            value={searchTerm}
            placeholder={searchInputPlaceholder}
            editable
            setValue={setSearchTerm}
          />
          <div className="results-container">
            {searchResults !== 'NOT_RESULTS_FOUND'
              ? searchResults.map(({ innerResult, ...result }) => (
                <SearchResultItem
                    key={JSON.stringify(result)}
                    {...result}
                    onClick={() => {
                      onLocationSelected(innerResult);
                    }}
                />
              ))
              : <span className="no-results-message">
                {t(`search_no_results_found${allowAddressSearch ? '_hub_or_address' : '_hub'}`)}
              </span>
            }
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};
