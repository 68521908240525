import { useMemo } from 'react';

import { PLACE_TYPES } from '../components/widget/Widget';

export const useResolveGoogleAddress = () => {
  const service = useMemo(() => new google.maps.places.AutocompleteService(), []);

  return (address: string) => {
    return new Promise<google.maps.places.AutocompletePrediction>((resolve, reject) => {
      service.getPlacePredictions({ input: address }, (googleAddresses: google.maps.places.AutocompletePrediction[]) => {
        if (googleAddresses) {
          const address = googleAddresses.find((address: google.maps.places.AutocompletePrediction) => address?.types?.some((type: string) => PLACE_TYPES.includes(type)));
          if (address) {
            resolve(address);
          } else {
            reject('No address found');
          }
        }
      });
    });
  };
};
