import React, { Dispatch, SetStateAction } from 'react';

export interface ILanguageOption {
  language_flag: { filename: string };
  language_iso_code: string;
  language_name: string;
}

export interface ILanguageContext {
  languages: ILanguageOption[];
  currentLocale: string;
  setLocale: Dispatch<SetStateAction<string>>;
}

export const LanguageContext = React.createContext<Partial<ILanguageContext>>({
  languages: undefined,
  currentLocale: undefined,
  setLocale: undefined,
});
